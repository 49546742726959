import React  from "react";
import { Button, Typography, Stack } from "@mui/material";
import HelpIcon from "@mui/icons-material/HelpCenter";

export default (props) => {
  const { supportId, ...restProps } = props;

  return (
    <Stack
      component={Button}
      href={`https://support.optimize-next.com/${supportId}`}
      target="_blank"
      direction="row"
      alignItems="center"
      spacing={0.1}
      {...restProps}
    >
      <HelpIcon color="logo" sx={{ width: "1rem", height: "1rem" }} />
      <Typography variant="caption" color="logo.main" fontSize="0.675rem" sx={{ textDecoration: "underline", textWrap: "nowrap" }}>
        ヘルプ
      </Typography>
    </Stack>
  );
};
