import React from "react";
import { Stack } from "@mui/material";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";

import Drawer from "../containers/Drawer";
import MetricDrawerContent from "./MetricDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";

import ChipWithIcon from "../elements/ChipWithIcon";

const title = "指標", supportId = "experience-report-metric";

export default (props) => {
  const { experience, readOnly, handleUpdate } = props;
  const drawer = Drawer.useContainer();
  const { metricName = "totalUsers" } = experience;

  function handleEdit() {
    const defaultValue = { metricName };
    drawer.open({
      title,
      supportId,
      btnLabel: "更新",
      defaultValue,
      ContentComponent: MetricDrawerContent,
      contentComponentProps: { experience },
      onSuccess: (data) => handleUpdate(data),
    });
  }

  return (
    <BuilderCard
      {...{ title, supportId }}
      color="info"
      Icon={AlignHorizontalLeftIcon}
      renderItem={() => (
        <Stack key={0} direction="row" spacing={4} alignItems="center">
          <ChipWithIcon type={metricName} />
          {!readOnly && (
            <Stack flex={1} direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
              <ActionButton type="edit" onClick={handleEdit} />
            </Stack>
          )}
        </Stack>
      )}
    />
  );
}
