import React from "react";
import { Stack, Typography, Chip } from "@mui/material";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import { arrayUnion } from "firebase/firestore";

import Drawer from "../containers/Drawer";

import ConditionDrawerContent from "./ConditionDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";

import { getConditionTypeObj } from "../utils/conditionTypes";
import { getRandomId } from "../utils/random";
import ja from "../utils/ja.json";

const title = "ターゲティング条件", supportId = "experience-conditions";

export default (props) => {
  const { conditionList = [], handleUpdate, readOnly = false } = props;
  const drawer = Drawer.useContainer();

  function handleEdit(item) {
    drawer.open({
      title: getConditionTypeObj(item.type).label,
      supportId,
      btnLabel: "更新",
      defaultValue: item,
      ContentComponent: ConditionDrawerContent,
      onSuccess: (data) => {
        handleUpdate({
          conditionList: conditionList.map((x) => {
            if (x.id !== item.id) { return x; }
            return { ...x, ...data };
          }),
        });
      },
    });
  }

  function handleAdd() {
    drawer.open({
      title,
      supportId,
      ContentComponent: ConditionDrawerContent,
      onSuccess: (data) => {
        handleUpdate({
          conditionList: arrayUnion({ ...data, id: getRandomId() }),
        });
      },
    });
  }

  function handleRemove(item) {
    handleUpdate({ conditionList: conditionList.filter((x) => x.id !== item.id) });
  }

  return (
    <BuilderCard
      {...{ title, supportId }}
      color="secondary"
      Icon={AdsClickIcon}
      onAddClick={!readOnly && handleAdd}
      items={conditionList}
      isItemConnectorShown
      renderItem={(item) => {
        const conditionTypeObj = getConditionTypeObj(item.type);
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" flex={1} spacing={1.5}>
              <conditionTypeObj.Icon color="secondary" />
              <Typography variant="h6">
                {conditionTypeObj.label}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" flex={2.5} spacing={3}>
              {!!item.key && <Chip label={item.key} color="secondary" />}
              <Chip label={ja[item.method]} />
              <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                {item.valueArr?.map((value, j) => (
                  <Chip key={j} label={value} variant="outlined" />
                ))}
              </Stack>
            </Stack>

            {!readOnly && (
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                <ActionButton type="edit" onClick={() => handleEdit(item)} />
                <ActionButton type="delete" onClick={() => handleRemove(item)} />
              </Stack>
            )}
          </Stack>
        );
      }}
    />
  );
}
