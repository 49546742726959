import React, { useEffect, useState, useMemo } from "react";
import { AlertTitle, Stack, Typography } from "@mui/material";
import BuildIcon from "@mui/icons-material/Build";
import ArticleIcon from "@mui/icons-material/Article";
import BarChartIcon from "@mui/icons-material/BarChart";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import HistoryIcon from "@mui/icons-material/History";

import ExperienceEditor from "./ExperienceEditor";
import ExperienceOverview from "./ExperienceOverview";
import ExperienceReport from "./ExperienceReport";
import ExperienceInsight from "./ExperienceInsight";
import ExperienceHistory from "./ExperienceHistory";

import SupportDrawer from "../containers/SupportDrawer";
import Header from "../containers/Header";

import FirestoreContainer from "../containers/FirestoreContainer";
import FirestoreExperience from "../containers/FirestoreExperience";
import SupportButton from "../elements/SupportButton";
import CustomIcon from "../elements/CustomIcon";

import * as time from "../utils/time";
import getCurrentStatus from "../utils/getCurrentStatus";
import getSortedIndicatorList from "../utils/getSortedIndicatorList";
import ja from "../utils/ja.json";
import colors from "../utils/colors.json";
import PlanGuideStack from "../elements/PlanGuideStack";

function Component (props) {
  const { containerId } = props;
  const supportDrawer = SupportDrawer.useContainer();
  const header = Header.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const firestoreExperience = FirestoreExperience.useContainer();

  const { docData: container, checkIsUpgradeRequired } = firestoreContainer;
  const { dataList: experienceList = [] } = firestoreContainer.useExperienceCol();
  const { dataList: hypothesisList = [] } = firestoreContainer.useHypothesisCol();
  const { dataList: assetList = [] } = firestoreContainer.useAssetCol();
  const { dataList: allIndicatorList = [] } = firestoreContainer.useIndicatorCol();
  const indicatorList = useMemo(() => getSortedIndicatorList(allIndicatorList), [allIndicatorList]);

  const { docData: experience = {} } = firestoreExperience;
  const { dataList: requestList = [] } = firestoreExperience.useRequestCol();

  const [currentStatus, setCurrentStatus] = useState();
  const [intervalCount, setIntervalCount] = useState(0);

  useEffect(() => {
    header.setTabs([
      { label: "概要", value: "overview", Icon: ArticleIcon },
      { label: "詳細", value: "", Icon: BuildIcon },
      { label: "レポート", value: "report", Icon: BarChartIcon },
      { label: "考察", value: "insight", Icon: TipsAndUpdatesIcon },
      { label: "履歴", value: "history", Icon: HistoryIcon },
    ]);
    const interval = setInterval(() => setIntervalCount((prev) => prev + 1), 10000);

    return () => {
      header.setTitle(null);
      header.setTitleChip(null);
      header.setTabs(null);
      header.setAlert(null);
      clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    header.setTitleChip(container.name ? { label: container.name, to: `/containers/${containerId}` } : null);
  }, [container.name]);

  useEffect(() => {
    header.setTitle(experience.name || "エクスペリエンス");
  }, [experience.name]);

  useEffect(() => {
    setCurrentStatus(getCurrentStatus(experience));
  }, [experience.id, experience.gtmInfo?.status, intervalCount]);

  useEffect(() => {
    header.setAlert(experience.notFound ? { children: "エクスペリエンスが見つかりませんでした。" } : null);
  }, [experience.notFound]);

  useEffect(() => {
    supportDrawer.setExperience(experience);
    return () => supportDrawer.setExperience(null);
  }, [experience]);

  useEffect(() => {
    if (!currentStatus) {
      header.setAlert(null);
      return;
    }

    const { startType, startAt, endType, endAt } = experience.gtmInfo || {};

    let startLabel = `${(startType === "auto") ? "自動" : "手動"}で開始`;
    let endLabel = "終了予定";

    switch (currentStatus) {
      case "scheduled":
        startLabel = "開始予定";
        break;  
      case "closed": case "archived":
        endLabel = `${(endType === "auto") ? "自動" : "手動"}で終了`;
        break;
      default:
        break;
    }

    header.setAlert({
      color: colors[currentStatus],
      icon: <CustomIcon type={currentStatus} />,
      children: <>
        <AlertTitle sx={{ mb: 0 }}>
          {`このエクスペリエンスは${ja[currentStatus]}です。`}
        </AlertTitle>
        {(currentStatus !== "draft") && (
          <Stack direction="row" alignItems="center" mt={0.5}>
            {!!startAt ? <>
              <Typography flex={1} fontSize={13}>{startLabel}：{time.toString(startAt)}</Typography>
              <Typography flex={1} fontSize={13}>{endLabel}：{time.toString(endAt) || "未設定"}</Typography>
            </> : (
              <Typography flex={1} fontSize={13}>開始されませんでした。</Typography>
            )}
          </Stack>
        )}
      </>,
      action: <SupportButton supportId="experience-execution" sx={{ alignSelf: "center" }} />,
      sx: { "& .MuiAlert-message": { width: "100% !important" }, "& .MuiAlert-action": { p: 0 } },
    });
  }, [currentStatus]);

  if (!experience.id || experience.notFound || !currentStatus) {
    return null;
  }

  function handleUpdate(data) {
    if (checkIsUpgradeRequired("updateExperience", { data, experienceList })) {
      return new Promise((_, reject) => reject());
    }
    return firestoreExperience.update(data);
  }

  switch (header.fragment) {
    case "report":
      return <ExperienceReport {...{ currentStatus }} />;
    case "overview":
      return <>
        <ExperienceOverview {...{ experienceList, hypothesisList, indicatorList, handleUpdate }} />
        <PlanGuideStack />
      </>;
    case "insight":
      return <>
        <ExperienceInsight {...{ currentStatus, experienceList, hypothesisList, indicatorList, handleUpdate }} />
        <PlanGuideStack />
      </>;
    case "history":
      return <ExperienceHistory {...{ currentStatus }} />;
    default:
      return <ExperienceEditor {...{ currentStatus, experienceList, assetList, requestList }} />;
  }

  // return supportDrawer.render(() => {
  //   switch (header.fragment) {
  //     case "report":
  //       return <ExperienceReport {...{ currentStatus }} />;
  //     case "overview":
  //       return <>
  //         <ExperienceOverview {...{ experienceList, hypothesisList, indicatorList, handleUpdate }} />
  //         <PlanGuideStack />
  //       </>;
  //     case "insight":
  //       return <>
  //         <ExperienceInsight {...{ currentStatus, experienceList, hypothesisList, indicatorList, handleUpdate }} />
  //         <PlanGuideStack />
  //       </>;
  //     case "history":
  //       return <ExperienceHistory {...{ currentStatus }} />;
  //     default:
  //       return <>
  //         <ExperienceEditor {...{ currentStatus, experienceList, requestList }} />
  //       </>;
  //   }
  // });
};

export default (props) => {
  const supportDrawer = SupportDrawer.useContainer();
  return (
    <>
      <Component {...props} />
      {supportDrawer.Component}
    </>
  );
}
