export function toString(value) {
  if (!value?.toDate) { return null; }
  return value.toDate().toLocaleString("ja").slice(0, -3);
}

export function toDateString(value) {
  if (!value?.toDate) { return null; }
  return value.toDate().toLocaleDateString("ja");
}

export function toAnalyticsDateString(value) {
  if (!value?.toDate) { return null; }
  return value.toDate().toLocaleDateString("ja", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  }).replace(/\//g, "-");
}

export function isFuture(value) {
  if (!value?.toDate) { return false; }
  return value.toDate() > new Date();
}

export function isPast(value) {
  if (!value?.toDate) { return false; }
  return value.toDate() < new Date();
}

export function isOld(value, min = 5) {
  if (!value?.toDate) { return false; }
  return value.toDate() - new Date() < - min * 60 * 1000;
}

export function diff(value1, value2) {
  if (!value1?.toDate || !value2?.toDate) { return null; }
  return value1.toDate() - value2.toDate();
}
