import React, { useState } from "react";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default (props) => {
  const { items } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
    e.preventDefault();
  }

  function handleClose(e) {
    setAnchorEl(null);
    e.stopPropagation();
    e.preventDefault();
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{ padding: 0 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ sx: { minWidth: 180 } }}
      >
        {items.map(({ Icon, label, color, onClick, hidden }, i) => hidden ? null : (
          <MenuItem
            key={i}
            onClick={(e) => {
              onClick();
              handleClose(e);
            }}
          >
            <ListItemIcon>
              <Icon {...{ color }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: "h5", color: `${color}.main` }}>
              {label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
