import React, { useMemo } from "react";
import { Button, Chip, Stack, Typography } from "@mui/material";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";

import AlertDialog from "../containers/AlertDialog";
import Drawer from "../containers/Drawer";

import VariantDrawerContent from "./VariantDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";

import { getRandomId } from "../utils/random";

const title = "パターン", supportId = "experience-variants";

export default (props) => {
  const { id, mode, variantList = [], pageVariantObj = {}, handleUpdate, handleUpdateExtra, readOnly = false, hasStarted = false, checkIsUpgradeRequired } = props;
  const alertDialog = AlertDialog.useContainer();
  const drawer = Drawer.useContainer();
  const isRollout = useMemo(() => (mode === "rollout"), [mode]);
  const isEditable =  useMemo(() => !readOnly && !hasStarted && !isRollout, [readOnly, hasStarted, isRollout]);

  function handleEdit() {
    drawer.open({
      title,
      supportId,
      btnLabel: "更新",
      defaultValue: { variantList },
      ContentComponent: VariantDrawerContent,
      onSuccess: (data) => {
        if (checkIsUpgradeRequired("updateVariantList", data)) { return; }
        handleUpdateExtra(data);
      },
    });
  }

  function handleAdd() {
    const itemToAdd = {
      id: getRandomId(),
      name: `パターン${variantList.length}`,
      ratio: variantList[variantList.length - 1].ratio,
    };
    handleUpdateExtra({
      variantList: getNewVariantList("add", itemToAdd),
    });
  }

  function handleDuplicate(item) {
    const itemToAdd = {
      id: getRandomId(),
      name: `${item.name} のコピー`,
      ratio: item.ratio,
    };
    const newPageVariantObj = { ...pageVariantObj };
    Object.keys(newPageVariantObj).forEach((key) => {
      if (key.endsWith(`@${item.id}`)) {
        newPageVariantObj[key.replace(`@${item.id}`, `@${itemToAdd.id}`)] = newPageVariantObj[key];
      }
    });
    alertDialog.open({
      title: `「${item.name}」のコピーを作成しますか？`,
      description: "このパターンに適用されている変更内容も複製されます。",
      cancelable: true,
      actions: [{
        label: "コピーを作成",
        onClick: () => handleUpdate({
          variantList: getNewVariantList("add", itemToAdd),
          pageVariantObj: newPageVariantObj,
        }),
      }],
    });
  }

  function handleRemove(item) {
    const newPageVariantObj = { ...pageVariantObj };
    let isChangeListExists = false;
    Object.keys(newPageVariantObj).forEach((key) => {
      if (key.endsWith(`@${item.id}`)) {
        delete newPageVariantObj[key];
        isChangeListExists = true;
      }
    });
    alertDialog.open({
      title: `「${item.name}」を削除しますか？`,
      description: isChangeListExists && "このパターンに適用されている変更内容も削除されます。",
      cancelable: true,
      actions: [{
        label: "削除",
        color: "error",
        onClick: () => handleUpdate({
          variantList: getNewVariantList("remove", item),
          pageVariantObj: newPageVariantObj,
        }),
      }],
    });
  }

  function getNewVariantList(type, item) {
    let compresser;
    switch (type) {
      case "add":
        compresser = 100 / (100 + Number(item.ratio));
        while (variantList.some((x) => x.name === item.name)) {
          item.name += " (1)"
        }
          break;
      case "remove":
        compresser = 100 / (100 - Number(item.ratio));
        break;
      default:
        break;
    }

    const newVariantList = [];
    let tempRatioSum = 0;

    function pushItemWithCompressedRatio(x) {
      let compressedRatio = Math.floor(Number(x.ratio) * compresser);
      if ((x.ratio > 0) && compressedRatio === 0) { // 元のratioが0ではないのにcompressedRatioが0になる場合は1にする
        compressedRatio = 1;
      }
      newVariantList.push({ ...x, ratio: String(compressedRatio) });
      tempRatioSum += compressedRatio;
    }

    variantList.forEach((x) => {
      if ((type === "remove") && (x.id === item.id)) { return; }
      pushItemWithCompressedRatio(x);
    });

    if (type === "add") {
      pushItemWithCompressedRatio(item);
    }

    // 合計が100%になるように調整
    while (tempRatioSum < 100) {
      const minRatio = Math.min(...newVariantList.map((x) => Number(x.ratio) || 100)); // 0 は minRatio とは見なさない
      const minRatioVariant = newVariantList.find((x) => Number(x.ratio) === minRatio);
      minRatioVariant.ratio = String(Number(minRatioVariant.ratio) + 1);
      tempRatioSum += 1;
    }
    while (tempRatioSum > 100) {
      const maxRatio = Math.max(...newVariantList.map((x) => Number(x.ratio)));
      const maxRatioVariant = newVariantList.find((x) => Number(x.ratio) === maxRatio);
      maxRatioVariant.ratio = String(Number(maxRatioVariant.ratio) - 1);
      tempRatioSum -= 1;
    }

    return newVariantList;
  }

  return (
    <BuilderCard
      {...{ title, supportId }}
      color="success"
      Icon={DonutLargeIcon}
      onAddClick={isEditable && handleAdd}
      items={isRollout ? [{ name: "100%反映", ratio: 100 }] : variantList}
      renderItem={(item, i) => {
        return (
          <Stack direction="row" spacing={4} alignItems="center">
            <Stack flex={4} direction="row" alignItems="center" spacing={1}>
              <Typography flex={0.5} variant="body2">{item.name}</Typography>
              <Chip label={`OPTX-${id}${isRollout ? "" : `-${i}`}`} variant="outlined" />

              <Button
                onClick={isEditable ? handleEdit : undefined}
                disabled={isRollout}
                disableRipple={!isEditable}
                disableFocusRipple={!isEditable}
                disableTouchRipple={!isEditable}
                variant="text"
                sx={{ fontWeight: "bold", marginLeft: "auto !important", minWidth: "7rem", justifyContent: "flex-start" }}
              >
                比重：{item.ratio}%
              </Button>
            </Stack>

            {isEditable && (
              <Stack flex={1} direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                {(i > 0) && (
                  <>
                    <ActionButton type="edit" onClick={handleEdit} />
                    <ActionButton type="duplicate" onClick={() => handleDuplicate(item)} />
                    {(variantList.length > 2) && (
                      <ActionButton type="delete" onClick={() => handleRemove(item)} />
                    )}
                  </>
                )}
              </Stack>
            )}
          </Stack>
        );
      }}
    />
  );
}
