import React, { useEffect, useMemo } from "react";
import { Alert, AlertTitle, Button, Container, Stack } from "@mui/material";
import ScienceIcon from "@mui/icons-material/Science";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SettingsIcon from "@mui/icons-material/Settings";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import Header from "../containers/Header";
import FirestoreContainer from "../containers/FirestoreContainer";
import SupportDrawer from "../containers/SupportDrawer";

import ContainerSubscription from "./ContainerSubscription";
import ContainerSettings from "./ContainerSettings";
import ExperienceList from "./ExperienceList";
import HypothesisList from "./HypothesisList";
import IndicatorList from "./IndicatorList";
import AssetList from "./AssetList";
import TopicList from "./TopicList";

import LoadingStack from "../elements/LoadingStack";
import getSortedIndicatorList from "../utils/getSortedIndicatorList";
import PlanGuideStack from "../elements/PlanGuideStack";

function Component () {
  const header = Header.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const { docData: container } = firestoreContainer;
  const { dataList: experienceList = [] } = firestoreContainer.useExperienceCol();
  const { dataList: requestList = [] } = firestoreContainer.useRequestCol();
  const { dataList: hypothesisList = [] } = firestoreContainer.useHypothesisCol();
  const { dataList: allIndicatorList = [], isLocal: isIndicatorListLocal } = firestoreContainer.useIndicatorCol();
  const { dataList: assetList = [] } = firestoreContainer.useAssetCol();
  const { dataList: topicList = [] } = firestoreContainer.useTopicCol();
  const indicatorList = useMemo(() => getSortedIndicatorList(allIndicatorList), [allIndicatorList]);

  useEffect(() => {
    header.setTabs([
      { label: "エクスペリエンス", value: "", Icon: ScienceIcon },
      { label: "仮説", value: "hypotheses", Icon: TextSnippetOutlinedIcon },
      { label: "KPIツリー", value: "kpitree", Icon: AccountTreeIcon },
      { label: "ドライブ", value: "drive", Icon: AddToDriveIcon },
      { label: "サポート", value: "support", Icon: SupportAgentIcon },
      { label: "設定", value: "settings", Icon: SettingsIcon },
      { label: "契約プラン", value: "subscription", Icon: CardMembershipIcon },
    ]);
    return () => {
      header.setTitle(null);
      header.setTabs(null);
      header.setAlert(null);
    }
  }, []);

  useEffect(() => {
    header.setAlert(container.notFound ? { children: "コンテナが見つかりませんでした。" } : null);
  }, [container.notFound]);

  useEffect(() => {
    header.setTitle(container.name || "コンテナ");
  }, [container.name]);

  if (container.notFound) {
    return null;
  }

  switch (header.fragment) {
    case "subscription":
      return renderOnStatus(<ContainerSubscription />);
    case "settings":
      return renderOnStatus(<ContainerSettings {...{ requestList }} />);
    case "support":
      return <TopicList {...{ experienceList, topicList }} />;
    case "drive":
      return <AssetList {...{ requestList, experienceList, assetList }} />;
    case "kpitree":
      return <>
        <IndicatorList {...{ requestList, experienceList, hypothesisList, indicatorList, isIndicatorListLocal }} />
        <PlanGuideStack />
      </>;
    case "hypotheses":
      return <>
        <HypothesisList {...{ experienceList, hypothesisList, indicatorList }} />
        <PlanGuideStack />
      </>;
    default:
      return renderOnStatus(<ExperienceList {...{ requestList, experienceList, hypothesisList, indicatorList }} />);
  }

  function renderOnStatus(Content) {
    switch (container.gtmStatus) {
      case "creating": {
        return <LoadingStack label="コンテナを準備中" />;
      }
      case "building": {
        return (
          <LoadingStack label="コンテナをセットアップ中">
            <Alert severity="info">セットアップが完了するまで数分ほどお待ちください。</Alert>
            <Button
              href="https://support.optimize-next.com"
              target="_blank"
              variant="outlined"
              disableElevation
              startIcon={<OpenInNewIcon />}
            >
              導入方法を学ぶ
            </Button>
          </LoadingStack>
        );
      }
      case "failed": {
        function handleRetry() {
          firestoreContainer.addRequest({ action: "build" }, 5000);
        }
        return (
          <Container maxWidth="md">
            <Stack spacing={4} p={4}>
              <Alert
                severity="error"
                action={(
                  <Button
                    onClick={handleRetry}
                    color="inherit"
                    sx={{ m: "auto", fontWeight: "bold" }}
                  >
                    再試行
                  </Button>
                )}
              >
                <AlertTitle>コンテナのセットアップに失敗しました。</AlertTitle>
                {container.createdAt?.toDate?.() < new Date("2024-04-25") ? <>
                  2024年4月23日以降に作成されたコンテナにおいて、セットアップに失敗してしまうエラーが発生しておりました。<br/>
                  現在はエラーが解消しておりますので、右の「再試行」ボタンをクリックしてセットアップを完了してください。<br/>
                  セットアップが完了すると問題なくご利用いただけますので、ご安心いただけますと幸いです。<br/>
                  この度はご不便をおかけしてしまい、大変申し訳ございませんでした。
                </> : <>
                  しばらく時間を置いてから再試行してください。
                </>}
              </Alert>
            </Stack>
          </Container>
        );
      }
      default:
        return Content;
    }
  }
}

export default () => {
  const supportDrawer = SupportDrawer.useContainer();
  return (
    <>
      <Component />
      {supportDrawer.Component}
    </>
  );
}
