import React from "react";
import { Link } from "react-router-dom";
import Youtube from "react-youtube";
import { Stack, Container, Typography, Card, CardContent, CardMedia, Grid, Avatar, CardHeader, Box } from "@mui/material";
import ScienceIcon from "@mui/icons-material/Science";
import CodeOffIcon from "@mui/icons-material/CodeOff";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";

import AccordionCard from "../elements/AccordionCard";
import ExternalLink from "../elements/ExternalLink";
import StartButton from "../elements/StartButton";
import CarouselTabs from "../elements/CarouselTabs";

export default () => {
  return (
    <Container>
      <Stack px={{ xs: 1, sm: 2, md: 4, lg: 0 }} py={2}>
        <Grid container spacing={4} py={4}>
          <Grid item xs={12} lg={6}>
            <Typography variant="h1" pl={{ lg: 1 }}>
              無料で使える<wbr/>ABテストツール
            </Typography>
            <Card variant="outlined" sx={{ maxWidth: "560px" }}>
              <Stack spacing={1} px={2.5} py={2}>
                <Typography variant="body3">
                  <strong>「Optimize Next」</strong>は、2023年9月にサービス終了となった「
                  <ExternalLink
                    label="Google オプティマイズ"
                    href="https://support.google.com/analytics/answer/12979939?hl=ja"
                    isIconHidden
                  />
                  」を代替するABテストツールです。
                </Typography>
                <Typography variant="body3">
                  シンプルな操作でかんたんにABテストを実施でき、<wbr/>どなたでも<strong>無料でご利用いただけます。</strong>
                </Typography>
                <Stack pt={2} alignItems="flex-start">
                  <StartButton />
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6} p={2} pr={0} sx={{ maxWidth: "900px !important" }}>
            <Youtube
              {...{ videoId }}
              className="demo-video"
              opts={{
                playerVars: {
                  autoplay: 1,
                  mute: 1,
                  loop: 1,
                  controls: 0,
                  modestbranding: 1,
                  playsinline: 1,
                  playlist: videoId,
                  enablejsapi: 1,
                  origin: window.location.origin,
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={4} py={4}>
          <Grid item xs={12} lg={5}>
            <Typography variant="h2" component="h1" sx={{ fontSize: { lg: "2.8rem" } }}>
              サービスの<wbr/>概要をご紹介。
            </Typography>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Stack spacing={1}>
              {featureList.map(({ Icon, title, description }, i) => (
                <Card key={i} variant="outlined">
                  <CardHeader
                    {...{ title }}
                    avatar={<Avatar sx={{ bgcolor: "logo.main" }}><Icon /></Avatar>}
                    titleTypographyProps={{ variant: "h3", component: "h2" }}
                    sx={{ pb: 1.2 }}
                  />
                  <Box px={2.5} pb={2}>
                    <Typography variant="body4" sx={{ "& span": { display: { xs: "inline-block", sm: undefined, lg: "inline-block" } } }}>
                      {description}
                    </Typography>
                  </Box>
                </Card>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Grid container columnSpacing={4} py={4} direction={{ lg: "row-reverse" }}>
          <Grid item xs={12} lg={4.5}>
            <Typography variant="h2" component="h1" sx={{ fontSize: { lg: "2.8rem" } }}>
              ABテストを、<wbr/>民主化する。
            </Typography>
          </Grid>
          <Grid item xs={12} lg={7.5}>
            <Card variant="outlined">
              <Stack spacing={1} px={2.5} py={2}>
                <Typography variant="body4">
                  ABテストの本質は、<strong>「課題を発見し、仮説を立てて、検証する」</strong>ことにあります。<br/>
                  テストの作成から実施までは、<strong>検証のための手段</strong>に過ぎません。
                </Typography>
                <Typography variant="body4">
                  したがって、そこに発生する<strong>コストは最小限であるべき</strong>です。
                </Typography>
                <Typography variant="body4">
                  その観点において、「Google オプティマイズ」は素晴らしいツールでした。<br/>
                  <strong>無料で利用でき、UIもシンプル</strong>で、誰でも簡単にABテストを実施できました。
                </Typography>
                <Typography variant="body4">
                  しかし、サービス終了となった今、代替となるツールの多くはこの条件を満たしません。<br/>
                  いくつかの有料ツールが提供されていますが、<strong>決して少なくないコスト</strong>がかかります。
                </Typography>
                <Typography variant="body4">
                  「Optimize Next」は、この状況を打開する<strong>ゲームチェンジャー</strong>となるべく生まれました。
                </Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>

        <Grid container columnSpacing={4} py={4}>
          <Grid item xs={12} lg={5.5}>
            <Typography variant="h2" component="h1" sx={{ fontSize: { lg: "2.8rem" } }}>
              サーバーレスだから、<wbr/>無料で、安心。
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6.5}>
            <Card variant="outlined">
              <Stack spacing={1} px={2.5} py={2}>
                <Typography variant="body4">
                他の有料ツールとの一番の違いは、<strong>自社のサーバーを持たない</strong>点にあります。
                </Typography>
                <Typography variant="body4">
                  「Google Tag Manager」の仕組みを応用することで、これを実現しています。<br/>
                  <i>（実は「Google オプティマイズ」の正体も「Google Tag Manager」でした。）</i>
                </Typography>
                <Typography variant="body4">
                  お気付きの通り、無料での提供が可能なのは、<strong>サーバーコストがかからない</strong>ためです。<br/>
                  加えて、テストの配信に関しても、<strong>安定性が担保</strong>されています。<br/>
                  <i>（もしテストの配信が失敗したとすれば、それはGoogleのサーバーがダウンしている時です。）</i>
                </Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>

        <Grid container columnSpacing={4} py={4} direction={{ lg: "row-reverse" }}>
          <Grid item xs={12} lg={4.5}>
            <Typography variant="h2" component="h1" sx={{ fontSize: { lg: "2.8rem" } }}>
              本家を再現、<wbr/>さらに進化。
            </Typography>
          </Grid>
          <Grid item xs={12} lg={7.5}>
            <Card variant="outlined">
              <Stack spacing={1} px={2.5} py={2}>
                <Typography variant="body4">
                  私たちは、マーケティング戦略に特化したコンサルティング企業として、<br/>
                  250以上のサービスにおいて、<strong>累計25,000以上のABテスト</strong>を実施してきました。<br/>
                  <i>（その売上改善額は累計100億円以上と、国内トップクラスの実績を誇ります。）</i>
                </Typography>
                <Typography variant="body4">
                  そのうち大半が「Google オプティマイズ」によって実施されてきたという歴史から、<br/>
                  私たち自身が<strong>極端なヘビーユーザー</strong>であったと自負しています。
                </Typography>
                <Typography variant="body4">
                  そのような背景から、「Google オプティマイズ」に最大限の敬意を込めて、<br/>
                  <strong>可能な限りUIを再現</strong>することにこだわりました。
                </Typography>
                <Typography variant="body4">
                  一方で、ヘビーユーザーだからこそ分かる「ツールの弱点」に対しては、<br/>
                  より良いUIを模索し、<strong>ユーザー視点からの改善</strong>を加えています。
                </Typography>
                <Typography variant="body4">
                  さらに、<strong>日本語を標準言語に採用</strong>しているため、<br/>
                  不自然な翻訳に困惑することは、もうありません。
                </Typography>
                <Typography variant="body4">
                  これまで「Google オプティマイズ」を使ってきた方はもちろん、<br/>
                  はじめてABテストに取り組む方にとっても<strong>使いやすいツール</strong>が完成しました。
                </Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>

        <Stack py={4}>
          <Typography variant="h2" component="h1">
            よくあるご質問
          </Typography>
          <Grid container spacing={2}>
            {faqList.map(({ question, answer }, i) => (
              <Grid key={i} item xs={12} lg={6}>
                <AccordionCard
                  key={i}
                  summary={<Typography variant="h4" component="h2" color="strong.main" fontWeight="bold">{question}</Typography>}
                >
                  <Typography variant="body4">{answer}</Typography>
                </AccordionCard>
              </Grid>
            ))}
          </Grid>
        </Stack>

        <Stack id="media" py={4}>
          <Typography variant="h2" component="h1">
            メディア掲載事例
          </Typography>
          <CarouselTabs containerId="media">
            {mediaList.map(({ title, url, imgUrl, sx }, i) => (
              <Link key={i} to={url} target="_blank">
                <Card variant="outlined">
                  <CardMedia
                    image={imgUrl}
                    alt={title}
                    sx={sx}
                    component="img"
                  />
                  <CardContent>
                    <ExternalLink label={title} fontSize="0.9rem" />
                  </CardContent>
                </Card>
              </Link>
            ))}
          </CarouselTabs>
        </Stack>
      </Stack>
    </Container>
  );
}

const videoId = "TD5c2ebyn1I";

const featureList = [
  {
    Icon: ScienceIcon,
    title: <>データドリブンに<wbr/>サイトを改善。</>,
    description: <>
      <span>あなたのWebサイトの<strong>「勝ちパターン」</strong>を見つけましょう。</span>
      <span>データに基づいて検証することで、<strong>コンバージョン率を最大化</strong>できます。</span>
    </>,
  },
  {
    Icon: CodeOffIcon,
    title: <>ノーコードで<wbr/>テストを作成。</>,
    description: <>
      <span>コーディングの知識がなくても、<strong>直感的な操作</strong>でコンテンツを編集できます。</span>
      <span>もちろん、HTMLやCSS、JavaScriptの記述も可能です。</span>
    </>,
  },
  {
    Icon: SignalCellularAltIcon,
    title: <>Googleアナリティクスと<wbr/>安全に連携。</>,
    description: <>
      <span>GA4とのAPI連携により、テスト結果の確認も<strong>すべてツール内で完結</strong>。</span>
      <span>GA4上でレポートを作成し、さらに詳しく分析することも可能です。</span>
      <span><strong>セキュアな認証システム</strong>により、データの安全性を最大限に高めています。</span>
    </>,
  },
];

const faqList = [
  {
    question: "どうして無料で使えるのですか？",
    answer: <>他の多くの有料ツールと異なり、<strong>サーバーコストがかからないため</strong>です。「Google Tag Manager」の仕組みを応用することで、自社のサーバーを介さないABテスト配信を実現しています。</>,
  },
  {
    question: "今後、有料化する予定はありますか？",
    answer: <>基本的なABテスト機能が<strong>有料化することはありません</strong>。ハイレベルなWebマーケターのみなさまのために、<strong>効率的なABテスト運用</strong>を可能にする便利な機能が満載の有料プランをご用意しています。</>,
  },
  {
    question: "どのような目的で無料提供しているのですか？",
    answer: <>データマーケティング事業を展開している弊社にとって、<strong>データマーケティング市場の活性化が中長期的な視点でプラスとなるため</strong>です。ABテストの実施は「目的」ではなく「手段」であり、そこにはコストが発生するべきでないと考えています。<strong>「ABテストを、民主化する。」</strong>というミッションのもと、データマーケティングにおける健全なインフラ構築を目指しています。</>,
  },
  {
    question: "Webサイトのデータが収集されることはありますか？",
    answer: <>本サービスの導入により<strong>Webサイト上のデータが弊社に対して送信されることはありません</strong>。ABテスト結果の確認に必要なデータは、お客様のGoogleアナリティクスに対して送信されます。弊社が収集・管理するデータは本サービス上に反映された内容のみであり、その用途もサービス提供において技術的に必要不可欠な範囲に限定されています。<strong>セキュリティには万全を期したサービス設計</strong>となっておりますので、ご安心ください。</>,
  },
  {
    question: "ABテストの実施数による制限はありますか？",
    answer: <><strong>基本的に制限はありません</strong>。ただし、同時に実施しているテストが多い場合や、テストにおける変更内容が膨大な場合は、GTMコンテナサイズの超過による制限がかかる可能性があります。</>,
  },
  {
    question: "サイトへのアクセス数・PV数などによる制限はありますか？",
    answer: <>アクセスの多いサイトでも、<strong>制限なく無料でご利用いただけます</strong>。</>,
  },
  {
    question: "どのくらい利用されていますか？",
    answer: <><strong>3,100以上のWebサイト</strong>で導入されています。（2024年8月時点）</>,
  },
];

const mediaList = [
  {
    title: "Optimize Nextが描く未来とは？ 開発者・植松氏が語る「ABテストの民主化」",
    url: "https://kwmlabo.com/interview/1982",
    imgUrl: "https://kwmlabo.com/wp-content/uploads/2024/02/Mr.-Uematsu-developer-of-Optimize-Next-OGP-1024x538.png",
  },
  {
    title: "ECサイトの売上を伸ばす！ABテスト成功の秘訣を植松さんに聞いてみた。",
    url: "https://shop-pro.jp/yomyom-colorme/100796",
    imgUrl: "https://calamel-wordpress.s3.ap-northeast-1.amazonaws.com/contents/uploads/2024/07/26150728/interview_uematsu.jpg",
  },
  {
    title: "Googleオプティマイズ終了後、A/Bテスト代替ツールで最も使われているのは「Optimize Next」【キーマケLab調べ】",
    url: "https://webtan.impress.co.jp/n/2024/02/05/46457",
    imgUrl: "https://webtan.impress.co.jp/sites/default/files/images/webtan_logo/webtan-logo-pink-1500.png",
    sx: { my: "8%" },
  },
  {
    title: "無料ABテストツール「Optimize Next」の開発秘話からわかった、無料提供の理由と今後の野望",
    url: "https://note.com/project_group/n/ne9bf60bd878a",
    imgUrl: "https://assets.st-note.com/production/uploads/images/128725125/rectangle_large_type_2_a71d912826d12a1abb1185a6f62b9444.png",
  },
  {
    title: "Google オプティマイズ廃止後の代替ツール検討、選定状況に関する調査結果",
    url: "https://kwmlabo.com/research-release/1497",
    imgUrl: "https://kwmlabo.com/wp-content/uploads/2023/11/logo_RGB-1024x91.png",
    sx: { width: "90%", mx: "auto", my: "20%" },
  },
  {
    title: "なぜOptimize Nextはたった数ヶ月でここまで導入されるに至ったのか",
    url: "https://ppc-log.com/measurement_tools/optimize-next/13571",
    imgUrl: "https://ppc-log.com/wp-content/uploads/2024/01/optimize-next-2-940x494.jpg",
    sx: { width: "96%", mx: "auto" },
  },
];
