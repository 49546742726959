import React from "react";
import { arrayUnion } from "firebase/firestore";
import { Chip, Stack, Typography } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";

import AlertDialog from "../containers/AlertDialog";
import Drawer from "../containers/Drawer";

import PageDrawerContent from "./PageDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";

import ja from "../utils/ja.json";
import { isDiff } from "../utils/diff";
import { getRandomId } from "../utils/random";
import ExternalLink from "../elements/ExternalLink";

const title = "対象ページ", supportId = "experience-pages";

export default (props) => {
  const { id, pageList = [], pageVariantObj = {}, handleUpdate, handleUpdateExtra, readOnly = false } = props;
  const alertDialog = AlertDialog.useContainer();
  const drawer = Drawer.useContainer();

  function handleEdit(item) {
    drawer.open({
      isWide: true,
      title,
      supportId,
      btnLabel: "更新",
      defaultValue: item,
      ContentComponent: PageDrawerContent,
      contentComponentProps: { experienceId: id },
      onSuccess: (data) => {
        (isDiff(data.filterList, item.filterList) ? handleUpdate : handleUpdateExtra)({
          pageList: pageList.map((x) => {
            if (x.id !== item.id) { return x; }
            return { ...x, ...data };
          }),
        });
      },
    });
  }

  function handleAdd() {
    drawer.open({
      isWide: true,
      title,
      supportId,
      btnLabel: "追加",
      ContentComponent: PageDrawerContent,
      contentComponentProps: { experienceId: id },
      onSuccess: (data) => {
        handleUpdateExtra({
          pageList: arrayUnion({
            ...data,
            id: getRandomId(),
            filterList: [{
              target: data.editorUrl.includes("?") ? "url" : "canonicalUrl",
              method: "equals",
              valueArr: [data.editorUrl],
            }],
          }),
        });
      },
    });
  }

  function handleRemove(item) {
    const newPageVariantObj = { ...pageVariantObj };
    let isChangeListExists = false;
    Object.keys(newPageVariantObj).forEach((key) => {
      if (key.startsWith(`${item.id}@`)) {
        delete newPageVariantObj[key];
        isChangeListExists = true;
      }
    });
    alertDialog.open({
      title: `「${item.name}」を削除しますか？`,
      description: isChangeListExists && "このページに適用されている変更内容も削除されます。",
      cancelable: true,
      actions: [{
        label: "削除",
        color: "error",
        onClick: () => handleUpdate({
          pageList: pageList.filter((x) => x.id !== item.id),
          pageVariantObj: newPageVariantObj,
        }),
      }],
    });
  }

  return (
    <BuilderCard
      {...{ title, supportId }}
      color="info"
      Icon={LanguageIcon}
      onAddClick={!readOnly && handleAdd}
      renderContent={() => (
        <Stack spacing={2}>
          {pageList.map((page, i) => (
            <BuilderCard
              key={i}
              title={page.name}
              titleRight={<ExternalLink href={page.editorUrl} />}
              action={!readOnly && (
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                  <ActionButton type="edit" onClick={() => handleEdit(page)} />
                  {pageList.length > 1 && (
                    <ActionButton type="delete" onClick={() => handleRemove(page)} />
                  )}
                </Stack>
              )}
              items={page.filterList}
              isItemConnectorShown
              renderItem={(filter) => (
                <Stack direction="row" alignItems="center">
                  <Typography variant="body2" flex={1} minWidth="84px">
                    {ja[filter.target]}
                  </Typography>
                  <Stack direction="row" alignItems="center" flex={3} spacing={3}>
                    <Chip label={ja[filter.method]} />
                    <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                      {filter.valueArr?.map((value, j) => (
                        <Chip key={j} label={value} variant="outlined" />
                      ))}
                    </Stack>
                  </Stack>
                </Stack>
              )}
            />
          ))}
        </Stack>
      )}
    />
  );
}
