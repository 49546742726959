import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Stack } from "@mui/material";
import RuleIcon from "@mui/icons-material/Rule";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Drawer from "../containers/Drawer";

import HypothesisCard from "../components/HypothesisCard";
import VerificationDrawerContent from "../components/VerificationDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";
import ChipWithIcon from "../elements/ChipWithIcon";

const title = "仮説の検証結果";//, supportId = "experience-insight-verification";

export default (props) => {
  const { experience, hypothesisList, indicatorList, readOnly, handleUpdate } = props;
  const drawer = Drawer.useContainer();
  const { hypothesisIdArr = [], hypothesisVerificationObj = {} } = experience;

  const relatedHypothesisList = useMemo(() => (
    hypothesisIdArr.map((id) => hypothesisList.find((hypothesis) => hypothesis.id === id)).filter(Boolean)
  ), [experience, hypothesisList]);

  function handleEdit() {
    drawer.open({
      title: `${title}を編集`,
      btnLabel: "更新",
      defaultValue: { hypothesisVerificationObj },
      ContentComponent: VerificationDrawerContent,
      contentComponentProps: { hypothesisIdArr, hypothesisList, indicatorList },
      onSuccess: (data) => handleUpdate(data).then(drawer.close),
    });
  }

  return (
    <BuilderCard
      {...{ title }}
      color="logo"
      Icon={RuleIcon}
      action={(
        <ActionButton
          type="edit"
          onClick={handleEdit}
          isLocked={readOnly}
          disabled={!relatedHypothesisList.length}
        />
      )}
      renderContent={() => !!relatedHypothesisList.length ? (
        <Stack spacing={2}>
          {relatedHypothesisList.map((hypothesis) => (
            <Stack key={hypothesis.id} direction="row" spacing={2}>
              <HypothesisCard
                {...props}
                item={hypothesis}
                selfExperienceId={experience.id}
                readOnly
                isSlim
                isExperiencesHidden
                sx={{ flex: 1, maxWidth: "45rem" }}
              />
              <Stack direction="row" spacing={2} alignItems="center" height="fit-content" pt={1}>
                <ArrowForwardIcon color="action" />
                <ChipWithIcon type={hypothesisVerificationObj[hypothesis.id]} variant="filled" />
              </Stack>
            </Stack>
          ))}
        </Stack>
      ) : (
        <Alert
          severity="warning"
          action={(
            <Button
              component={Link}
              to="#overview"
              color="inherit"
              sx={{ m: "auto", fontWeight: "bold" }}
            >
              設定する
            </Button>
          )}
        >
          「検証対象の仮説」が設定されていません。
        </Alert>
      )}
    />
  );
}
