import React from "react";
import { Stack, Grid, Typography, Button, Link } from "@mui/material";

export default () => {
  return (
    <Stack py={4} sx={{ px: { xs: 1, md: 6 } }} bgcolor="title.main" alignItems="center">
      <Grid container rowSpacing={6} columnSpacing={2} direction="row-reverse" maxWidth="lg">
        <Grid item xs={12} md={6} lg={7}>
          <Stack spacing={3}>
            <Stack spacing={0.5} alignItems={{ xs: "center", md: "normal" }}>
              <Typography variant="footer" fontSize="0.8rem" color="white.main" textAlign={{ xs: "center", md: "inherit" }}>
                <b>効率的なサイト改善</b>と<b>正確なABテスト検証</b>を実現！<wbr/>弊社のデータアナリストが<b>あなたのGA4を最適化</b>します。
              </Typography>
              <Stack spacing={0.5} alignItems="center" width="100%" minWidth="fit-content">
                <Typography
                  variant="caption"
                  color="premium.main"
                  fontWeight="bold"
                >
                  ＼先着10社限定／
                </Typography>
                <Button
                  variant="contained"
                  color="premium"
                  disableElevation
                  href="https://support.optimize-next.com/ga4-boost"
                  target="_blank"
                  sx={{ width: "100%", borderRadius: 10, fontWeight: "bold" }}
                >
                  プロ仕様のGA4を今すぐチェック
                </Button>
              </Stack>
            </Stack>
            <Stack spacing={1} alignItems={{ xs: "center", md: "normal" }}>
              <Typography variant="footer" fontSize="0.8rem" color="white.main" textAlign={{ xs: "center", md: "inherit" }}>
                <b>ABテスト事例</b>や<b>最新のアップデート情報</b>、<wbr/><b>トラブルシューティング</b>などはこちらから。
              </Typography>
              <Button
                variant="contained"
                color="logo"
                disableElevation
                href="https://discord.gg/uDZ5jJfT4B"
                target="_blank"
                sx={{ width: "100%", minWidth: "fit-content", borderRadius: 10, fontWeight: "bold" }}
              >
                公式Discordコミュニティに参加
              </Button>
            </Stack>
            <Stack spacing={1} alignItems={{ xs: "center", md: "normal" }}>
              <Typography variant="footer" fontSize="0.8rem" color="white.main" textAlign={{ xs: "center", md: "inherit" }} sx={{ wordBreak: { xs: "keep-all", md: "inherit" } }}>
                <b>公式ドキュメント</b>はこちらから。
              </Typography>
              <Button
                variant="outlined"
                color="white"
                disableElevation
                href="https://support.optimize-next.com"
                target="_blank"
                sx={{ width: "100%", minWidth: "fit-content", borderRadius: 0 }}
              >
                ヘルプページを確認
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Stack spacing={2} height="100%" alignItems={{ xs: "center", md: "normal" }}>
            <Link href="/" style={{ flex: 1, maxWidth: "90%" }}>
              <img
                alt="Optimize Next"
                src="/logo.png"
                width="480px"
                style={{ maxWidth: "100%" }}
              />
            </Link>

            <Stack alignItems={{ xs: "center", md: "normal" }}>
              <Link href="/terms" target="_blank" underline="always" color="white.main">
                <Typography variant="footer">利用規約・プライバシーポリシー</Typography>
              </Link>
              <Link href="/compliance" target="_blank" underline="always" color="white.main">
                <Typography variant="footer">特定商取引法に基づく表記</Typography>
              </Link>
              <Link href="https://project-g.co.jp" target="_blank" underline="always" color="white.main">
                <Typography variant="footer">運営会社</Typography>
              </Link>
              <Typography variant="footer" color="white.main" mt={1.5} textAlign={{ xs: "center", md: "inherit" }}>
                © 2023 PROJECT GROUP Inc. <wbr/>All Rights Reserved.
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
