import React, { useMemo } from "react";
import { Alert, AlertTitle, Stack, Switch, Typography } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";

import AccordionCard from "../elements/AccordionCard";
import { BuilderCardHeader } from "../elements/BuilderCard";

const title = "レポート", supportId = null; // "experience-analytics";

export default (props) => {
  const { mode, isReportActive = true, handleUpdateExtra, readOnly = false, hasStarted = false } = props;
  const isRollout = useMemo(() => (mode === "rollout"), [mode]);

  return (
    <AccordionCard
      defaultExpanded={isRollout || !isReportActive}
      summary={(
        <BuilderCardHeader
          {...{ title, supportId }}
          color="gray"
          Icon={BarChartIcon}
          sx={{ p: 0 }}
        />
      )}
    >
      <Stack spacing={1}>
        <Stack spacing={1}direction="row" alignItems="center">
          <Switch
            checked={isReportActive}
            onChange={() => handleUpdateExtra({ isReportActive: !isReportActive })}
            disabled={readOnly || hasStarted}
          />
          <Typography variant="body2">
            レポートを有効化（エクスペリエンスの開始時に、Googleアナリティクス上にオーディエンスが作成されます）
          </Typography>
        </Stack>
        {(!isRollout && !isReportActive) && (
          <Alert severity="warning">
            <AlertTitle>レポートは表示されません。</AlertTitle>
            テスト結果を確認するには、Googleアナリティクス上で探索レポートを作成する必要があります。
          </Alert>
        )}
      </Stack>
    </AccordionCard>
  );
}
