import isURL from "validator/lib/isURL";

export function getIsUrl(url) {
  return isURL(url, { require_protocol: true });
}

export function getCanonicalUrl(url) {
  try {
    const urlConstructor = new URL(url);
    const { origin, pathname } = urlConstructor;
    return (origin.replace("www.", "").replace("http:", "https:") + pathname.replace(/\/+$/, "")).toLowerCase();
  } catch {
    return null;
  }
}

export function getPreviewUrl(editorUrl, experienceId, variantIndex = 1, isConditionActive = false) {
  try {
    const urlConstructor = new URL(editorUrl);
    urlConstructor.searchParams.set(`_optx_${experienceId}`, `${variantIndex}${isConditionActive ? "-if" : ""}`);
    return getSlashAdjustedUrl(editorUrl, urlConstructor);
  } catch {
    return null;
  }
}

export function getIsUrlMatchingWithFilter(url, filter) {
  try {
    const urlConstructor = new URL(url);
    const { pathname, hostname, search } = urlConstructor;
    const canonicalUrl = getCanonicalUrl(url);
    const pathnameWithSearch = pathname + search;
    const urlObj = { canonicalUrl, url, pathname, pathnameWithSearch, hostname };
    const { target, method, valueArr = [], ignoreCaseFlag } = filter;
    const ref = urlObj[target];
    const isNegative = method.startsWith("not:");
    const positiveMethod = isNegative ? method.replace("not:", "") : method;
    return !isNegative === valueArr.some((value) => {
      switch (positiveMethod) {
        case "equals":
          let newValue = value;
          if (target === "canonicalUrl") { newValue = getCanonicalUrl(value); }
          return (ref === newValue);
        case "match":
          try {
            return ref?.match(new RegExp(value, ignoreCaseFlag ? "i" : undefined));
          } catch {
            return false;
          }
        default:
          return ref[positiveMethod](value);
      }
    });
  } catch {
    return false;
  }
}

export function getIsUrlMatchingWithFilterList(url, filterList = []) {
  return filterList.every((filter) => getIsUrlMatchingWithFilter(url, filter));
}

export function getRedirectedUrl(originalUrl, redirectInfo = {}) {
  const { type, url, isSearchInherited, isHashInherited, replaceList = [], queryList = [], fragment } = redirectInfo;
  let urlConstructor;
  switch (type) {
    case "single":
      try {
        urlConstructor = new URL(url);
        if (isSearchInherited) {
          const { searchParams } = new URL(originalUrl);
          searchParams.forEach(function(value, key){
            if (!urlConstructor.searchParams.has(key)) {
              urlConstructor.searchParams.set(key, value);
            }
          });
        }
        if (isHashInherited) {
          const { hash } = new URL(originalUrl);
          urlConstructor.hash = hash;
        }
        break;
      } catch {
        break;
      }
    case "advanced":
      try {
        urlConstructor = new URL(originalUrl);
        const { origin, pathname, search, hash } = urlConstructor;
        let originWithPathname = origin + pathname.replace(/\/+$/, "");
        replaceList.forEach(({ before, after, isRegExp, globalSearchFlag, ignoreCaseFlag }) => {
          try {
            let option = "";
            if (globalSearchFlag) { option += "g"; }
            if (ignoreCaseFlag) { option += "i"; }
            originWithPathname = originWithPathname.replace(isRegExp ? new RegExp(before, option) : before, after || "");
          } catch {}
        });
        urlConstructor = new URL(originWithPathname + search + hash);
        queryList.forEach(({ key, value }) => {
          if (value) {
            urlConstructor.searchParams.set(key, value);
          } else {
            urlConstructor.searchParams.delete(key);
          }
        });
        if (fragment) { urlConstructor.hash = fragment; }
        break;
      } catch {
        break;
      }
    default: break;
  }

  return urlConstructor?.href;
  //return getSlashAdjustedUrl(originalUrl, urlConstructor);
}

function getSlashAdjustedUrl(originalUrl, urlConstructor) {
  const regExp = /\/+($|\?|#)/;
  if (originalUrl.match(regExp)) { return urlConstructor.href; }
  return urlConstructor.href.replace(regExp, "$1");
}

export function getAssetUrl(asset = {}) {
  return `https://assets.optimize-next.com/${asset.fileName}`;
}
