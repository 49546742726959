import React, { useMemo } from "react";
import { arrayUnion, deleteField } from "firebase/firestore";
import { Container, Stack } from "@mui/material";

import FirestoreContainer from "../containers/FirestoreContainer";
import FirestoreExperience from "../containers/FirestoreExperience";

import VerificationCard from "../components/VerificationCard";
import InsightCard from "../components/InsightCard";
import WinningVariantCard from "../components/WinningVariantCard";
import NextExperienceListCard from "../components/NextExperienceListCard";

export default (props) => {
  const { experienceList, hypothesisList, indicatorList, handleUpdate } = props;
  const firestoreContainer = FirestoreContainer.useContainer();
  const firestoreExperience = FirestoreExperience.useContainer();
  const { docData: container = {}, currentUserRole, checkIsUpgradeRequired } = firestoreContainer;
  const { docData: experience = {} } = firestoreExperience;
  const isRollout = useMemo(() => (experience.mode === "rollout"), [experience.mode]);

  const readOnly = useMemo(() => (currentUserRole === "viewer"), [currentUserRole]);

  async function handleCreateRolloutExperience() {
    if (checkIsUpgradeRequired("turnIntoRollout")) { return; }
    const { name, type, conditionList = [], pageList = [], pageVariantObj = {}, triggerIdArr, winningVariantId } = experience;
    firestoreContainer.addExperienceFromTemplate({
      name: `${name}の100%反映`,
      mode: "rollout",
      type,
      conditionList,
      pageList,
      pageVariantObj: Object.fromEntries(pageList.map((page) => [`${page.id}@rollout`, pageVariantObj[`${page.id}@${winningVariantId}`]])),
      triggerIdArr,
      isReportActive: false,
      baseExperienceId: experience.id,
      timestampKeyArr: ["productionUpdatedAt", "previewUpdatedAt"],
    }).then((rolloutExperienceId) => {
      handleUpdate({ rolloutExperienceInfo: { id: rolloutExperienceId, variantId: winningVariantId } }, null);
      setTimeout(() => window.open(`/containers/${container.id}/experiences/${rolloutExperienceId}`, "_blank"), 500);
    });
  }

  async function handleUnlinkRolloutExperience() {
    return handleUpdate({ rolloutExperienceInfo: deleteField() }, "エクスペリエンスとの紐付けを解除");
  }

  async function handleUpdateVerification(data) {
    return handleUpdate(data, "仮説の検証結果を更新")
      .then(() => {
        Object.entries(data.hypothesisVerificationObj).forEach(([hypothesisId, verification]) => {
          const hypothesis = hypothesisList.find((x) => x.id === hypothesisId);
          if ((verification === "proven") && (hypothesis.status !== "hasProven")) {
            updateHypothesisStatus("hasProven");
          }
          if ((verification === "disproven") && (hypothesis.status !== "hasDisproven")) {
            updateHypothesisStatus("hasDisproven");
          }
          function updateHypothesisStatus(status) {
            firestoreContainer.updateHypothesis(hypothesisId, { status }, "仮説のステータスを更新");
          }
        });
      });
  }

  async function handleCreateExperience(data) {
    return firestoreContainer.addExperienceFromTemplate(data)
      .then((newExperienceId) => {
        handleUpdate({ nextExperienceIdArr: arrayUnion(newExperienceId) }, "展開案を更新");
        setTimeout(() => window.open(`/containers/${container.id}/experiences/${newExperienceId}`, "_blank"), 500);
      });
  }

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        {!isRollout && (
          <WinningVariantCard
            {...{ container, experience, experienceList, readOnly, handleUpdate, handleCreateRolloutExperience, handleUnlinkRolloutExperience }}
          />
        )}
        <VerificationCard
          handleUpdate={handleUpdateVerification}
          {...{ container, experience, hypothesisList, indicatorList, readOnly }}
        />
        <InsightCard
          {...{ experience, readOnly, handleUpdate }}
        />
        <NextExperienceListCard
          {...{ container, experience, experienceList, hypothesisList, indicatorList, readOnly, handleUpdate, handleCreateExperience }}
        />
      </Stack>
    </Container>
  );
}
