import React, { useEffect } from "react";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { FormHelperText, InputAdornment, Stack } from "@mui/material";

export default (props) => {
  const { defaultValue } = props;
  const { setValue, reset, watch, setError, clearErrors, formState } = useFormContext();
  const { errors } = formState;

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  useEffect(() => {
    const subscription = watch(({ variantList = [] }) => {
      if (new Set(variantList.map((variant) => variant.name)).size !== variantList.length) {
        setError("variantList", { message: "パターン名が重複しています" });
      } else if (variantList.reduce((sum, variant) => sum + Number(variant.ratio), 0) !== 100) {
        setError("variantList", { message: "比重の合計を100%にしてください", textAlign: "right" });
      } else {
        clearErrors("variantList");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Stack spacing={3} p={4}>
      {defaultValue.variantList.map((_, i) => {
        const nameInputName = `variantList[${i}].name`;
        const ratioInputName = `variantList[${i}].ratio`;
        return (
          <Stack key={i} direction="row" spacing={2} alignItems="center">
            <TextFieldElement
              name={nameInputName}
              label="パターン名"
              required
              autoComplete="off"
              inputProps={{ maxLength: 20 }}
              sx={{ flex: 1 }}
              onChange={(e) => setValue(nameInputName, e.target.value?.trim() || "")}
            />

            <TextFieldElement
              name={ratioInputName}
              label="比重"
              required
              autoComplete="off"
              sx={{ marginLeft: "auto", width: "20%" }}
              InputProps={{
                type: "number",
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={(e) => {
                let value = Number(e.target.value);
                if (!value || value < 0) { value = 0; }
                if (value > 100) { value = 100; }
                setValue(ratioInputName, String(value));
              }}
            />
          </Stack>
        );
      })}
      {!!errors.variantList && (
        <FormHelperText error sx={{ textAlign: errors.variantList.textAlign }}>
          {errors.variantList.message}
        </FormHelperText>
      )}
    </Stack>
  );
}
