import React, { useMemo } from "react";
import { Container, Stack } from "@mui/material";

import FirestoreContainer from "../containers/FirestoreContainer";
import FirestoreExperience from "../containers/FirestoreExperience";

import PrevExperienceListCard from "../components/PrevExperienceListCard";
import HypothesisListCard from "../components/HypothesisListCard";
import DescriptionCard from "../components/DescriptionCard";

export default (props) => {
  const { experienceList = [], hypothesisList = [], indicatorList = [], handleUpdate } = props;
  const firestoreContainer = FirestoreContainer.useContainer();
  const firestoreExperience = FirestoreExperience.useContainer();
  const { docData: container = {}, currentUserRole } = firestoreContainer;
  const { docData: experience = {} } = firestoreExperience;

  const readOnly = useMemo(() => (currentUserRole === "viewer"), [currentUserRole]);

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <HypothesisListCard
          handleCreateHypothesis={firestoreContainer.addHypothesis}
          handleUpdateHypothesis={firestoreContainer.updateHypothesis}
          handleDeleteHypothesis={firestoreContainer.deleteHypothesis}
          {...{ container, experience, experienceList, hypothesisList, indicatorList, readOnly, handleUpdate }}
        />
        <DescriptionCard
          {...{ experience, readOnly, handleUpdate }}
        />
        <PrevExperienceListCard
          {...{ container, experience, experienceList }}
        />
      </Stack>
    </Container>
  );
}
