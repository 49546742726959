import React, { useEffect, useState, useMemo } from "react";
import { Container, Stack } from "@mui/material";
import { FormContainer } from "react-hook-form-mui";

import Header from "../containers/Header";
import Firebase from "../containers/Firebase";
import FirestoreContainer from "../containers/FirestoreContainer";
import FirestoreTopic from "../containers/FirestoreTopic";

import MessageBubble from "../elements/MessageBubble";
import MessageInput from "../elements/MessageInput";
import LoadingStack from "../elements/LoadingStack";

export default (props) => {
  const header = Header.useContainer();
  const { currentUser, isSuperUser } = Firebase.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const firestoreTopic = FirestoreTopic.useContainer();
  const { containerId, isService = isSuperUser } = props;
  const { docData: topic = {}, messageList = [] } = firestoreTopic;

  const { docData: container = {}, currentUserRole, checkIsUpgradeRequired } = firestoreContainer;
  const { userObj = {} } = container;
  const readOnly = useMemo(() => currentUserRole === "viewer", [currentUserRole]);

  const [sendSuccessCount, setSendSuccessCount] = useState(0);

  useEffect(() => {
    return () => {
      header.setTitle(null);
      header.setTitleChip(null);
      header.setTabs(null);
      header.setAlert(null);
    }
  }, []);

  useEffect(() => {
    header.setTitleChip(container.name ? { label: container.name, to: `/containers/${containerId}#support` } : null);
  }, [container.name]);

  useEffect(() => {
    header.setTitle(topic.name || "お問合せトピック");
  }, [topic.name]);

  useEffect(() => {
    header.setAlert(topic.notFound ? { children: "お問合せトピックが見つかりませんでした。" } : null);
  }, [topic.notFound]);

  useEffect(() => {
    const { latestMessage, userIdArr = [], userLatestMessageIdObj = {} } = topic;
    if (!topic.id || !userIdArr.includes(currentUser?.uid)) { return; }
    const myLatestMessageId = userLatestMessageIdObj[currentUser?.uid];
    if (latestMessage && (myLatestMessageId !== latestMessage.id)) {
      firestoreTopic.update({ [`userLatestMessageIdObj.${currentUser?.uid}`]: latestMessage.id });
    }
  }, [topic, currentUser?.uid]);

  useEffect(() => {
    if (!messageList.length) { return; }
    setTimeout(() => {
      const latestBubble = document.getElementById(messageList[0].id);
      if (latestBubble) {
        latestBubble.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }
    }, 300);
  }, [messageList.length]);

  if (!topic.id || topic.notFound) {
    return null;
  }

  function handleSend(data) {
    if (isService) {
      data.createdByUserId = "support";
    } else if (readOnly || checkIsUpgradeRequired("addMessage")) {
      return;
    }
    firestoreTopic.addMessage(data).then(() => {
      setSendSuccessCount((prev) => prev + 1);
    });
  }

  if (!messageList.length) {
    return <LoadingStack />;
  }

  return (
    <Container maxWidth="lg">
      <Stack direction="column-reverse" spacing={4} pt={4} pb={20}>
        {messageList.map((item) => (
          <MessageBubble key={item.id} {...{ item, currentUser, userObj }} />
        ))}
      </Stack>
      {!readOnly && (
        <FormContainer onSuccess={handleSend}>
          <MessageInput {...{ handleSend, sendSuccessCount }} />
        </FormContainer>
      )}
    </Container>
  );
}
