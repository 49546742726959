import React, { useMemo, useState } from "react";
import { Stack, Typography, Switch } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import AccordionCard from "../elements/AccordionCard";
import BuilderCard, { BuilderCardHeader } from "../elements/BuilderCard";
import ExternalLink from "../elements/ExternalLink";
import CopyButton from "../elements/CopyButton";
import { getPreviewUrl } from "../utils/url";

export default (props) => {
  const { id, mode, pageList = [], variantList = [], gtmInfo = {}, children } = props;
  const [isConditionActive, setIsConditionActive] = useState(false);
  const isRollout = useMemo(() => (mode === "rollout"), [mode]);

  return (
    <AccordionCard
      summary={(
        <BuilderCardHeader
          title="プレビュー"
          supportId="experience-preview"
          color="gray"
          Icon={VisibilityIcon}
          sx={{ p: 0 }}
        />
      )}
    >
      <Stack spacing={2}>
        {children}
        {!!gtmInfo.previewUpdatedAt && (
          <>
            {pageList.map((page, i) => (
              <BuilderCard
                key={i}
                title={page.name}
                items={isRollout ? [{ name: "オリジナル" }, { name: "100%反映" }] : variantList}
                renderItem={(variant, j) => {
                  const link = getPreviewUrl(page.editorUrl, id, j, isConditionActive);
                  return (
                    <Stack key={j}>
                      <Typography variant="body2" flex={1}>
                        {variant.name}
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <ExternalLink href={link} fontSize="0.9rem" />
                        <CopyButton value={link} />
                      </Stack>
                    </Stack>
                  );
                }}
              />
            ))}
            <Stack spacing={1} direction="row" alignItems="center">
              <Switch
                checked={isConditionActive}
                onChange={() => setIsConditionActive((prev) => !prev)}
              />
              <Typography variant="body2">
                ターゲティング条件を有効化
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </AccordionCard>
  );
}
