import React, { useEffect } from "react";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

import CustomSelect from "../elements/CustomSelect";
import { ExperienceCardContent } from "./ExperienceCard";

export default (props) => {
  const { defaultValue, experienceList = [], ...restProps } = props;
  const { reset } = useFormContext();

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  return (
    <Stack spacing={4} p={4} {...restProps}>
      <TextFieldElement
        name="name"
        label="件名"
        required
        fullWidth
        autoFocus
        autoComplete="off"
      />
      <TextFieldElement
        name="description"
        label="詳細"
        required
        multiline
        minRows={10}
        fullWidth
        autoComplete="off"
      />
      {!!experienceList.length && (
        <CustomSelect
          name="experienceIdArr"
          label="関連するエクスペリエンス"
          multiple
          options={experienceList}
          renderCardContent={(item) => (
            <ExperienceCardContent
              {...{ item }}
              isWinningVariantHidden
              contentComponentProps={{ sx: { px: 1.5, py: 1 } }}
            />
          )}
        />
      )}
    </Stack>
  );
}
