import React, { useMemo } from "react";
import { Stack, Typography, Alert, AlertTitle, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from "@mui/material";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";

import Drawer from "../containers/Drawer";

import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";
import ExternalLink from "../elements/ExternalLink";
import RequestStatusAlert from "./RequestStatusAlert";
import AnalyticsDrawerContent from "./AnalyticsDrawerContent";
import SupportButton from "../elements/SupportButton";

const title = "リンク先のGoogleアナリティクス"; const supportId = "container-ga4";

export default (props) => {
  const drawer = Drawer.useContainer();
  const { container = {}, requestList = [], readOnly, handleAddRequest, handleConfirmRequest } = props;
  const { ga4Info } = container;
  const ga4RequestList = useMemo(() => requestList.filter(({ action }) => action === "updateGa4Info"), [requestList]);

  function handleEditClick() {
    drawer.open({
      title,
      supportId,
      btnLabel: "更新",
      defaultValue: ga4Info,
      ContentComponent: AnalyticsDrawerContent,
      isWide: true,
      onSuccess: (ga4Info) => {
        handleAddRequest({ action: "updateGa4Info", ga4Info, status: "processing", isActive: true })
          .then(() => setTimeout(drawer.close, 500));
        },
    });
  }

  return (
    <BuilderCard
      {...{ title, supportId }}
      color="warning"
      Icon={SignalCellularAltOutlinedIcon}
      action={<ActionButton type="edit" onClick={handleEditClick} isLocked={readOnly} requiredRole="admin" />}
      renderContent={() => (
        <Stack spacing={2}>
          {!!ga4RequestList.length && ga4RequestList.map((request) => (
            <RequestStatusAlert
              key={request.id}
              request={request}
              handleConfirm={() => handleConfirmRequest(request.id)}
            />
          ))}
          {!ga4Info?.propertyId && (
            <Alert severity="error">
              <AlertTitle>{ga4Info?.measurementId ? "再設定が必要です" : "設定されていません"}</AlertTitle>
              {ga4Info?.measurementId ? "測定IDの手動入力は、β版のみの仕様です。正式版では、アクセス可能なプロパティの一覧から選択する必要があります。" : "エクスペリエンスを開始するには、計測を有効にしてください。"}
            </Alert>
          )}
          <Stack direction="row" spacing={2}>
            <Stack flex={1} spacing={1}>
              <Typography variant="caption">プロパティ</Typography>
              {!!ga4Info?.propertyId ? (
                <ExternalLink
                  href={`https://analytics.google.com/analytics/web/#/p${ga4Info.propertyId}`}
                  label={ga4Info.propertyName}
                />
              ) : (
                <Typography variant="h5" color="error" component="p">未設定</Typography>
              )}
            </Stack>
            <Stack flex={1} spacing={1}>
              <Typography variant="caption">ストリーム</Typography>
              {!!ga4Info ? (
                <Typography variant="body1">{`${ga4Info.streamName || "不明"}（${ga4Info.measurementId}）`}</Typography>
              ) : (
                <Typography variant="h5" color="error" component="p">未設定</Typography>
              )}
            </Stack>
          </Stack>
          
          <Alert
            severity="info"
            action={<SupportButton supportId="container-ga4-custom-dimension" />}
          >
            エクスペリエンスを開始する前に、下記のカスタムディメンションを作成しておくと、Googleアナリティクス上でもテスト結果を分析できます。
          </Alert>
          <TableContainer variant="outlined" component={Paper} sx={{ width: "100%" }}>
            <Table>
              <TableHead sx={{ "& th": { fontSize: "small", color: "gray", padding: "8px 16px" } }}>
                <TableRow>
                  <TableCell>ディメンション名（推奨）</TableCell>
                  <TableCell>イベントパラメータ</TableCell>
                  <TableCell>概要</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dimensionList.map((dimension) => (
                  <TableRow key={dimension.key}>
                    <TableCell>{dimension.name}</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>{dimension.key}</TableCell>
                    <TableCell>{dimension.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Stack>
      )}
    />
  );
}

const dimensionList = [
  { key: "exp_variant_string", name: "パターンID", description: "どのエクスペリエンスのどのパターンかを一意に識別するID" },
  { key: "optx_experience", name: "エクスペリエンス名", description: "入力したエクスペリエンスの名称" },
  { key: "optx_variant", name: "パターン名", description: "入力したパターンの名称" },
];
