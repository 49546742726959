import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CheckboxElement, TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Stack, Box, Typography, TextField, Button, List, ListItem, Paper } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import TagIcon from "@mui/icons-material/Tag";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";

import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";
import { getIsUrl, getIsUrlMatchingWithFilter, getIsUrlMatchingWithFilterList, getRedirectedUrl } from "../utils/url";
import CustomSelect from "../elements/CustomSelect";

export default (props) => {
  const { defaultValue, readOnly, page = {} } = props;
  const { editorUrl, filterList = [] } = page;
  const { setValue, getValues, reset, watch } = useFormContext();

  const [currentType, setCurrentType] = useState(defaultValue?.type);
  const [currentReplaceList, setCurrentReplaceList] = useState(defaultValue?.replaceList || []);
  const [currentQueryList, setCurrentQueryList] = useState(defaultValue?.queryList || []);
  const [currentFragment, setCurrentFragment] = useState(defaultValue?.fragment || "");
  const [currentExampleUrlArr, setCurrentExampleUrlArr] = useState(defaultValue?.exampleUrlArr || []);
  const [exampleUrlInputValue, setExampleUrlInputValue] = useState("");

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue);
      if (!defaultValue.measurementStrategy) { setValue("measurementStrategy", "cookie"); }
    }
    return () => reset({});
  }, []);

  useEffect(() => {
    const subscription = watch(({ type, replaceList = [], queryList = [], fragment, exampleUrlArr = [] }) => {
      setCurrentType(type);
      setCurrentReplaceList([...replaceList]);
      setCurrentQueryList([...queryList]);
      setCurrentFragment(fragment);
      setCurrentExampleUrlArr([...exampleUrlArr]);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleAddReplace = useCallback(() => {
    setValue("replaceList", currentReplaceList.concat({
      before: "",
      after: "",
      isRegExp: false,
    }));
  });

  const handleRemoveReplace = useCallback((index) => {
    reset({
      ...getValues(),
      replaceList: currentReplaceList.filter((_, i) => i !== index),
    });
  });

  const handleAddQuery = useCallback(() => {
    setValue("queryList", currentQueryList.concat({
      key: "",
      value: "",
    }));
  });

  const handleRemoveQuery = useCallback((index) => {
    reset({
      ...getValues(),
      queryList: currentQueryList.filter((_, i) => i !== index),
    });
  });

  const handleExampleUrlInputChange = useCallback(({ target: { value } }) => {
    setExampleUrlInputValue(value);
  });

  const handleAddExampleUrl = useCallback(() => {
    if ([editorUrl, ...currentExampleUrlArr].includes(exampleUrlInputValue)) { return; }
    setValue("exampleUrlArr", currentExampleUrlArr.concat(exampleUrlInputValue));
    setExampleUrlInputValue("");
  });

  const handleRemoveExampleUrl = useCallback((index) => {
    reset({
      ...getValues(),
      exampleUrlArr: currentExampleUrlArr.filter((_, i) => i !== index - 1), //editorUrlがあるので-1
    }, { keepErrors: true });
  });

  const isExampleUrlInvalid = useMemo(() => (
    !!exampleUrlInputValue && !getIsUrl(exampleUrlInputValue)
  ), [exampleUrlInputValue]);

  const isExampleUrlUnmatching = useMemo(() => (
    !!exampleUrlInputValue && !filterList.every((filter) => getIsUrlMatchingWithFilter(exampleUrlInputValue, filter))
  ), [exampleUrlInputValue, filterList]);

  const getCurrentRedirectedUrl = useCallback((url) => {
    return getRedirectedUrl(url, {
      type: currentType,
      replaceList: currentReplaceList,
      queryList: currentQueryList,
      fragment: currentFragment,
    });
  }, [currentType, currentReplaceList, currentQueryList, currentFragment]);

  return (
    <Stack spacing={4} p={4}>
      <CustomSelect
        name="measurementStrategy"
        label="計測方法"
        options={[
          { id: "cookie", description: "一時的に専用のCookieを付与します（別ドメインにリダイレクトさせる場合は選択不可）" },
          { id: "query", description: "リダイレクト先のURLに専用のクエリパラメータを付与します" },
        ]}
        required
        {...{ readOnly }}
      />

      <CustomSelect
        name="type"
        label="タイプ"
        options={[
          { id: "single", description: "特定のURLにリダイレクトさせます" },
          { id: "advanced", description: "もとのURLの一部を変更してリダイレクトさせます" },
        ]}
        required
        {...{ readOnly }}
      />

      {(currentType === "single") && (
        <Stack spacing={4}>
          <TextFieldElement
            name="url"
            label="リダイレクト先URL"
            placeholder={editorUrl}
            type="url"
            required
            fullWidth
            InputProps={{ readOnly }}
          />
          <Stack>
            <CheckboxElement
              name="isSearchInherited"
              label="クエリパラメータを引き継ぐ"
              size="small"
              disabled={readOnly}
            />
            <CheckboxElement
              name="isHashInherited"
              label="フラグメントを引き継ぐ"
              size="small"
              disabled={readOnly}
            />
          </Stack>
        </Stack>
      )}

      {(currentType === "advanced") && (
        <Stack spacing={2}>
          <BuilderCard
            title="置換"
            color="info"
            Icon={AutorenewIcon}
            items={currentReplaceList}
            onAddClick={!readOnly && handleAddReplace}
            renderItem={(_, i) => (
              <Stack key={i} spacing={2} pt={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <TextFieldElement
                    name={`replaceList[${i}].before`}
                    label="ドメイン+パス内を検索"
                    required
                    size="small"
                    autoComplete="off"
                    sx={{ flex: 1 }}
                    InputProps={{ readOnly }}
                  />
                  <ArrowForwardIcon />
                  <TextFieldElement
                    name={`replaceList[${i}].after`}
                    label="置換するテキスト"
                    size="small"
                    autoComplete="off"
                    sx={{ flex: 1 }}
                    InputProps={{ readOnly }}
                  />
                  {!readOnly && (
                    <Box width="24px">
                      <ActionButton
                        type="delete"
                        onClick={() => handleRemoveReplace(i)}
                      />
                    </Box>
                  )}
                </Stack>
                <Stack>
                  <CheckboxElement
                    name={`replaceList[${i}].isRegExp`}
                    label="正規表現を使用"
                    size="small"
                    disabled={readOnly}
                  />
                  {currentReplaceList[i].isRegExp && (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <CheckboxElement
                        name={`replaceList[${i}].globalSearchFlag`}
                        label="全てに適用"
                        size="small"
                        disabled={readOnly}
                      />
                      <CheckboxElement
                        name={`replaceList[${i}].ignoreCaseFlag`}
                        label="大文字と小文字を区別しない"
                        size="small"
                        disabled={readOnly}
                      />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            )}
          />

          <BuilderCard
            title="クエリパラメータ"
            color="secondary"
            Icon={QuestionMarkIcon}
            items={currentQueryList}
            onAddClick={!readOnly && handleAddQuery}
            renderItem={(_, i) => (
              <Stack key={i} direction="row" spacing={2} alignItems="center">
                <TextFieldElement
                  name={`queryList[${i}].key`}
                  label="クエリのキー"
                  required
                  size="small"
                  autoComplete="off"
                  sx={{ flex: 1 }}
                  InputProps={{ readOnly }}
                />
                <TextFieldElement
                  name={`queryList[${i}].value`}
                  label="クエリの値"
                  size="small"
                  autoComplete="off"
                  sx={{ flex: 1 }}
                  InputProps={{ readOnly }}
                />
                {!readOnly && (
                  <Box width="24px">
                    <ActionButton
                      type="delete"
                      onClick={() => handleRemoveQuery(i)}
                    />
                  </Box>
                )}
              </Stack>
            )}
          />

          <BuilderCard
            title="フラグメント"
            color="success"
            Icon={TagIcon}
            renderContent={() => (
              <TextFieldElement
                name="fragment"
                label="フラグメントの値"
                size="small"
                autoComplete="off"
                sx={{ flex: 1 }}
                InputProps={{ readOnly }}
              />
            )}
          />

          <Stack spacing={2} p={3} bgcolor="#f1f3f4">
            <Typography variant="body2">リダイレクトの確認</Typography>
            <Stack direction="row" spacing={1} alignItems="flex-start">
              <TextField
                placeholder="URLを入力"
                type="url"
                size="small"
                fullWidth
                InputProps={{ sx: { bgcolor: "#fff" } }}
                value={exampleUrlInputValue}
                onChange={handleExampleUrlInputChange}
                error={isExampleUrlInvalid || isExampleUrlUnmatching}
                onKeyDown={(e) => { if (e.key === "Enter") { handleAddExampleUrl(); } }}
                helperText={isExampleUrlInvalid ? "有効なURLを入力してください" : (isExampleUrlUnmatching && "ページの条件に一致しません")}
              />
              <Button
                onClick={handleAddExampleUrl}
                disabled={!exampleUrlInputValue || isExampleUrlInvalid}
              >
                確認
              </Button>
            </Stack>

            <List component={Paper} elevation={0} sx={{ p: 0 }}>
              {[editorUrl, ...currentExampleUrlArr].map((url, i) => {
                const redirectedUrl = getCurrentRedirectedUrl(url);
                const isUrlMatching = getIsUrlMatchingWithFilterList(url, filterList);
                return (
                  <ListItem key={i} divider>
                    <Stack spacing={1} width="100%">
                      <Stack direction="row" alignItems="center" width="100%">
                        <Typography
                          variant="body2"
                          flex={1}
                          sx={{ lineBreak: "anywhere" }}
                          color={!isUrlMatching && "error.main"}
                        >
                          {url}
                        </Typography>
                        {!!i && (
                          <ActionButton
                            type="remove"
                            onClick={() => handleRemoveExampleUrl(i)}
                          />
                        )}
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1} sx={{ opacity: isUrlMatching ? 1 : 0.6 }}>
                        {getIsUrl(redirectedUrl) ? (
                          <CheckCircleOutlineIcon color="success" />
                        ) : (
                          <BlockIcon color="error" />
                        )}
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{ lineBreak: "anywhere" }}
                        >
                          {redirectedUrl}
                        </Typography>
                      </Stack>
                    </Stack>
                  </ListItem>
                );
              })}
            </List>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
