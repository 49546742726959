import React, { useEffect } from "react";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

import { getIsUrl } from "../utils/url";

export default (props) => {
  const { defaultValue } = props;
  const { watch, reset, setError, clearErrors } = useFormContext();

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  useEffect(() => {
    const subscription = watch(({ defaultUrl }) => {
      if (defaultUrl) {
        if (!getIsUrl(defaultUrl)) {
          setError("defaultUrl", {
            type: "manual",
            message: "有効なURLを入力してください",
          });
        } else {
          clearErrors("defaultUrl");
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Stack spacing={4} p={4}>
      <TextFieldElement
        name="name"
        label="コンテナ名"
        required
        fullWidth
        autoFocus
        placeholder="PROJECT GROUP コーポレートサイト"
        autoComplete="off"
      />
      <TextFieldElement
        name="defaultUrl"
        label="対象サイトのURL"
        type="url"
        required
        fullWidth
        placeholder="https://project-g.co.jp"
        helperText="通常、対象サイトのトップページのURLを入力します。"
      />
      <TextFieldElement
        name="companyName"
        label="会社名"
        fullWidth
        placeholder="PROJECT GROUP株式会社"
      />
    </Stack>
  );
}
