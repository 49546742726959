import React, { useMemo } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Card, CardHeader, CircularProgress, Stack, Tooltip, Typography } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

import AlertDialog from "../containers/AlertDialog";

import ExperienceCard from "./ExperienceCard";
import MenuButton from "../elements/MenuButton";
import ExternalLink from "../elements/ExternalLink";
import CopyButton from "../elements/CopyButton";

import { getAssetUrl } from "../utils/url";

export default (props) => {
  const { container = {}, item = {}, experienceList = [], readOnly, onEditClick, onDeleteClick } = props;
  const { name, description, status, fileName } = item;
  const alertDialog = AlertDialog.useContainer();

  const url = useMemo(() => getAssetUrl(item), [fileName]);

  const relatedExperienceList = useMemo(() => (
    experienceList.filter(({ pageVariantObj = {} }) => (
      Object.values(pageVariantObj).some(({ changeList = [] }) => (
        changeList.some(({ value }) => value === url)
      ))
    )
  )), [experienceList, url]);

  function openDeletionAlert() {
    alertDialog.open({
      title: "この画像は利用中です",
      description: "エクスペリエンスで利用されている画像は削除できません。",
    });
  }

  return (
    <Card variant="outlined">
      <CardHeader
        title={name}
        avatar={<ImageIcon color={relatedExperienceList.length ? "primary" : "action"} />}
        action={!readOnly && (
          <MenuButton
            items={[
              {
                label: "情報を編集",
                Icon: EditIcon,
                color: "primary",
                onClick: onEditClick,
              },
              {
                label: "削除",
                Icon: DeleteIcon,
                color: "error",
                onClick: relatedExperienceList.length ? openDeletionAlert : onDeleteClick,
              },
            ]}
          />
        )}
      />
      <Stack px={1.5} alignItems="center">
        <Tooltip
          title={description}
          placement="top"
          componentsProps={{ tooltip: { sx: { whiteSpace: "pre-wrap" } } }}
        >
          <Stack width="100%" maxHeight="280px" alignItems="center" justifyContent="center" overflow="hidden" sx={{ aspectRatio: "2" }}>
           {(status === "ready") ? (
              <img
                src={url}
                alt={item.name}
                style={{ width: "100%", height: "100%", objectFit: "contain", borderRadius: 4 }}
              />
            ) : (status === "processing") ? (
              <CircularProgress />
            ) : (status === "failed") && (
              <ImageNotSupportedIcon fontSize="large" color="action" />
            )}
          </Stack>
        </Tooltip>
        {((status === "ready") && (
          <Stack direction="row" alignItems="center">
            <ExternalLink href={url} label={fileName} isIconHidden />
            <CopyButton value={url} />
          </Stack>
        ))}
      </Stack>
      <Accordion
        disabled={!relatedExperienceList.length}
        disableGutters
        square
        sx={{ "&:before": { display: "none" } }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="caption">
            {`利用中のエクスペリエンス (${relatedExperienceList.length})`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={0.5}>
            {relatedExperienceList.map((experience) => (
              <ExperienceCard
                key={experience.id}
                item={experience}
                {...{ container }}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}
