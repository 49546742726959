import AndroidIcon from "@mui/icons-material/Android";
import CodeIcon from "@mui/icons-material/Code";
import CookieIcon from "@mui/icons-material/Cookie";
import DataArrayIcon from "@mui/icons-material/DataArray";
import DevicesIcon from "@mui/icons-material/Devices";
import ExploreIcon from "@mui/icons-material/Explore";
import SwitchRightIcon from "@mui/icons-material/SwitchRight";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
// import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";

const types = [
  { value: "device", label: "デバイスカテゴリ", Icon: DevicesIcon, options: ["mobile", "tablet", "desktop"] },
  { value: "browser", label: "ブラウザ", Icon: ExploreIcon, options: ["Safari", "Chrome", "InternetExplorer", "Edge", "Firefox", "Opera", "Unknown"] },
  { value: "os", label: "OS", Icon: AndroidIcon, options: ["iOS", "Android", "Macintosh", "Windows", "WindowsPhone", "ChromeOS", "Linux", "Unknown"] },
  { value: "referrer", label: "参照元URL", Icon: SwitchRightIcon },
  { value: "query", label: "クエリパラメータ", Icon: QuestionMarkIcon, isKeyNeeded: true },
  { value: "cookie", label: "Cookie（ファーストパーティ）", Icon: CookieIcon, isKeyNeeded: true },
  { value: "global", label: "グローバル変数", Icon: CodeIcon, isKeyNeeded: true },
  { value: "dataLayer", label: "データレイヤー変数", Icon: DataArrayIcon, isKeyNeeded: true },
  // { value: "js", label: "カスタムJavaScript", Icon: DeveloperModeIcon, isCustomVariable: true },
];

export function getConditionTypeObj(type) {
  return types.find((x) => x.value === type) || {};
}

export default types;
