import React from "react";
import { Stack, Chip } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";

import Drawer from "../containers/Drawer";
import GoalDrawerContent from "./GoalDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";

const title = "目標イベント", supportId = "experience-report-goals";

export default (props) => {
  const { container, experience, readOnly, handleUpdate } = props;
  const drawer = Drawer.useContainer();
  const { goalEventNameArr = [] } = experience;

  function handleEdit() {
    const defaultValue = { goalEventNameArr };
    drawer.open({
      title,
      supportId,
      btnLabel: "更新",
      defaultValue,
      ContentComponent: GoalDrawerContent,
      contentComponentProps: { container, experience },
      onSuccess: handleUpdate,
    });
  }

  return (
    <BuilderCard
      {...{ title, supportId }}
      color="warning"
      Icon={FlagIcon}
      renderItem={() => (
        <Stack key={0} direction="row" spacing={4} alignItems="center">
          <Stack spacing={1} flex={4} direction="row" alignItems="center">
            {!!goalEventNameArr.length
              ? goalEventNameArr?.map((eventName, i) => (
                <Chip key={i} label={eventName} variant="outlined" />
              ))
              : <Chip label="未設定" />
            }
          </Stack>

          {!readOnly && (
            <Stack flex={1} direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
              <ActionButton type="edit" onClick={handleEdit} />
            </Stack>
          )}
        </Stack>
      )}
    />
  );
}
