import React  from "react";
import { Button, Stack } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";

export default (props) => {
  const { containerId, plan } = props;
  return (
    <Stack width="100%" alignItems="center">
      <Button
        href={`/containers/${containerId}?plan=${plan}#subscription`}
        target="_blank"
        variant="contained"
        color="premium"
        startIcon={<VerifiedIcon />}
        sx={{ width: "100%", maxWidth: "20rem", fontWeight: "bold", lineHeight: "2rem" }}
      >
        すべてのプランを確認
      </Button>
    </Stack>
  );
};
