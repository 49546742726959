import React, { useState, useEffect, useCallback, useMemo } from "react";
import { CheckboxElement, SelectElement, TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Stack, Card, CardContent, Divider, Typography, Chip, Box, TextField, Button, ListItem, List, FormHelperText } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";

import AddButton from "../elements/AddButton";
import ActionButton from "../elements/ActionButton";

import selectOptions from "../utils/selectOptions";
import { getIsUrl, getIsUrlMatchingWithFilter, getIsUrlMatchingWithFilterList, getPreviewUrl } from "../utils/url";
import MultiInputElement from "../elements/MultiInputElement";

export default (props) => {
  const { experienceId, defaultValue } = props;
  const { setValue, getValues, reset, watch, setError, clearErrors, getFieldState } = useFormContext();
  const [currentEditorUrl, setCurrentEditorUrl] = useState(defaultValue?.editorUrl);
  const [currentFilterList, setCurrentFilterList] = useState(defaultValue?.filterList || []);
  const [currentExampleUrlArr, setCurrentExampleUrlArr] = useState(defaultValue?.exampleUrlArr || []);
  const [exampleUrlInputValue, setExampleUrlInputValue] = useState("");

  const currentAllExampleUrlArr = useMemo(() => (
    [currentEditorUrl, getPreviewUrl(currentEditorUrl, experienceId), ...currentExampleUrlArr].filter((x) => !!x)
  ), [currentEditorUrl, currentExampleUrlArr]);

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  useEffect(() => {
    const subscription = watch(({ editorUrl, filterList = [], exampleUrlArr = [] }) => {
      setCurrentEditorUrl(editorUrl);
      setCurrentFilterList([...filterList]);
      setCurrentExampleUrlArr([...exampleUrlArr]);
      if (editorUrl) {
        if (!getIsUrl(editorUrl)) {
          setError("editorUrl", {
            type: "manual",
            message: "有効なURLを入力してください",
          });
        } else {
          clearErrors("editorUrl");
          setFilterListErrors(filterList, editorUrl);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  function setFilterListErrors(filterList = currentFilterList, editorUrl = currentEditorUrl) {
    filterList.forEach((filter, i) => {
      if ((filter?.target === "canonicalUrl") && filter?.valueArr?.some((value = "") => value.includes("?"))) {
        setError(`filterList[${i}]`, {
          type: "manual",
          message: "正規URLはクエリ文字列を含みません",
        });
      } else if ((filter?.target === "canonicalUrl") && filter?.valueArr?.some((value = "") => value.includes("#"))) {
        setError(`filterList[${i}]`, {
          type: "manual",
          message: "正規URLはフラグメントを含みません",
        });
      } else if (!getIsUrlMatchingWithFilter(editorUrl, filter)) {
        setError(`filterList[${i}]`, {
          type: "manual",
          message: "エディタページのURLがこの条件に一致しません",
        });
      } else if (!getIsUrlMatchingWithFilter(getPreviewUrl(editorUrl, experienceId), filter)) {
        setError(`filterList[${i}]`, {
          type: "manual",
          message: "プレビュー用URLがこの条件に一致しません",
        });
      } else {
        clearErrors(`filterList[${i}]`);
      }
    });
  }

  const isExampleUrlInputValueInvalid = useMemo(() => (
    !!exampleUrlInputValue && !getIsUrl(exampleUrlInputValue)
  ), [exampleUrlInputValue]);

  const isExampleUrlInputDisabled = useMemo(() => (
    !exampleUrlInputValue || isExampleUrlInputValueInvalid || currentAllExampleUrlArr.includes(exampleUrlInputValue)
  ), [exampleUrlInputValue, currentAllExampleUrlArr]);

  const getIsUrlMatching = useCallback((url) => (
    getIsUrlMatchingWithFilterList(url, currentFilterList)
  ), [currentFilterList]);

  const handleAddFilter = useCallback(() => {
    setValue("filterList", currentFilterList.concat({
      target: "canonicalUrl",
      method: "equals",
      valueArr: "",
    }));
  });

  const handleRemoveFilter = useCallback((index) => {
    const filterList = currentFilterList.filter((_, i) => i !== index);
    reset({
      ...getValues(),
      filterList,
    });
    setFilterListErrors(filterList);
  });

  const handleExampleUrlInputChange = useCallback(({ target: { value } }) => {
    setExampleUrlInputValue(value);
  });

  const handleAddExampleUrl = useCallback(() => {
    if (isExampleUrlInputDisabled) { return; }
    setValue("exampleUrlArr", currentExampleUrlArr.concat(exampleUrlInputValue));
    setExampleUrlInputValue("");
  });

  const handleRemoveExampleUrl = useCallback((index) => {
    reset({
      ...getValues(),
      exampleUrlArr: currentExampleUrlArr.filter((_, i) => i !== index),
    }, { keepErrors: true });
  });

  return (
    <Stack spacing={4} p={4}>
      <TextFieldElement
        name="name"
        label="ページ名"
        required
        inputProps={{
          maxLength: 20,
        }}
        fullWidth
        autoComplete="off"
      />

      <TextFieldElement
        name="editorUrl"
        label="エディタページのURL"
        type="url"
        required
        fullWidth
      />

      {!!currentFilterList.length && (
        <>
          <Divider />
          <Typography variant="h5">条件</Typography>
          <Stack spacing={1}>
            {currentFilterList?.map((_, i) => {
              const { error } = getFieldState(`filterList[${i}]`);
              return (
                <Stack key={i} alignItems="center">
                  {(i > 0) && <Chip label="かつ" sx={{ mb: 1 }} />}
                  <Card variant="outlined" sx={{ width: "100%", borderColor: !!error ? "error.main" : undefined }}>
                    <CardContent sx={{ padding: "16px 24px !important" }}>
                      <Stack spacing={2}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <SelectElement
                            name={`filterList[${i}].target`}
                            label="URL変数"
                            options={selectOptions("pageTarget")}
                            required
                            size="small"
                            sx={{ flex: 1 }}
                          />
                          <SelectElement
                            name={`filterList[${i}].method`}
                            label="演算子"
                            options={selectOptions("method")}
                            required
                            size="small"
                            sx={{ flex: 1 }}
                          />
                          <Box width="24px">
                            {currentFilterList.length > 1 && (
                              <ActionButton
                                type="delete"
                                onClick={() => handleRemoveFilter(i)}
                              />
                            )}
                          </Box>
                        </Stack>
                        <MultiInputElement
                          name={`filterList[${i}].valueArr`}
                          label="値"
                        />
                        {currentFilterList[i].method?.endsWith("match") && (
                          <CheckboxElement
                            name={`filterList[${i}].ignoreCaseFlag`}
                            label="大文字と小文字の違いを無視"
                            size="small"
                          />
                        )}
                        {!!error && <FormHelperText error>{error?.message}</FormHelperText>}
                      </Stack>
                    </CardContent>
                  </Card>
                </Stack>
              );
            })}
          </Stack>
          <AddButton onClick={handleAddFilter} label="条件" color="primary" />

          <Stack spacing={2} p={3} bgcolor="#f1f3f4">
            <Typography variant="h6">条件の確認</Typography>
            <Stack direction="row" spacing={1} alignItems="flex-start">
              <TextField
                placeholder="URLを入力..."
                type="url"
                size="small"
                fullWidth
                InputProps={{ sx: { bgcolor: "#fff" } }}
                value={exampleUrlInputValue}
                onChange={handleExampleUrlInputChange}
                onKeyDown={(e) => { if (e.key === "Enter") { handleAddExampleUrl(); } }}
                error={isExampleUrlInputValueInvalid}
                helperText={isExampleUrlInputValueInvalid && "有効なURLを入力してください"}
              />
              <Button
                onClick={handleAddExampleUrl}
                disabled={isExampleUrlInputDisabled}
              >
                確認
              </Button>
            </Stack>

            <List sx={{ bgcolor: "background.paper", p: 0 }}>
              {currentAllExampleUrlArr.map((url, i) => {
                const index = i - (currentAllExampleUrlArr.length - currentExampleUrlArr.length);
                return (
                  <ListItem key={i} divider>
                    <Stack direction="row" alignItems="center" spacing={2} width="100%">
                      {getIsUrlMatching(url) ? (
                        <CheckCircleOutlineIcon color="success" />
                      ) : (
                        <BlockIcon color="error" />
                      )}
                      <Typography variant="body2" flex={1} sx={{ lineBreak: "anywhere" }}>
                        {url}
                      </Typography>
                      {(index > -1) && (
                        <ActionButton
                          type="remove"
                          onClick={() => handleRemoveExampleUrl(index)}
                        />
                      )}
                    </Stack>
                  </ListItem>
                );
              })}
            </List>
          </Stack>
        </>
      )}
    </Stack>
  );
}
