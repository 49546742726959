import { createContainer } from "unstated-next";
import { doc } from "firebase/firestore";

import Firestore from "./Firestore";

export default createContainer(({ templateId }) => {
  const { templateColRef, useDocData, updateDocData } = Firestore.useContainer();

  const templateDocRef = doc(templateColRef, templateId);

  const { data: docData = {} } = useDocData(templateDocRef);

  function update(data, operation = "テンプレートを更新") {
    return updateDocData(templateDocRef, data, operation);
  }

  return { docData, update };
});
