import React, { useEffect } from "react";
import { Alert, AlertTitle } from "@mui/material";

import Header from "../containers/Header";
import FirestoreSession from "../containers/FirestoreSession";

import LoadingStack from "../elements/LoadingStack";

export default () => {
  const header = Header.useContainer();
  const { docData: session = {} } = FirestoreSession.useContainer();

  useEffect(() => {
    header.setTitle("サブスクリプションの管理");
    return () => {
      header.setTitle(null);
    }
  }, []);

  useEffect(() => {
    header.setAlert(session.notFound ? { children: "無効なURLです。" } : null);
  }, [session.notFound]);

  useEffect(() => {
    if (session.url) {
      document.location.href = session.url;
    }
  }, [session.url]);

  if (session.notFound) {
    return null;
  }

  if (session.status === "failed") {
    return (
      <Alert severity="error" sx={{ my: 4, mx: "auto", width: "90%", maxWidth: "sm" }}>
        <AlertTitle>カスタマーポータルを作成できませんでした</AlertTitle>
        もう一度やり直してください。
      </Alert>
    );
  }

  return <LoadingStack label="カスタマーポータルを準備しています" />;
}
