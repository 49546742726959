import React, { useMemo, useState } from "react";
import { FormContainer } from "react-hook-form-mui";
import { Stack } from "@mui/material";

import Firestore from "../containers/Firestore";
import FirestoreContainer from "../containers/FirestoreContainer";

import IndicatorListForm from "../components/IndicatorListForm";
import RequestStatusAlert from "../components/RequestStatusAlert";

export default (props) => {
  const { requestList = [], ...restProps } = props;
  const firestore = Firestore.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const { docData: container, currentUserRole } = firestoreContainer;

  const importRequestList = useMemo(() => requestList.filter(({ action }) => action === "importTemplate"), [requestList]);

  async function handleConfirmRequest(requestId) {
    firestoreContainer.updateRequest(requestId, { isActive: false });
  }

  return (
    <>
      {!!importRequestList.length && (
        <Stack spacing={2} py={2} alignItems="center">
          {importRequestList.map((request) => (
            <RequestStatusAlert
              key={request.id}
              request={request}
              handleConfirm={() => handleConfirmRequest(request.id)}
            />
          ))}
        </Stack>
      )}
      <IndicatorListComponent
        {...{ container, ...restProps }}
        readOnly={currentUserRole === "viewer"}
        handleCreate={firestoreContainer.addIndicator}
        handleUpdate={firestoreContainer.updateIndicator}
        handleDelete={firestoreContainer.deleteIndicator}
        handleCreateHypothesis={firestoreContainer.addHypothesis}
        handleUpdateHypothesis={firestoreContainer.updateHypothesis}
        handleDeleteHypothesis={firestoreContainer.deleteHypothesis}
        handleCreateExperience={firestoreContainer.addExperienceFromTemplate}
        handleCreateTemplate={firestore.addTemplate}
      />
    </>
  );
}

export const IndicatorListComponent = (props) => {
  const { handleCreate, handleUpdate, handleDelete, ...restProps } = props;
  const [focusedIndicatorId, setFocusedIndicatorId] = useState();
  const [ghostParentIndicatorId, setGhostParentIndicatorId] = useState();
  const [isUnderSubmission, setIsUnderSubmission] = useState(false);

  function handleSubmit(data) {
    setIsUnderSubmission(true);
    if (focusedIndicatorId) {
      handleUpdate(focusedIndicatorId, data)
        .then(() => setFocusedIndicatorId(null))
        .finally(() => setIsUnderSubmission(false));
    } else {
      handleCreate({ ...data, parentIndicatorId: ghostParentIndicatorId || "root" })
        .then(() => setGhostParentIndicatorId(null))
        .finally(() => setIsUnderSubmission(false));
    }
  }

  function onDelete() {
    handleDelete(focusedIndicatorId).then(() => setFocusedIndicatorId(null));
  }

  return (
    <FormContainer onSuccess={handleSubmit} FormProps={{ style: { width: "100%" } }}>
      <IndicatorListForm
        {...restProps}
        {...{
          handleSubmit,
          focusedIndicatorId, setFocusedIndicatorId,
          ghostParentIndicatorId, setGhostParentIndicatorId,
          isUnderSubmission,
        }}
        handleDelete={onDelete}
      />
    </FormContainer>
  );
}
