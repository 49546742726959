import React, { useMemo } from "react";
import { Stack, Container, Divider } from "@mui/material";

import FirestoreContainer from "../containers/FirestoreContainer";

import ContainerInfoCard from "../components/ContainerInfoCard";
import AnalyticsCard from "../components/AnalyticsCard";
import SnippetCard from "../components/SnippetCard";
import UserListCard from "../components/UserListCard";
import ContainerDeletionCard from "../components/ContainerDeletionCard";

export default (props) => {
  const { requestList = [] } = props;
  const firestoreContainer = FirestoreContainer.useContainer();
  const { docData: container, billableUserCount, currentUserRole, currentPlan, checkIsUpgradeRequired } = firestoreContainer;

  const readOnly = useMemo(() => !["owner", "admin"].includes(currentUserRole), [currentUserRole]);

  const handleUpdate = firestoreContainer.update;

  const handleAddRequest = firestoreContainer.addRequest;

  async function handleConfirmRequest(requestId) {
    return firestoreContainer.updateRequest(requestId, { isActive: false });
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Stack spacing={4}>
        <ContainerInfoCard
          {...{ container, readOnly, handleUpdate }}
        />
        <AnalyticsCard
          {...{ container, requestList, readOnly, handleAddRequest, handleConfirmRequest }}
        />
        <SnippetCard
          {...{ container, readOnly, handleUpdate }}
        />
        <Divider />
        <UserListCard
          {...{ container, requestList, billableUserCount, readOnly, currentPlan, checkIsUpgradeRequired, handleUpdate, handleAddRequest, handleConfirmRequest }}
        />
        <ContainerDeletionCard
          {...{ container, requestList, currentPlan, handleAddRequest, handleConfirmRequest }}
          readOnly={currentUserRole !== "owner"}
        />
      </Stack>
    </Container>
  );
}
