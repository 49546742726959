import React, { useMemo } from "react";

export default (props) => {
  const { type, DefaultIcon, ...restProps } = props;
  const Icon = useMemo(() => getCustomIcon(type, DefaultIcon), [type, DefaultIcon]);
  if (!type || !Icon) { return null; }
  return <Icon {...restProps} />;
};

export function getCustomIcon(type, DefaultIcon) {
  switch (type) {
    case "edit": return require("@mui/icons-material/Edit").default;
    case "duplicate": return require("@mui/icons-material/ContentCopy").default;
    case "delete": return require("@mui/icons-material/Delete").default;
    case "deleteForever": return require("@mui/icons-material/DeleteForever").default;
    case "remove": return require("@mui/icons-material/RemoveCircle").default;
    case "preview": return require("@mui/icons-material/Visibility").default;
    case "add": return require("@mui/icons-material/AddCircle").default;
    case "lock": return require("@mui/icons-material/Lock").default;

    case "running": return require("@mui/icons-material/PlayCircleOutline").default;
    case "scheduled": return require("@mui/icons-material/Schedule").default;
    case "paused": return require("@mui/icons-material/PauseCircleOutline").default;
    case "closed": return require("@mui/icons-material/Done").default;
    case "archived": return require("@mui/icons-material/Archive").default;
    case "draft": return require("@mui/icons-material/Drafts").default;

    case "public": return require("@mui/icons-material/Public").default;
    case "limitedPublic": return require("@mui/icons-material/Link").default;
    case "private": return require("@mui/icons-material/Lock").default;

    case "owner": return require("@mui/icons-material/Security").default;
    case "admin": return require("@mui/icons-material/VerifiedUser").default;
    case "publisher": return require("@mui/icons-material/Publish").default;
    case "editor": return require("@mui/icons-material/Edit").default;
    case "viewer": return require("@mui/icons-material/Visibility").default;

    case "abtest": return require("@mui/icons-material/Compare").default;
    case "rollout": return require("@mui/icons-material/AllInclusive").default;

    case "modify": return require("@mui/icons-material/AutoFixHigh").default;
    case "redirect": return require("@mui/icons-material/AirlineStops").default;

    case "integrate": return require("@mui/icons-material/CompareArrows").default;
    case "overwrite": return require("@mui/icons-material/WarningAmber").default;

    case "totalUsers": return require("@mui/icons-material/PersonOutline").default;
    case "sessions": return require("@mui/icons-material/Web").default;

    case "proven": case "hasProven": return require("@mui/icons-material/TaskAlt").default;
    case "disproven": case "hasDisproven": return require("@mui/icons-material/Close").default;
    case "unknown": case "unverified": return require("@mui/icons-material/QuestionMark").default;
    case "underVerification": return require("@mui/icons-material/PlayArrow").default;

    case "original": return require("@mui/icons-material/SentimentVeryDissatisfied").default;
    case "draw": return require("@mui/icons-material/SentimentNeutral").default;

    case "free": return require("@mui/icons-material/MoneyOff").default;
    case "starter": case "basic": case "premium": return require("@mui/icons-material/Verified").default;

    default: return DefaultIcon || require("@mui/icons-material/Help").default;
  }
}
