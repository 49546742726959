import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { createContainer } from "unstated-next";
import { Avatar, IconButton, Popover, Button, Toolbar, Typography, Tabs, Tab, Box, Alert, Stack, Chip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VerifiedIcon from "@mui/icons-material/Verified";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

import Firebase from "./Firebase";
import AlertDialog from "./AlertDialog";
import StartButton from "../elements/StartButton";
import ChipWithIcon from "../elements/ChipWithIcon";

export default createContainer(() => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, signOut } = Firebase.useContainer();
  const alertDialog = AlertDialog.useContainer();

  const [title, setTitle] = useState(null);
  const [titleRight, setTitleRight] = useState(null);
  const [titleChip, setTitleChip] = useState(null);
  const [tabs, setTabs] = useState(null);
  const [tabScrollObj, setTabScrollObj] = useState({});
  const [alert, setAlert] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);

  const path = useMemo(() => location.pathname.replace(/\/+$/, "") || "/", [location.pathname]);
  const parentPath = useMemo(() => path.replace(/\/(|experiences\/|topics\/)[^/]+$/, ""), [path]);
  const parentFragment = useMemo(() => path.match(/topics\/.+/) ? "#support" : "", [path]);
  const fragment = useMemo(() => location.hash.replace(/^#/, ""), [location.hash]);
  const isTop = useMemo(() => ["/", "/containers", "/terms", "/policy", "/compliance"].includes(path), [path]);

  useEffect(() => {
    const finalTitle = title || "Optimize Next";
    if (document.title !== finalTitle) { document.title = finalTitle; }
  }, [title]);

  useEffect(() => {
    setTabScrollObj({});
  }, [tabs]);

  function handlePopoverOpen(event) {
    setAnchorEl(event.currentTarget);
  };

  function handlePopoverClose() {
    setAnchorEl(null);
  };

  function handleSignOut() {
    alertDialog.open({
      title: "ログアウトしますか？",
      description: `${currentUser?.displayName}（${currentUser?.email}）からログアウトしますか？`,
      cancelable: true,
      actions: [{ label: "ログアウト", color: "error", onClick }],
    }, "signOut");
    function onClick() {
      signOut();
      alertDialog.close("signOut");
      handlePopoverClose();
    }
  }

  function handleTabChange(_, newFragment) {
    const container = window.container;
    if (container) {
      setTabScrollObj((prev) => ({ ...prev, [fragment]: container.scrollTop }));
      handleScroll();
      setTimeout(handleScroll);
      function handleScroll() {
        container.scrollTo({ top: tabScrollObj[newFragment] || 0 });
      }
    }
  }

  const Component = (
    <>
      <Box sx={{ position: "sticky", top: 0, right: 0, left: 0 }} zIndex={100} ref={ref}>
        <Toolbar sx={{ backgroundColor: "white.main", borderBottom: 1, borderColor: "divider", paddingInline: { xs: "4px", sm: "16px" } }}>
          {!isTop && (
            <IconButton onClick={() => navigate(parentPath + parentFragment)}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Link
            to={isTop ? "/" : "/containers"}
            style={{ width: !!title ? "36px" : "224px", height: "36px", marginLeft: "4px", position: "relative" }}
          >
            <img
              alt="Optimize Next"
              src={!!title ? "/icon.png" : "/logo.png"}
              width={!!title ? "36px" : "224px"}
              height="36px"
              style={{ objectFit: "contain" }}
            />
            {(!!currentPlan && (currentPlan !== "free")) && (
              <VerifiedIcon
                fontSize="small"
                color={currentPlan}
                sx={{ position: "absolute", right: "-9px", top: "50%", translate: "0 -50%", backgroundColor: "#fff", borderRadius: "50%" }}
              />
            )}
          </Link>
          <Stack alignItems="start" flexGrow={1} mx={1.5} overflow="hidden">
            {!!titleChip && (
              <Stack direction="row" alignItems="center" mb={0.2}>
                <Chip
                  {...titleChip}
                  component={Link} variant="outlined" size="small"
                  sx={{ fontWeight: "bold", fontSize: "0.65rem", p: "0.05rem", height: "fit-content", "&:hover": { bgcolor: "background.focused" }, "& .MuiChip-label": { lineHeight: 1.65 } }}
                />
                <ChevronRightIcon color="action" fontSize="small" />
              </Stack>
            )}
            <Stack direction="row" spacing={1} alignItems="center" ml={0.5} width="-webkit-fill-available" maxWidth="lg">
              <Typography variant="h3" component="p" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {title}
              </Typography>
              {titleRight}
            </Stack>
          </Stack>

          {path.startsWith("/service") ? (
            <Avatar sx={{ bgcolor: "logo.main" }}>
              <SupportAgentIcon />
            </Avatar>
          ) : !!path.match(/^\/(containers|templates)/) ? (
            <>
              {!!currentUserRole && <ChipWithIcon type={currentUserRole} />}
              <IconButton onClick={handlePopoverOpen}>
                <Avatar
                  src={currentUser?.photoURL}
                  alt={currentUser?.displayName}
                />
              </IconButton>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Button onClick={handleSignOut}>
                  ログアウト
                </Button>
              </Popover>
            </>
          ) : (
            <StartButton sx={{ display: { xs: "none", sm: "block" }, px: 4, mr: 2 }} />
          )}
        </Toolbar>
        
        {!!tabs && (
          <Tabs
            value={fragment}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ backgroundColor: "white.main", borderBottom: 1, borderColor: "divider" }}
          >
            {tabs.map(({ value, label, Icon }) => (
              <Tab
                key={value}
                component={Link}
                to={"#" + value}
                iconPosition="start"
                icon={!!Icon && <Icon />}
                {...{ value, label }}
                // sx={{ fontWeight: "bold", letterSpacing: "0.025rem", minHeight: "48px" }}
                sx={{
                  fontWeight: "bold", letterSpacing: "0.025rem", minHeight: "48px",
                  minWidth: tabs.length > 5 ? undefined : { xs: undefined, md: "108px" },
                }}
              />
            ))}
          </Tabs>
        )}

        {!!alert && (
          <Alert severity="error" {...alert} />
        )}
      </Box>
    </>
  );

  return { Component, setTitle, setTitleRight, setTitleChip, setTabs, setAlert, setCurrentPlan, setCurrentUserRole, fragment };
});
