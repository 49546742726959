import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

export default (props) => {
  const { children, ...restProps } = props;
  return (
    <Swiper
      slidesPerView={1}
      simulateTouch={false}
      spaceBetween={30}
      // autoHeight
      {...restProps}
    >
      {children.map((Component, i) => (
        <SwiperSlide key={i}>
          {Component}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
