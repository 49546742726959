import React, { useMemo } from "react";
import { Button, Chip, Stack, Typography } from "@mui/material";
import { diff } from "deep-object-diff";

import Drawer from "../containers/Drawer";
import Extension from "../containers/Extension";

import ChangeListDrawerContent from "./ChangeListDrawerContent";
import RedirectDrawerContent from "./RedirectDrawerContent";

import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";
import ExternalLink from "../elements/ExternalLink";
import { getCustomIcon } from "../elements/CustomIcon";

import ja from "../utils/ja.json";
import { getRedirectedUrl } from "../utils/url";

const title = "変更内容";

export default (props) => {
  const { mode, type, pageList = [], variantList = [], pageVariantObj = {}, handleUpdate, handleUpdateExtra, handleOpenEditor, readOnly = false } = props;
  const drawer = Drawer.useContainer();
  const extension = Extension.useContainer();
  const isRollout = useMemo(() => (mode === "rollout"), [mode]);
  const supportId = useMemo(() => `experience-changes-${type}`, [type]);

  function handleOpenChangeListDrawer(page, variant, changeList) {
    drawer.open({
      chipLabel: page.name,
      title: isRollout ? "変更内容" : `「${variant.name}」の変更内容`,
      supportId,
      ContentComponent: ChangeListDrawerContent,
      contentComponentProps: { changeList },
      readOnly: true,
    });
  }

  function handleOpenRedirectDrawer(page, variant, defaultValue = { type: "single", measurementStrategy: "cookie", url: page.editorUrl }) {
    drawer.open({
      isWide: true,
      chipLabel: page.name,
      title: isRollout ? "変更内容" : `「${variant.name}」の変更内容`,
      supportId,
      btnLabel: "更新",
      defaultValue,
      ContentComponent: RedirectDrawerContent,
      contentComponentProps: { page },
      readOnly,
      onSuccess: (data) => {
        const isExtra = !Object.keys(diff(data, defaultValue) || {}).some((key) => key !== "exampleUrlArr");
        (isExtra ? handleUpdateExtra : handleUpdate)({
          [`pageVariantObj.${page.id}@${variant.id}`]: data,
        });
      },
    });
  }

  return (
    <BuilderCard
      {...{ title, supportId }}
      color="warning"
      Icon={getCustomIcon(type)}
      renderContent={() => (
        <Stack spacing={2}>
          {pageList.map((page, i) => (
            <BuilderCard
              key={i}
              title={page.name}
              titleRight={<ExternalLink href={page.editorUrl} />}
              color="warning"
              items={isRollout ? [{ id: "rollout", name: "100%反映" }] : variantList}
              renderItem={(variant, j) => {
                const isOriginal = (j === 0) && !isRollout;
                const pageVariantInfo = pageVariantObj[`${page.id}@${variant.id}`];
                switch (type) {
                  case "modify": {
                    const { changeList = [] } = pageVariantInfo || {};
                    return (
                      <Stack key={j} direction="row" spacing={4} alignItems="center">
                        <Stack flex={4} direction="row" alignItems="center">
                          <Typography variant="body2">
                            {variant.name}
                          </Typography>
                          {!isOriginal && (
                            <Button
                              onClick={() => handleOpenChangeListDrawer(page, variant, changeList)}
                              disabled={!changeList.length}
                              sx={{
                                fontWeight: "bold",
                                marginLeft: "auto !important",
                                "&.Mui-disabled": {
                                  color: "error.main"
                                },
                              }}
                            >
                              変更：{changeList.length}件
                            </Button>
                          )}
                        </Stack>
                        {!isOriginal && (
                          <Stack flex={1} direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                            <ActionButton
                              type={readOnly ? "preview" : "edit"}
                              onClick={() => handleOpenEditor({ page, variant, changeList })}
                              color="primary"
                              disabled={!extension.isInstalled}
                              isReverse={extension.isInstalled}
                            />
                          </Stack>
                        )}
                      </Stack>
                    );
                  }
                  case "redirect": {
                    return (
                      <Stack key={j} spacing={1}>
                        <Stack flex={4} direction="row" alignItems="center">
                          <Typography variant="body2">
                            {variant.name}
                          </Typography>
                          {!isOriginal && (
                            <Stack flex={1} direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                              <ActionButton
                                type={readOnly ? "preview" : "edit"}
                                onClick={() => handleOpenRedirectDrawer(page, variant, pageVariantInfo)}
                                isReverse
                              />
                            </Stack>
                          )}
                        </Stack>
                        {!isOriginal && (
                          <Stack flex={1} direction="row" spacing={4} alignItems="center">
                            {!!pageVariantInfo?.type ? (
                              <>
                                <Chip label={ja[pageVariantInfo.type]} />
                                <Chip label={getRedirectedUrl(page.editorUrl, pageVariantInfo)} variant="outlined" />
                              </>
                            ) : (
                              <Chip label="リダイレクトなし" color="error" variant="outlined" />
                            )}
                          </Stack>
                        )}
                      </Stack>
                    );
                  }
                  default: {
                    return null;
                  }
                }
              }}
            />
          ))}
        </Stack>
      )}
    />
  );
}
