import React  from "react";
import { Chip } from "@mui/material";

import CustomIcon from "./CustomIcon";
import ja from "../utils/ja.json";
import colors from "../utils/colors.json";

export default (props) => {
  const { type = "", label = ja[type], defaultColor, color = colors[type] || defaultColor, DefaultIcon, ...restProps } = props;
  return (
    <Chip
      {...{ label, color }}
      icon={<CustomIcon {...{ type, DefaultIcon }} />}
      variant="outlined"
      sx={{ width: "fit-content" }}
      {...restProps}
    />
  );
};
