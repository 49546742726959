import { useEffect, useMemo, useState } from "react";
import { createContainer } from "unstated-next";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, getAdditionalUserInfo } from "firebase/auth";
import { useAuthState, useSignInWithGoogle, useSignOut } from "react-firebase-hooks/auth";
import { useLocation } from "react-router";
import { Link, Stack, Typography } from "@mui/material";
import GoogleButton from "react-google-button";

import AlertDialog from "./AlertDialog";

import config from "../env.json";

export default createContainer(() => {
  const location = useLocation();
  const alertDialog = AlertDialog.useContainer();

  const app = initializeApp(config.firebase);
  const auth = getAuth(app);

  const [user, loading, error] = useAuthState(auth);
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [signOutAuth] = useSignOut(auth);
  const [oauthToken, setOauthToken] = useState(localStorage.getItem("token"));

  // useEffect(() => {
  //   localStorage.removeItem("token");
  // }, []);

  // useEffect(() => {
  //   signInByStorage();
  // }, []);

  // function signInByStorage() {
  //   const token = JSON.parse(localStorage.getItem("token"));
  //   if (!token) { return; }
  //   const credential = GoogleAuthProvider.credential(null, token);
  //   console.log(credential);
  //   signInWithCredential(auth, credential)
  //     .then((result) => {
  //       console.log("Sign In Success - credential", result);
  //     })
  //     .catch((error) => {
  //       console.log("Sign In Error - credential", error);
  //     });
  // }

  const currentUser = useMemo(() => {
    if (loading) {
      return JSON.parse(localStorage.getItem("user"));
    }
    if (error || !user) {
      resetOauthToken();
      return undefined;
    }
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
    };
    localStorage.setItem("user", JSON.stringify(userData));
    return userData;
  }, [user, loading, error]);

  const isSuperUser = useMemo(() => config.superUserIdArr.includes(currentUser?.uid), [currentUser]);

  useEffect(() => {
    if (loading) { return; }
    if (user) {
      if (alertDialog.getIsOpen("signIn")) { alertDialog.close("signIn"); }
    } else if (location.pathname.match(/^\/(containers|templates)/) && !alertDialog.getIsOpen("signIn")) {
      openSignInAlert();
    }
  }, [user, loading, error, location.pathname]);

  function openSignInAlert() {
    alertDialog.open({
      title: "ログインが必要です",
      disableClose: true,
      actions: null,
      Content: (
        <Stack>
          <GoogleButton onClick={signIn} style={{ margin: "20px auto" }} />
          <Typography variant="caption">
            Google APIから受け取るデータの取り扱いに関しては、<Link href="https://developers.google.com/terms/api-services-user-data-policy?hl=ja" target="_blank">Google API サービスのユーザーデータに関するポリシー</Link>に準拠します（使用制限の要件を含む）。
          </Typography>
        </Stack>
      ),
    }, "signIn");
  }
  
  function signIn() {
    signInWithGoogle()
      .then((ret) => {
        const { isNewUser } = getAdditionalUserInfo(ret) || {};
        if (isNewUser) { sendEvent("sign_up"); }
      });
  }

  function signOut() {
    signOutAuth();
    localStorage.removeItem("user");
  }

  async function getOauthToken() {
    const provider = new GoogleAuthProvider();
    provider.addScope(["https://www.googleapis.com/auth/analytics", "https://www.googleapis.com/auth/analytics.edit"]);
    try {
      const ret = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(ret);
      const token = credential.accessToken;
      setOauthToken(token);
      if (token) {
        localStorage.setItem("token", token);
        alertDialog.close("token");
      }
      return token;
    } catch (e) {
      return undefined;
    }
  }

  function resetOauthToken() {
    setOauthToken(undefined);
    localStorage.removeItem("token");
  }

  function sendEvent(eventName, eventLabel, isDirect) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: (isDirect ? "" : "event_") + eventName,
      event_label: eventLabel,
      user_id: currentUser?.uid || null,
    });
  }

  return { currentUser, isSuperUser, openSignInAlert, signOut, oauthToken, getOauthToken, resetOauthToken, sendEvent };
});
