import React from "react";
import { Container, Stack, Typography } from "@mui/material";

import TermItem from "../elements/TermItem";

import compliance from "../utils/compliance.json";

export default () => {
  return (
    <Container maxWidth="md">
      <Stack spacing={5} py={6}>
        <Typography variant="h2" component="h1">特定商取引法に基づく表記</Typography>
        {compliance.map((item, i) => (
          <TermItem key={i} {...item} />
        ))}
      </Stack>
    </Container>
  );
}
