import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Container, Divider, Grid, Typography } from "@mui/material";

import Header from "../containers/Header";
import AlertDialog from "../containers/AlertDialog";
import Drawer from "../containers/Drawer";
import Firestore from "../containers/Firestore";

import TemplateCard from "../components/TemplateCard";
import TemplateDrawerContent from "../components/TemplateDrawerContent";

export default () => {
  const [searchParams] = useSearchParams();
  const header = Header.useContainer();
  const alertDialog = AlertDialog.useContainer();
  const drawer = Drawer.useContainer();
  const firestore = Firestore.useContainer();
  const { dataList: templateList = [] } = firestore.useTemplateCol();
  const { dataList: myTemplateList = [] } = firestore.useMyTemplateCol();

  useEffect(() => {
    header.setTitle("テンプレート一覧");
    return () => header.setTitle(null);
  }, []);

  function handleEditClick(template) {
    const { id, name, description, indicatorList = [], hypothesisList = [], accessMode, creator } = template;
    const defaultValue = { id, name, description, indicatorList, hypothesisList, accessMode, creator };
    drawer.open({
      title: "テンプレートを編集",
      btnLabel: "更新",
      ContentComponent: TemplateDrawerContent,
      defaultValue,
      onSuccess: (data) => {
        firestore.updateTemplate(id, data).then(() => setTimeout(drawer.close, 300)); 
      },
    });
  }

  function handleDeleteClick(template) {
    alertDialog.open({
      title: `テンプレート「${template.name}」を削除しますか？`,
      cancelable: true,
      actions: [{
        label: "削除",
        color: "error",
        onClick: () => firestore.deleteTemplate(template.id),
      }],
    });
  }

  function renderList(list, isOwner) {
    return (
      <Grid container spacing={2}>
        {list.map((template) => (
          <Grid key={template.id} item xs={12} md={6} lg={4}>
            <Link to={`./${template.id}?${searchParams.toString()}`}>
              <TemplateCard
                item={template}
                onEditClick={() => handleEditClick(template)}
                onDeleteClick={() => handleDeleteClick(template)}
                {...{ isOwner }}
                sx={{ "&:hover": { bgcolor: "background.focused" } }}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    );
  }  

  return (
    <Container sx={{ py: 4 }}>
      {!!myTemplateList.length && <>
        <Typography variant="h4" mb={2}>マイテンプレート</Typography>
        {renderList(myTemplateList, true)}
        <Divider sx={{ my: 4 }} />
      </>}
      {!!templateList.length && <>
        <Typography variant="h4" mb={2}>コミュニティテンプレート</Typography>
        {renderList(templateList)}
      </>}
    </Container>
  );
}
