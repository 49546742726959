import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import { getChangeTypeObj } from "../utils/changeTypes";

export default (props) => {
  const { changeList = [] } = props;

  return (
    <List disablePadding>
      {changeList.map((item, i) => {
        const { type, selector } = item;
        const changeTypeObj = getChangeTypeObj(type);
        return (
          <ListItem key={i} divider sx={{ bgcolor: "background.paper" }}>
            <ListItemIcon>
              <changeTypeObj.Icon />
            </ListItemIcon>
            <ListItemText
              primary={`${i + 1}. ${changeTypeObj.label}`}
              secondary={selector}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
