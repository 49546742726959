import React, { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { TextFieldElement, useFormContext, useFormState } from "react-hook-form-mui";
import { Badge, Card, Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ForwardIcon from "@mui/icons-material/Forward";
import ModeCommentIcon from "@mui/icons-material/ModeComment";

import Drawer from "../containers/Drawer";

import HypothesisDrawerContent from "./HypothesisDrawerContent";
import HypothesisListDrawerContent from "./HypothesisListDrawerContent";
import { getRandomItem } from "../utils/random";
import placeholders from "../utils/placeholders.json";

export default function IndicatorCard (props) {
  const {
    readOnly, isTemplate, experienceList = [], hypothesisList = [], container,
    index, item = {}, list = [], isIndicatorListLocal,
    isInitial = false, isExperiencesHidden,
    isAncestorFocused = false, isSiblingFocused = false,
    prevSibling, nextSibling,
    parentRef, setParentLastChildRef,
    focusedIndicatorId = null, setFocusedIndicatorId,
    sortOrderDiff, handleSort,
    warpParentIndicatorId, handleWarp,
    ghostParentIndicatorId = null, setGhostParentIndicatorId,
    onDeleteClick,
    handleCreateHypothesis, handleUpdateHypothesis, handleDeleteHypothesis, handleCreateExperience,
  } = props;
  const { id, parentIndicatorId, name = "", valueAsIs = "", valueToBe = "", description = "", childList = [] } = item;
  const drawer = Drawer.useContainer();
  const { reset, unregister, setValue, handleSubmit } = useFormContext();
  const { isValid, errors } = useFormState();
  const ref = useRef(null);
  const [lastChildRef, setLastChildRef] = useState(null);
  const [isDrawerTarget, setIsDrawerTarget] = useState(false);
  const [drawerUpdateCount, setDrawerUpdateCount] = useState(0);

  const isGhost = useMemo(() => (id === "ghost"), [id]);
  const isRoot = useMemo(() => (parentIndicatorId === "root"), [parentIndicatorId, list]);
  const hasChild = useMemo(() => !!childList.length, [childList]);
  const isFirstChild = useMemo(() => (index === 0), [index]);
  const isLastChild = useMemo(() => (!nextSibling && !isGhost), [nextSibling, isGhost]);
  const isFocused = useMemo(() => (id === focusedIndicatorId), [id, focusedIndicatorId]);
  const relatedHypothesisList = useMemo(() => (
    hypothesisList.filter(({ indicatorIdArr = [] }) => indicatorIdArr.includes(id))
  ), [hypothesisList]);

  useEffect(() => {
    if (isDrawerTarget) {
      drawer.updateContentComponentProps({ experienceList, hypothesisList: relatedHypothesisList });
    }
  }, [drawerUpdateCount]);

  useEffect(() => {
    if ((isGhost || isInitial) && !isTemplate) {
      setTimeout(() => reset({ orderNum: prevSibling ? prevSibling.orderNum + 1 : 0 }), 50);
    }
  }, []);

  useEffect(() => {
    if (isLastChild) { setParentLastChildRef?.(ref); }
  }, [isLastChild]);

  useEffect(() => {
    if (!isFocused || isInitial || isTemplate) { return; }
    if (sortOrderDiff) {
      setTimeout(() => {
        let orderNum = item.orderNum || 0;
        if (prevSibling && nextSibling) {
          orderNum = (prevSibling.orderNum + nextSibling.orderNum) / 2;
        } else if (prevSibling) {
          orderNum = prevSibling.orderNum + 1;
        } else if (nextSibling) {
          orderNum = nextSibling.orderNum - 1;
        }
        setValue("orderNum", orderNum);
      }, 100);
    } else {
      unregister("orderNum");
    }
  }, [isFocused, sortOrderDiff]);

  useEffect(() => {
    if (!isFocused || isInitial) { return; }
    if (warpParentIndicatorId) {
      setValue("parentIndicatorId", warpParentIndicatorId);
      if (!isTemplate) { setValue("orderNum", prevSibling ? prevSibling.orderNum + 1 : 0); }
    } else {
      unregister("parentIndicatorId");
    }
  }, [isFocused, warpParentIndicatorId]);

  useEffect(() => {
    if (!isFocused && !isGhost) { return; }
    function handleKeyDown (e) {
      const focusedInputName = document.activeElement?.name;
      switch (e.key) {
        case "Escape":
          cancelInputMode();
          break;
        case "ArrowUp":
          if (isFocused && (focusedInputName !== "description")) { handleSortUp(); e.preventDefault(); }
          break;
        case "ArrowDown":
          if (isFocused && (focusedInputName !== "description")) { handleSortDown(); e.preventDefault(); }
          break;
        case "Backspace":
          if (isFocused && (e.metaKey || e.ctrlKey)) { onDeleteClick(item); e.preventDefault(); }
          break;
        case "Enter":
          if (e.metaKey || e.ctrlKey) { handleSubmit(props.handleSubmit)(); e.preventDefault(); }
          break;
        default:
          break;
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFocused, prevSibling, nextSibling]);

  function startEditting() {
    setFocusedIndicatorId(id);
    if (isTemplate) {
      reset({ name, description });
    } else {
      reset({ name, description, valueAsIs, valueToBe });
    }
  }

  function cancelInputMode() {
    if (isGhost) {
      setGhostParentIndicatorId(null);
    } else if (isFocused) {
      setFocusedIndicatorId(null);
    }
  }

  const handleSortUp = useCallback(() => {
    if (!prevSibling) { return; }
    handleSort(-1);
  }, [prevSibling]);

  const handleSortDown = useCallback(() => {
    if (!nextSibling) { return; }
    handleSort(1);
  }, [nextSibling]);

  function handleHypothesisClick() {
    setIsDrawerTarget(true);
    function onClose() { setIsDrawerTarget(false); }

    if (relatedHypothesisList.length) {
      drawer.open({
        title: "関連する仮説",
        onClose,
        ContentComponent: HypothesisListDrawerContent,
        contentComponentProps: {
          indicator: item,
          readOnly,
          isExperiencesHidden,
          isTemplate,
          container,
          experienceList,
          hypothesisList,
          relatedHypothesisList,
          indicatorList: list,
          handleCreate: handleCreateHypothesis,
          handleUpdate: handleUpdateHypothesis,
          handleDelete: handleDeleteHypothesis,
          handleCreateExperience,
          handleUpdateDrawer: () => setDrawerUpdateCount((prev) => prev + 1),
        },
      });
    } else if (!readOnly) {
      const defaultValue = { status: "unverified", indicatorIdArr: [id] };
      drawer.open({
        title: "関連する仮説を作成",
        btnLabel: "作成",
        onClose,
        defaultValue,
        ContentComponent: HypothesisDrawerContent,
        contentComponentProps: { indicatorList: list, isTemplate },
        onSuccess: (data) => handleCreateHypothesis(data).then(() => {
          onClose();
          setTimeout(drawer.close, 300);
        }),
      });
    }
  }

  const RightAdornment = useMemo(() => {
    if (hasChild || (id === focusedIndicatorId) || isAncestorFocused) {
      return null;
    }
    return (
      <IconButton
        onClick={() => (focusedIndicatorId ? handleWarp : setGhostParentIndicatorId)(id)}
        sx={{
          position: "absolute",
          top: "1.64rem",
          right: "-2.4rem",
          transform: "translateY(-50%)",
          opacity: 0.6,
        }}
      >
        {!!focusedIndicatorId ? (
          <AdsClickIcon color="secondary" fontSize="small" />
        ) : (
          <MoreHorizIcon color="primary" fontSize="small" />
        )}
      </IconButton>
    );
  }, [hasChild, focusedIndicatorId, isAncestorFocused]);

  const BottomAdornment = useMemo(() => {
    if (!isLastChild || (id === focusedIndicatorId) || isAncestorFocused || isSiblingFocused) {
      return;
    }
    return (
      <IconButton
        onClick={() => (focusedIndicatorId ? handleWarp : setGhostParentIndicatorId)(parentIndicatorId)}
        sx={{
          position: "absolute",
          top: isRoot ? "calc(100% + 1.5rem)" : "2rem",
          left: isRoot ? 0 : "-4.05rem",
          opacity: 0.6,
        }}
      >
        {!!focusedIndicatorId ? (
          <AdsClickIcon color="secondary" fontSize="small" />
        ) : isRoot ? (
          <AddCircleOutlineIcon color="primary" fontSize="small" />
        ) : (
          <MoreVertIcon color="primary" fontSize="small" />
        )}
      </IconButton>
    );
  }, [isLastChild, focusedIndicatorId, isAncestorFocused, isSiblingFocused]);

  return (
    <Stack
      direction="row"
      spacing={12}
      alignItems="flex-start"
      width="max-content"
      pr={(isGhost && !isInitial) ? 12 : undefined}
    >
      <Card
        {...{ id, ref }}
        flex={1}
        variant="outlined"
        sx={{
          position: "relative",
          overflow: "visible",
          minWidth: isFocused ? "22rem" : "8.25rem",
          maxWidth: isGhost ? "22rem" : "min-content",
          minHeight: "3rem",
          zIndex: 2,
          backgroundColor: (isDrawerTarget || isFocused || isGhost) ? "background.focused" : undefined,
          borderColor: isDrawerTarget ? "border.focused" : (isFocused || isGhost) ? "border.focusedTree" : undefined,
          borderWidth: "2px",
          boxSizing: "border-box",
          "&:after": ((index > -1) && !isRoot) && {
            height: "2px",
            top: "1.5rem",
            right: "100%",
            width: isFirstChild ? "6.1rem" : isGhost ? `calc(3rem + 1px)` : "3rem",
          },
          "&:before": ((index > 0) && (isLastChild || isGhost)) && {
            width: "2px",
            bottom: "calc(100% - 1.5rem - 1px)",
            left: "-3rem",
            height: `calc(${ref.current?.offsetTop - parentRef?.current?.offsetTop + 1}px)`,
          },
          "&:after, &:before": {
            content: "''",
            position: "absolute",
            backgroundColor: isGhost ? "border.focusedTree" : "border.tree",
            overflow: "hidden",
          },
        }}
      >
        <Tooltip
          title={!isFocused ? description : undefined}
          componentsProps={{ tooltip: { sx: { whiteSpace: "pre-wrap" } } }}
          arrow
        >
          <Stack
            direction="row"
            sx={{ "&:not(:hover) .shown-on-hovered": { display: "none" } }}
          >
            {isFocused && (
              <Stack spacing={1.5} pl={1.5} my="auto">
                <IconButton onClick={handleSortUp} disabled={!prevSibling} color="primary" sx={{ p: 0, opacity: 0.8, flex: 1 }}>
                  <KeyboardArrowUpIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={handleSortDown} disabled={!nextSibling} color="primary" sx={{ p: 0, opacity: 0.8, flex: 1 }}>
                  <KeyboardArrowDownIcon fontSize="small" />
                </IconButton>
              </Stack>
            )}

            <Stack p={1.5} flex={1} overflow="hidden">
              {(isGhost || isFocused) ? (
                <Stack spacing={1.5}>
                  <Stack direction="row" spacing={1}>
                    <TextFieldElement
                      name="name"
                      label={isRoot ? "KGI名" : "KPI名"}
                      placeholder={getRandomItem(placeholders[isRoot ? "KGI" : "KPI"])}
                      required
                      size="small"
                      autoComplete="off"
                      sx={{ flex: 1 }}
                    />
                    <Stack justifyContent="center" alignItems="center" direction="row" spacing={0.5}>
                      <IconButton
                        type="submit"
                        disabled={!isValid || !!Object.keys(errors).length}
                        color="primary"
                        sx={{ p: 0 }}
                      >
                        <CheckCircleOutlineIcon />
                      </IconButton>
                      {isFocused && (
                        <IconButton
                          onClick={() => onDeleteClick(item)}
                          color="error"
                          sx={{ p: 0 }}
                        >
                          <RemoveCircleOutlineIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                  {!isTemplate && (
                    <Stack direction="row" spacing={1.5}>
                      <TextFieldElement
                        name="valueAsIs"
                        label="現状数値"
                        size="small"
                        autoComplete="off"
                        sx={{ flex: 1 }}
                      />
                      <TextFieldElement
                        name="valueToBe"
                        label="目標数値"
                        size="small"
                        autoComplete="off"
                        sx={{ flex: 1 }}
                      />
                    </Stack>
                  )}
                  <TextFieldElement
                    name="description"
                    label="概要"
                    multiline
                    size="small"
                    autoComplete="off"
                  />
                </Stack>
              ) : (
                  <Stack spacing={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        textWrap: (name.length > 20) ? "balance" : "nowrap",
                        whiteSpace: (name.length > 20) ? "unset" : "nowrap",
                        minWidth: (name.length > 20) ? "12rem" : "fit-content",
                      }}
                    >
                      {name}
                    </Typography>
                    {(!!valueAsIs || !!valueToBe) && (
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        {!!valueAsIs && (
                          <Chip label={valueAsIs} variant="outlined" size="small" sx={{ minWidth: "2.5rem" }} />
                        )}
                        {!!valueToBe && <>
                          <ForwardIcon fontSize="small" color="action" />
                          <Chip label={valueToBe} variant="outlined" color="primary" size="small" sx={{ minWidth: "2.5rem" }} />
                        </>}
                      </Stack>
                    )}
                  </Stack>
                )}
              </Stack>

            {!isInitial && (
              <Stack width="1.75rem">
                {(isGhost || isFocused) ? (
                  <IconButton onClick={cancelInputMode} sx={{ p: 0.5 }}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                ) : (!ghostParentIndicatorId && !focusedIndicatorId) && <>
                  {!(readOnly && !relatedHypothesisList.length) && (
                    <IconButton
                      onClick={handleHypothesisClick}
                      size="small"
                      color="logo"
                      sx={{ p: 0.3 }}
                      className={!relatedHypothesisList.length ? "shown-on-hovered" : undefined}
                    >
                      <Badge badgeContent={relatedHypothesisList.length} color="logo">
                        <PostAddIcon fontSize="small" />
                      </Badge>
                    </IconButton>
                  )}
                  {!readOnly && (
                    <IconButton
                      onClick={startEditting}
                      color="primary"
                      sx={{ px: 0.3, py: 0 }}
                      className="shown-on-hovered"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )}
                </>}
              </Stack>
            )}

            {(!!description && !isFocused) && (
              <ModeCommentIcon color="lightGray" fontSize="inherit" sx={{ position: "absolute", top: 0, left: 0, transform: "translate(-50%, -50%)" }} />
            )}
          </Stack>
        </Tooltip>

        {((!readOnly && !isGhost && !ghostParentIndicatorId) && <>
          {RightAdornment}
          {BottomAdornment}
        </>)}
      </Card>

      {!isGhost && (
        <Stack spacing={3} zIndex={1}>
          {childList.map((childItem, i) => (
            <IndicatorCard
              {...props}
              key={childItem.id + i}
              item={childItem}
              index={i}
              prevSibling={childList[i - 1]}
              nextSibling={childList[i + 1]}
              parentRef={ref}
              setParentLastChildRef={setLastChildRef}
              isAncestorFocused={isAncestorFocused || isFocused}
              isSiblingFocused={childList.some((x) => x.id === focusedIndicatorId)}
            />
          ))}

          {((id === ghostParentIndicatorId) && !isIndicatorListLocal) && (
            <IndicatorCard
              {...props}
              item={{ id: "ghost", parentIndicatorId: id }}
              index={childList.length}
              prevSibling={childList[childList.length - 1]}
              parentRef={lastChildRef || ref}
            />
          )}
        </Stack>
      )}
    </Stack>
  );
}
