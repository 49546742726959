import * as time from "./time";

export default ({ id, gtmInfo }) => {
  if (!id) { return undefined; }
  switch (gtmInfo?.status) {
    case "published":
      if (time.isFuture(gtmInfo?.startAt)) { return "scheduled"; }
      if (time.isPast(gtmInfo?.endAt)) { return "closed"; }
      return "running";
    case "paused":
      if (time.isPast(gtmInfo?.endAt)) { return "closed"; }
      return "paused";
    case "closed":
      return "closed";
    case "archived":
      return "archived";
    default:
      return "draft";
  }
}
