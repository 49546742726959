import React, { useEffect } from "react";
import { TextFieldElement, useFormContext, Controller } from "react-hook-form-mui";
import { MuiFileInput } from "mui-file-input";
import { Stack } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";

export default (props) => {
  const { defaultValue, assetUrl } = props;
  const { watch, reset, control } = useFormContext();

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  const file = watch("file");

  return (
    <Stack spacing={4} p={4}>
      <TextFieldElement
        name="name"
        label="画像名"
        required
        fullWidth
        autoFocus
        autoComplete="off"
      />
      <TextFieldElement
        name="description"
        label="概要"
        multiline
        minRows={2}
        fullWidth
        autoComplete="off"
      />
      {!assetUrl && (
        <Controller
          name="file"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <MuiFileInput
              {...field}
              label="画像ファイル"
              required
              InputProps={{
                startAdornment: <AttachFileIcon />,
                inputProps: { accept: "image/*" },
              }}
              clearIconButtonProps={{ children: <CloseIcon fontSize="small" /> }}
            />
          )}
        />
      )}
      {(!!file || !!assetUrl) && (
        <img
          src={file ? URL.createObjectURL(file) : assetUrl}
          alt="プレビュー"
        />
      )}
    </Stack>
  );
}
