import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import {Grid,  Stack, Card, CardHeader, Typography, Button, Badge } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import ExperienceCard from "./ExperienceCard";
import MenuButton from "../elements/MenuButton";
import AccordionCard from "../elements/AccordionCard";

export default (props) => {
  const { item = {}, container = {}, experienceList = [], readOnly, currentUser, onEditClick } = props;
  const { name, description, experienceIdArr = [], messageIdArr = [], userIdArr = [], userLatestMessageIdObj = {} } = item;

  const relatedExperienceList = useMemo(() => (
    experienceIdArr.map((id) => experienceList.find((x) => x.id === id)).filter(Boolean)
  ), [experienceList]);

  const unreadMessageCount = useMemo(() => {
    if (!userIdArr.includes(currentUser?.uid)) { return 0; }
    const userLatestMessageId = userLatestMessageIdObj[currentUser?.uid];
    return messageIdArr.length - (messageIdArr.findIndex((id) => id === userLatestMessageId) + 1);
  }, [messageIdArr, userLatestMessageIdObj, currentUser?.uid]);

  const menuItems = [
    { label: "編集", Icon: EditIcon, color: "primary", onClick: onEditClick },
  ];

  return (
    <Card variant="outlined">
      <CardHeader
        title={(
          <Typography variant="h5" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {name}
          </Typography>
        )}
        action={!readOnly && <MenuButton items={menuItems} />}
        sx={{ pb: 0 }}
      />
      <Stack spacing={2} p={2} pt={1} alignItems="center">
        <Grid container rowSpacing={1}>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              {!!description && (
                <Typography variant="body5">{description}</Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <AccordionCard
              summary={(
                <Typography variant="caption">
                  {`関連するエクスペリエンス (${relatedExperienceList.length})`}
                </Typography>
              )}
              onClick={(e) => e.stopPropagation()}
              sx={{ bgcolor: "background.card" }}
              disabled={!relatedExperienceList.length}
              defaultExpanded={!!relatedExperienceList.length}
            >
              <Stack spacing={1}>
                {!!relatedExperienceList.length && (
                  <Stack spacing={0.5}>
                    {relatedExperienceList.map((experience) => (
                      <ExperienceCard
                        key={experience.id}
                        item={experience}
                        {...{ container }}
                      />
                    ))}
                  </Stack>
                )}
              </Stack>
            </AccordionCard>
          </Grid>
        </Grid>
        <Badge badgeContent={unreadMessageCount} color="secondary">
          <Button
            to={`/containers/${container.id}/topics/${item.id}`}
            component={Link}
            variant="contained"
            color="logo"
          >
            メッセージを確認
          </Button>
        </Badge>
      </Stack>
    </Card>
  );
}
