import { createContainer } from "unstated-next";
import { doc, collection, where, orderBy } from "firebase/firestore";

import Firestore from "./Firestore";
import FirestoreContainer from "./FirestoreContainer";

export default createContainer(({ experienceId }) => {
  const { useDocData, useColData, addDocData, updateDocData } = Firestore.useContainer();
  const { experienceColRef } = FirestoreContainer.useContainer();

  const experienceDocRef = doc(experienceColRef, experienceId);
  const requestColRef = collection(experienceDocRef, "requests");

  const { data: docData = {} } = useDocData(experienceDocRef);

  function update(data, operation = "エクスペリエンスを更新") {
    return updateDocData(experienceDocRef, data, operation);
  }

  function useRequestCol() {
    return useColData(requestColRef, where("isActive", "==", true));
  }

  function useHistoryCol() {
    return useColData(requestColRef, where("status", "==", "successed"), orderBy("resolvedAt", "desc"));
  }

  function addRequest(data) {
    return addDocData(requestColRef, { ...data, isActive: true }, "リクエストを送信");
  }

  function updateRequest(requestId, data) {
    return updateDocData(doc(requestColRef, requestId), data);
  }

  return { docData, update, useRequestCol, useHistoryCol, addRequest, updateRequest };
});
