import React, { useState } from "react";
import { useSwiper } from "swiper/react";
import { Button, Stack } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import Drawer from "../containers/Drawer";
import AlertDialog from "../containers/AlertDialog";

import SwiperTabs from "../elements/SwiperTabs";
import HypothesisCard from "./HypothesisCard";
import HypothesisDrawerContent from "./HypothesisDrawerContent";
import ExperienceDrawerContent from "./ExperienceDrawerContent";

export default (props) => {
  const [FormSlideContent, setFormSlideContent] = useState(null);
  return (
    <SwiperTabs>
      <ListSlideContent {...{ setFormSlideContent }} {...props} />
      {FormSlideContent}
    </SwiperTabs>
  );
}

function ListSlideContent (props) {
  const { container, indicator, setFormSlideContent, readOnly, isTemplate, experienceList = [], hypothesisList = [], relatedHypothesisList = [], indicatorList = [], handleCreate, handleUpdate, handleDelete, handleCreateExperience, handleUpdateDrawer } = props;
  const swiper = useSwiper();
  const drawer = Drawer.useContainer();
  const alertDialog = AlertDialog.useContainer();

  function handleCloseForm() {
    swiper.slidePrev();
    drawer.revert();
    handleUpdateDrawer();
    setTimeout(() => setFormSlideContent(null));
  }

  const drawerProps = {
    LeftIcon: ChevronLeftIcon,
    onLeftIconClick: handleCloseForm,
  };

  function handleAddClick() {
    const defaultValue = { status: "unverified", indicatorIdArr: [indicator.id] };
    setFormSlideContent(<HypothesisDrawerContent {...{ defaultValue, indicatorList, isTemplate }} delayAutoFocus />);
    setTimeout(() => {
      swiper.slideNext();
      drawer.update({
        title: "関連する仮説を作成",
        btnLabel: "作成",
        defaultValue,
        onSuccess: (data) => handleCreate(data).then(handleCloseForm),
        ...drawerProps,
      });
    });
  }

  function handleEditClick(hypothesis) {
    const { id, name, status, description, indicatorIdArr = [] } = hypothesis;
    const defaultValue = { name, status, description, indicatorIdArr };
    setFormSlideContent(<HypothesisDrawerContent {...{ defaultValue, indicatorList, isTemplate }} disableAutoFocus />);
    setTimeout(() => {
      swiper.slideNext();
      drawer.update({
        title: "仮説を更新",
        btnLabel: "更新",
        defaultValue,
        onSuccess: (data) => handleUpdate(id, data).then(handleCloseForm),
        ...drawerProps,
      });
    });
  }

  async function handleDeleteClick(hypothesis) {
    const { id, name } = hypothesis;
    const hasRelatedExperience = experienceList.some(({ hypothesisIdArr = [] }) => hypothesisIdArr.includes(id));
    alertDialog.open({
      title: `仮説「${name}」を削除しますか？`,
      description: hasRelatedExperience ? "エクスペリエンスとの紐付けも失われます。" : "",
      cancelable: true,
      actions: [{
        label: "削除",
        color: "error",
        onClick: () => handleDelete(id).then(handleUpdateDrawer),
      }],
    });
  }

  async function handleCreateExperienceClick(hypothesis) {
    const defaultValue = { type: "modify", editorUrl: container.defaultUrl, hypothesisIdArr: [hypothesis.id] };
    setFormSlideContent(<ExperienceDrawerContent {...{ defaultValue, experienceList, hypothesisList }} />);
    setTimeout(() => {
      swiper.slideNext();
      drawer.update({
        title: "エクスペリエンスを作成",
        supportId: "experience-new",
        btnLabel: "作成",
        defaultValue,
        ...drawerProps,
        onSuccess: (data) => {
          handleCreateExperience(data)
            .then((experienceId) => {
              window.open(`/containers/${container.id}/experiences/${experienceId}`, "_blank");
              handleCloseForm();
            });
        },
      });
    });
  }

  return (
    <Stack spacing={4} p={4}>
      {!readOnly && (
        <Button onClick={handleAddClick} variant="contained" color="primary" sx={{ mx: "auto !important" }}>
          仮説を作成
        </Button>
      )}
      {relatedHypothesisList.map((hypothesis) => (
        <HypothesisCard
          key={hypothesis.id}
          item={hypothesis}
          onEditClick={() => handleEditClick(hypothesis)}
          onDeleteClick={() => handleDeleteClick(hypothesis)}
          onCreateExperienceClick={() => handleCreateExperienceClick(hypothesis)}
          isSlim
          isStatusShown
          {...props}
        />
      ))}
    </Stack>
  );
}
