import { createContainer } from "unstated-next";
import { doc, collection } from "firebase/firestore";

import Firestore from "./Firestore";
import FirestoreContainer from "./FirestoreContainer";

export default createContainer(({ topicId }) => {
  const { useDocData, useColData, addDocData, updateDocData } = Firestore.useContainer();
  const { topicColRef } = FirestoreContainer.useContainer();

  const topicDocRef = doc(topicColRef, topicId);
  const messageColRef = collection(topicDocRef, "messages");

  const { data: docData = {} } = useDocData(topicDocRef);

  const { dataList: messageList = [] } = useColData(messageColRef);

  function update(data, operation) {
    return updateDocData(topicDocRef, data, operation);
  }

  function addMessage(data) {
    return addDocData(messageColRef, data);
  }

  function updateMessage(messageId, data) {
    return updateDocData(doc(messageColRef, messageId), data);
  }

  return { docData, update, messageList, addMessage, updateMessage };
});
