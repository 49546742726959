import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AlertDialog from "../containers/AlertDialog";
import Snackbar from "../containers/Snackbar";
import Drawer from "../containers/Drawer";
import Analytics from "../containers/Analytics";
import FirestoreContainer from "../containers/FirestoreContainer";

import ExperienceDrawerContent from "../components/ExperienceDrawerContent";
import RequestStatusAlert from "../components/RequestStatusAlert";
import VStack from "../elements/VStack";
import MenuButton from "../elements/MenuButton";
import ChipWithIcon from "../elements/ChipWithIcon";
import CustomIcon from "../elements/CustomIcon";

import * as time from "../utils/time";
import getCurrentStatus from "../utils/getCurrentStatus";
import ja from "../utils/ja.json";
import colors from "../utils/colors.json";
import WinningVariantChip from "../elements/WinningVariantChip";

const title = "エクスペリエンスを新規作成", supportId = "experience-new";

export default (props) => {
  const { experienceList = [], hypothesisList = [], indicatorList = [], requestList = [] } = props;
  const navigate = useNavigate();
  const alertDialog = AlertDialog.useContainer();
  const snackbar = Snackbar.useContainer();
  const drawer = Drawer.useContainer();
  const analytics = Analytics.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const { docData: container = {}, currentUserRole, checkIsUpgradeRequired } = firestoreContainer;
  const readOnly = useMemo(() => (currentUserRole === "viewer"), [currentUserRole]);
  const isAbovePublisher = useMemo(() => ["owner", "admin", "publisher"].includes(currentUserRole), [currentUserRole]);

  const experienceRequestList = useMemo(() => requestList.filter(({ action }) => action === "archiveExperience"), [requestList]);
  
  const { runningExperienceList = [], scheduledExperienceList = [], pausedExperienceList = [], draftExperienceList = [], closedExperienceList = [], archivedExperienceList = [] } = useMemo(() => {
    const obj = { runningExperienceList: [], scheduledExperienceList: [], pausedExperienceList: [], draftExperienceList: [], closedExperienceList: [], archivedExperienceList: [] };
    experienceList.forEach((experience) => {
      obj[`${getCurrentStatus(experience)}ExperienceList`]?.push(experience);
    });
    return obj;
  }, [experienceList]);

  function handleCreate() {
    const defaultValue = { mode: "abtest", type: "modify", editorUrl: container.defaultUrl };
    drawer.open({
      title,
      supportId,
      btnLabel: "作成",
      defaultValue,
      ContentComponent: ExperienceDrawerContent,
      contentComponentProps: { experienceList, hypothesisList, indicatorList },
      isWide: true,
      onSuccess: (data) => {
        if (checkIsUpgradeRequired("addExperience", data)) { return; }
        firestoreContainer.addExperienceFromTemplate(data)
          .then((experienceId) => {
            if (!experienceId) { return; }
            setTimeout(() => {
              drawer.close();
              navigate(`./experiences/${experienceId}`);
            }, 300);
          });
      }
    });
  }

  async function handleDuplicate(experience) {
    const { name, mode = "abtest", type, conditionList = [], variantList = [], pageList = [], pageVariantObj = {}, triggerIdArr = [], hypothesisIdArr = [], description } = experience;
    alertDialog.open({
      title: `「${name}」のコピーを作成しますか？`,
      description: "スケジュールや実施状況は複製されず、下書き状態で作成されます。",
      cancelable: true,
      actions: [{
        label: "コピーを作成",
        onClick: () => {
          if (checkIsUpgradeRequired("duplicateExperience")) { return; }
          const defaultName = `${name}のコピー`;
          let adjustedName = defaultName, sameCount = 0;
          // eslint-disable-next-line no-loop-func
          while (experienceList.some((x) => (x.name === adjustedName))) {
            sameCount++;
            adjustedName = `${defaultName}(${sameCount})`;
          }
          firestoreContainer.addExperienceFromTemplate({
            name: adjustedName,
            mode,
            type,
            conditionList,
            variantList,
            pageList,
            pageVariantObj,
            triggerIdArr,
            hypothesisIdArr,
            description,
            timestampKeyArr: ["productionUpdatedAt", "previewUpdatedAt"],
          });
        },
      }],
    });
  }

  async function handleArchive(experience) {
    alertDialog.open({
      title: `「${experience.name}」をアーカイブしますか？`,
      description: "エクスペリエンスの内容は引き続き確認できますが、プレビュー用URLは無効になり、レポートの目標イベントは更新できなくなります。\n元に戻すことはできません。",
      cancelable: true,
      actions: [{
        label: "アーカイブ",
        color: "error",
        onClick: () => {
          if (!experience.ga4Info || !!experience.ga4Info.reportList) {
            handleExecute();
          } else {
            alertDialog.open({
              title: `「${experience.name}」はレポートが保存されていません。`,
              description: "アーカイブする前にレポートを保存してください。アーカイブ後はレポートを作成できなくなります。",
              actions: [{
                label: "レポートを作成",
                onClick: () => navigate(`./experiences/${experience.id}#report`),
              }, {
                label: "このままアーカイブ",
                color: "error",
                onClick: handleExecute,
              }],
            });
          }
        },
      }],
    });

    async function handleExecute() {
      const { audienceList = [] } = experience.ga4Info || {};
      if (audienceList?.some((x) => !x.isArchived)) {
        const operation = "GA4オーディエンスをアーカイブ";
        const loading = alertDialog.open({ operation, isLoading: true });
        const archivedAudienceList = await analytics.archiveAudienceList(container, audienceList);
        loading.close();

        if (archivedAudienceList?.some((x) => !!x.isArchived)) {
          firestoreContainer.updateExperience(experience.id, { "ga4Info.audienceList": archivedAudienceList });
        }

        if (archivedAudienceList?.some((x) => !x.isArchived)) {
          snackbar.open({ operation, isError: true });
          return;
        }
        snackbar.open({ operation });
      }

      firestoreContainer.addRequest({
        action: "archiveExperience",
        experience,
        status: "processing",
        isActive: true,
      });
    }
  }

  async function handleDelete(experience) {
    alertDialog.open({
      title: `「${experience.name}」を削除しますか？`,
      description: "このエクスペリエンスに関するすべてのデータが完全に削除されます。\n復元することはできません。",
      cancelable: true,
      actions: [{
        label: "削除",
        color: "error",
        onClick: () => {
          firestoreContainer.deleteExperience(experience.id);
        },
      }],
    });
  }

  async function handleConfirmRequest(requestId) {
    firestoreContainer.updateRequest(requestId, { isActive: false });
  }

  function renderTable(statusType, list = []) {
    let items;
    switch (statusType) {
      case "draft":
        items = [
          { head: "作成日", body: (x) => time.toDateString(x.createdAt) },
          { head: "最終更新日", body: (x) => time.toDateString(x.updatedAt) || "-" },
        ];
        break;
      case "running":
        items = [
          { head: "開始日", body: (x) => time.toDateString(x.gtmInfo?.startAt) },
          { head: "終了予定日", body: (x) => time.toDateString(x.gtmInfo?.endAt) || "未設定" },
        ];
        if (list.some((x) => !!x.winningVariantId)) {
          items.unshift({ head: "勝利したパターン", body: (x) => (x.mode !== "rollout") && <WinningVariantChip item={x} size="small" /> });
        }
        break;
      case "paused":
        items = [
          { head: "開始日", body: (x) => time.toDateString(x.gtmInfo?.startAt) },
          { head: "一時停止日", body: (x) => time.toDateString(x.gtmInfo?.pausedAt) },
        ];
        if (list.some((x) => !!x.winningVariantId)) {
          items.unshift({ head: "勝利したパターン", body: (x) => (x.mode !== "rollout") && <WinningVariantChip item={x} size="small" /> });
        }
        break;
      case "scheduled":
        items = [
          { head: "開始予定日", body: (x) => time.toDateString(x.gtmInfo?.startAt) },
          { head: "終了予定日", body: (x) => time.toDateString(x.gtmInfo?.endAt) || "未設定" },
        ];
        break;  
      default:
        items = [
          { head: "勝利したパターン", body: (x) => ((x.mode !== "rollout") && time.isPast(x.gtmInfo?.startAt)) && <WinningVariantChip item={x} size="small" /> },
          { head: "開始日", body: (x) => time.toDateString(x.gtmInfo?.startAt) || "未開始" },
          { head: "終了日", body: (x) => time.toDateString(x.gtmInfo?.endAt) || "-" },
        ];
        break;
    }

    return (
      <Accordion
        sx={{ width: "100%", boxShadow: "none", backgroundColor: "transparent", "&:before": { display: "none" } }}
        disableGutters
        defaultExpanded={statusType !== "archived"}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color={colors[statusType]} />}
          sx={{ padding: 0, "& .MuiAccordionSummary-content": { margin: 0 }, "& .MuiAccordionSummary-expandIconWrapper": { position: "absolute", right: "16px" } }}
        >
          <Alert color={colors[statusType]} icon={<CustomIcon type={statusType} />} sx={{ width: "100%", fontWeight: "bold" }}>
            {`${ja[statusType]} (${list.length})`}
          </Alert>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px" }}>
          <TableContainer variant="outlined" component={Paper} sx={{ width: "100%" }}>
            <Table>
              <TableHead sx={{ "& th": { fontSize: "small", color: "gray", padding: "8px 16px" } }}>
                <TableRow>
                  <TableCell>エクスペリエンス名</TableCell>
                  <TableCell>モード</TableCell>
                  <TableCell>タイプ</TableCell>
                  {items.map(({ head }, i) => <TableCell key={i}>{head}</TableCell>)}
                  <TableCell>作成者</TableCell>
                  <TableCell sx={{ width: 0 }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((experience) => {
                  const createdByUser = container.userObj?.[experience.createdByUserId];
                  const menuItems = [
                    { label: "複製", Icon: ContentCopyIcon, color: "darkGray", onClick: () => handleDuplicate(experience) },
                  ];
                  if (isAbovePublisher) {
                    switch (statusType) {
                      case "closed": case "draft":
                        menuItems[1] = { label: "アーカイブ", Icon: ArchiveIcon, color: "error", onClick: () => handleArchive(experience) };
                        break;
                      case "archived":
                        menuItems[1] = { label: "削除", Icon: DeleteForeverIcon, color: "error", onClick: () => handleDelete(experience) };
                        break;
                      default:
                        break;
                    }
                  }
                  return (
                    <TableRow
                      key={experience.id}
                      onClick={() => navigate(`./experiences/${experience.id}`)}
                      sx={{ cursor: "pointer", "&:hover": { bgcolor: "background.focused" } }}
                    >
                      <TableCell sx={{ maxWidth: { xs: "30vw", lg: "420px" }, minWidth: { xs: "20vw !important", lg: "280px !important" } }}>
                        <Box
                          sx={{
                            fontWeight: "bold",
                            fontSize: "0.825rem", 
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                          }}
                        >
                          {experience.name}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <ChipWithIcon type={experience.mode || "abtest"} size="small" />
                      </TableCell>
                      <TableCell>
                        <ChipWithIcon type={experience.type} size="small" />
                      </TableCell>
                      {items.map(({ body }, i) => (
                        <TableCell key={i} sx={{ whiteSpace: "nowrap", fontSize: "0.775rem" }}>
                          {body(experience)}
                        </TableCell>
                      ))}
                      <TableCell>
                        <Tooltip title={createdByUser?.displayName} arrow>
                          <Avatar
                            src={createdByUser?.photoURL}
                            alt={createdByUser?.displayName}
                            sx={{ width: 24, height: 24 }}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {!readOnly && <MenuButton items={menuItems} />}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <VStack width="96%" maxWidth="lg" py={3}>
      {!readOnly && (
        <Stack spacing={2} width="100%">
          <Button onClick={handleCreate} variant="contained" color="primary" sx={{ mx: "auto !important" }}>
            {title}
          </Button>
          {!!experienceRequestList.length && experienceRequestList.map((request) => (
            <RequestStatusAlert
              key={request.id}
              request={request}
              handleConfirm={() => handleConfirmRequest(request.id)}
            />
          ))}
        </Stack>
      )}
      {!!runningExperienceList.length && renderTable("running", runningExperienceList)}
      {!!scheduledExperienceList.length && renderTable("scheduled", scheduledExperienceList)}
      {!!pausedExperienceList.length && renderTable("paused", pausedExperienceList)}
      {!!closedExperienceList.length && renderTable("closed", closedExperienceList)}
      {!!draftExperienceList.length && renderTable("draft", draftExperienceList)}
      {!!archivedExperienceList.length && renderTable("archived", archivedExperienceList)}
    </VStack>
  );
}
