import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import Header from "../containers/Header";
import AlertDialog from "../containers/AlertDialog";
import Drawer from "../containers/Drawer";
import Firebase from "../containers/Firebase";
import Firestore from "../containers/Firestore";
import FirestoreTemplate from "../containers/FirestoreTemplate";

import { HypothesisListComponent } from "./HypothesisList";
import { IndicatorListComponent } from "./IndicatorList";
import ImportDrawerContent from "../components/ImportDrawerContent";

import { getRandomId } from "../utils/random";

export default () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const header = Header.useContainer();
  const alertDialog = AlertDialog.useContainer();
  const drawer = Drawer.useContainer();
  const { currentUser } = Firebase.useContainer();
  const firestore = Firestore.useContainer();
  const firestoreTemplate = FirestoreTemplate.useContainer();
  const { dataList: containerList = [] } = firestore.useMyContainerCol();
  const { docData: template = {} } = firestoreTemplate;
  const { hypothesisList = [], indicatorList = [] } = template;
  const isOwner = useMemo(() => currentUser?.uid === template.createdByUserId, [currentUser?.uid, template.createdByUserId]);
  const [importClickedCount, setImportClickedCount] = useState(0);
  
  useEffect(() => {
    header.setTitleChip({ label: "テンプレート一覧", to: "/templates" });
    header.setTabs([
      { label: "仮説", value: "hypotheses", Icon: TextSnippetOutlinedIcon },
      { label: "KPIツリー", value: "", Icon: AccountTreeIcon },
    ]);
    return () => {
      header.setTitle(null);
      header.setTitleChip(null);
      header.setTabs(null);
      header.setAlert(null);
    }
  }, []);

  useEffect(() => {
    if (template.notFound) {
      header.setAlert({ children: "テンプレートが見つかりませんでした。" });
    }
  }, [template.notFound]);

  useEffect(() => {
    header.setTitle(template.name || "テンプレート");
  }, [template.name]);

  useEffect(() => {
    header.setAlert(template.indicatorList?.length ? {
      severity: "info",
      children: isOwner ? "テンプレートを編集できます。別のコンテナにもインポートできます。" : "ご自身のコンテナにインポートできます。",
      action: (
        <Button
          onClick={() => setImportClickedCount((prev) => prev + 1)}
          variant="contained"
        >
          インポート
        </Button>
      ),
      sx: { "& .MuiAlert-action": { p: 0, mr: 0 } },
    }: null);
  }, [template.indicatorList?.length, isOwner]);

  useEffect(() => {
    if (!importClickedCount) { return; }
    drawer.open({
      title: "テンプレートをインポート",
      btnLabel: "インポート",
      ContentComponent: ImportDrawerContent,
      contentComponentProps: { containerId: searchParams.get("container"), template, containerList },
      onSuccess: ({ containerId, ...data }) => {
        const container = containerList.find((x) => x.id === containerId);
        if (!container) {
          alertDialog.open({ title: "インポートするコンテナを選択してください" });
          return;
        }
        alertDialog.open({
          title: `コンテナ「${container.name}」にテンプレート「${template.name}」をインポートしますか？`,
          description: `コンテナ内のKPI${data.includesHypotheses ? "と仮説" : ""}を${(data.type === "overwrite") ? "すべて削除して上書きします" : "残したまま追加します"}。`,
          cancelable: true,
          actions: [{
            label: "インポート",
            color: (data.type === "overwrite") ? "error" : "primary",
            onClick: () => {
              firestore.addContainerRequest(containerId, { action: "importTemplate", templateId: template.id, ...data })
                .then(() => {
                  drawer.close();
                  setTimeout(() => navigate(`/containers/${containerId}#kpitree`), 300);
                });
            },
          }],
        });
      },
    });
  }, [importClickedCount]);

  if (template.notFound || !template.indicatorList?.length) {
    return null;
  }

  function handleCreateHypothesis(data) {
    return firestoreTemplate.update({ hypothesisList: [{ ...data, id: getRandomId() }].concat(hypothesisList) });
  }

  function handleUpdateHypothesis(id, data) {
    return firestoreTemplate.update({ hypothesisList: hypothesisList.map((x) => (x.id === id) ? { ...x, ...data } : x) });
  }

  function handleDeleteHypothesis(id) {
    return firestoreTemplate.update({ hypothesisList: hypothesisList.filter((x) => x.id !== id) });
  }

  function getIndicatorCount(indicatorId, list = indicatorList) {
    let count = 1;
    list.forEach((x) => {
      if (x.parentIndicatorId === indicatorId) {
        count += getIndicatorCount(x.id);
      }
    });
    return count;
  }

  function handleCreateIndicator(data) {
    const parentIndex = indicatorList.findIndex((x) => x.id === data.parentIndicatorId);
    const index = parentIndex + getIndicatorCount(data.parentIndicatorId);
    return firestoreTemplate.update({ indicatorList: indicatorList.toSpliced(index, 0, { ...data, id: getRandomId() }) });
  }

  function handleUpdateIndicator(id, { sortOrderDiff, ...data }) {
    const newIndicatorList = indicatorList.map((x) => (x.id === id) ? { ...x, ...data } : x);
    if (data.parentIndicatorId) {
      const index = newIndicatorList.findIndex((x) => x.id === id);
      const selfCount = getIndicatorCount(id, newIndicatorList);
      const selfIndicatorList = newIndicatorList.splice(index, selfCount);
      const parentIndex = newIndicatorList.findIndex((x) => x.id === data.parentIndicatorId);
      const parentCount = getIndicatorCount(data.parentIndicatorId);
      newIndicatorList.splice(parentIndex + parentCount, 0, ...selfIndicatorList);
    }
    if (sortOrderDiff) {
      const { parentIndicatorId } = newIndicatorList.find((x) => x.id === id);
      const siblingIndicatorList = newIndicatorList.filter((x) => x.parentIndicatorId === parentIndicatorId);
      const indexInSibling = siblingIndicatorList.findIndex((x) => x.id === id);
      let betweenOffspringCount = 0;
      for (let i = 1; i <= Math.abs(sortOrderDiff); i++) {
        const targetIndexInSibling = indexInSibling + i * Math.sign(sortOrderDiff);
        const targetIndicator = siblingIndicatorList[targetIndexInSibling];
        betweenOffspringCount += getIndicatorCount(targetIndicator.id, newIndicatorList);
      }
      const index = newIndicatorList.findIndex((x) => x.id === id);
      const selfCount = getIndicatorCount(id, newIndicatorList);
      const selfIndicatorList = newIndicatorList.splice(index, selfCount);
      newIndicatorList.splice(index + betweenOffspringCount * Math.sign(sortOrderDiff), 0, ...selfIndicatorList);
    }
   return firestoreTemplate.update({ indicatorList: newIndicatorList });
  }

  function handleDeleteIndicator(id) {
    const data = { indicatorList: [], hypothesisList: [] };
    const deletedIndicatorIdArr = [id];
    indicatorList.forEach((x) => {
      if (deletedIndicatorIdArr.includes(x.id)) {
        deletedIndicatorIdArr.push(x.id);
        return;
      }
      if (deletedIndicatorIdArr.includes(x.parentIndicatorId)) {
        deletedIndicatorIdArr.push(x.id);
        return;
      }
      data.indicatorList.push(x);
    });
    data.hypothesisList = hypothesisList.map(({ indicatorIdArr = [], ...data }) => ({
      ...data,
      indicatorIdArr: indicatorIdArr.filter((x) => !deletedIndicatorIdArr.includes(x)),
    }));
    return firestoreTemplate.update(data);
  }

  switch (header.fragment) {
    case "hypotheses": return (
      <HypothesisListComponent
        readOnly={!isOwner}
        handleCreate={handleCreateHypothesis}
        handleUpdate={handleUpdateHypothesis}
        handleDelete={handleDeleteHypothesis}
        isTemplate
        isExperiencesHidden
        {...template}
      />
    );
    default: return (
      <IndicatorListComponent
        readOnly={!isOwner}
        handleCreate={handleCreateIndicator}
        handleUpdate={handleUpdateIndicator}
        handleDelete={handleDeleteIndicator}
        {...{ handleCreateHypothesis, handleUpdateHypothesis, handleDeleteHypothesis }}
        isTemplate
        isExperiencesHidden
        {...template}
      />
    );
  }
}
