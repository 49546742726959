import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

import CustomSelect from "../elements/CustomSelect";

export default (props) => {
  const { reset } = useFormContext();
  const { defaultValue } = props;

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  return (
    <Stack spacing={4} p={4}>
      <CustomSelect
        name="metricName"
        label="指標"
        required
        withIcon
        colored
        options={[
          { id: "totalUsers", description: "ユーザー単位で分析します" },
          { id: "sessions", description: "セッション単位で分析します（しきい値の適用を回避できる場合があります）" },
        ]}
      />
    </Stack>
  );
}
