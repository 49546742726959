import { v4 } from "uuid";

export function getRandomId(num = 10) {
  return v4().replaceAll("-", "").slice(0, num);
}

export function getRandomNumber(n) {
  const min = 10 ** (n - 1);
  const max = (10 ** n) - 1;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomItem(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}
