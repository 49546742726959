import React from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import ja from "../utils/ja.json";

export default (props) => {
  const { value, minDateTime, maxDateTime, onChange, ...restProps } = props;

  return (
    <LocalizationProvider
      locale="ja"
      dateAdapter={AdapterDayjs}
      dateFormats={{
        monthAndYear: "YYYY年 M月",
        keyboardDateTime24h: "YYYY年M月D日  H:mm",
      }}
    >
      <DateTimePicker
        value={value ? dayjs(value) : null}
        minDateTime={minDateTime ? dayjs(minDateTime) : undefined}
        maxDateTime={maxDateTime ? dayjs(maxDateTime) : undefined}
        onChange={(v) => onChange(v ? v.toDate() : null)}
        format="YYYY年M月D日  H:mm"
        dayOfWeekFormatter={(day) => ja[day]}
        ampm={false}
        showDaysOutsideCurrentMonth
        disablePast
        sx={{ width: "60%" }}
        {...restProps}
      />
    </LocalizationProvider>
  );
}
