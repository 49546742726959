import React, { useMemo } from "react";
import { Alert, AlertTitle, Button, Stack } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import Drawer from "../containers/Drawer";
import AlertDialog from "../containers/AlertDialog";

import WinningVariantDrawerContent from "./WinningVariantDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";
import WinningVariantChip from "../elements/WinningVariantChip";
import ExperienceCard from "./ExperienceCard";

const title = "勝利したパターン";

export default (props) => {
  const { experience, experienceList, readOnly, handleUpdate, handleCreateRolloutExperience, handleUnlinkRolloutExperience } = props;
  const drawer = Drawer.useContainer();
  const alertDialog = AlertDialog.useContainer();
  const { variantList = [], winningVariantId = "", rolloutExperienceInfo } = experience;

  const winningVariant = useMemo(() => (
    (!!winningVariantId && !["draw", "unknown"].includes(winningVariantId)) ? variantList.find((x) => x.id === winningVariantId) : null
  ), [winningVariantId, variantList]);

  const rolloutExperience = useMemo(() => (
    !!rolloutExperienceInfo ? experienceList.find((x) => x.id === rolloutExperienceInfo.id) : null
  ), [experienceList, rolloutExperienceInfo]);

  function handleEdit() {
    drawer.open({
      title: `${title}を編集`,
      btnLabel: "更新",
      defaultValue: { winningVariantId },
      ContentComponent: WinningVariantDrawerContent,
      contentComponentProps: { variantList },
      onSuccess: (data) => handleUpdate(data, `${title}を更新`).then(drawer.close),
    });
  }

  function handleUnlink() {
    alertDialog.open({
      title: `100%反映モードのエクスペリエンスとの紐付けを解除しますか？`,
      description: `「${rolloutExperience.name}」との紐付けが解除されます。`,
      cancelable: true,
      actions: [{
        label: "紐付けを解除",
        color: "error",
        onClick: handleUnlinkRolloutExperience,
      }],
    });
  }

  return (
    <BuilderCard
      {...{ title }}
      color="success"
      Icon={EmojiEventsIcon}
      renderContent={() => (
        <Stack spacing={2}>
          <WinningVariantChip item={experience} />
          {(!!winningVariantId && !["original", "draw", "unknown"].includes(winningVariantId)) && (
            !!rolloutExperience ? <>
              <Alert
                severity="success"
                action={(
                  <Button
                    onClick={handleUnlink}
                    color="error"
                    sx={{ m: "auto" }}
                  >
                    紐付けを解除
                  </Button>
                )}
              >
                「{variantList.find((x) => x.id === rolloutExperienceInfo.variantId)?.name}」をもとに、100%反映モードのエクスペリエンスが作成されました。
              </Alert>
              <ExperienceCard
                item={rolloutExperience}
                {...props}
              />
            </> : (
              <Alert
                severity="info"
                action={(
                  <Button
                    onClick={handleCreateRolloutExperience}
                    color="inherit"
                    sx={{ m: "auto", fontWeight: "bold" }}
                  >
                    エクスペリエンスを作成
                  </Button>
                )}
              >
                <AlertTitle>勝利したパターンを100%反映しますか？</AlertTitle>
                「{winningVariant?.name}」をもとに、100%反映モードのエクスペリエンスを作成します。
              </Alert>
            )
          )}
        </Stack>
      )}
      action={(
        <ActionButton
          type="edit"
          onClick={handleEdit}
          isLocked={readOnly}
        />
      )}
    />
  );
}
