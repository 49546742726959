import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

import { ExperienceCardContent } from "./ExperienceCard";
import CustomSelect from "../elements/CustomSelect";

export default (props) => {
  const { name, defaultValue, experienceList = [] } = props;
  const { reset } = useFormContext();

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  return (
    <Stack p={4}>
      <CustomSelect
        name={name}
        multiple
        options={experienceList}
        renderCardContent={(item) => (
          <ExperienceCardContent
            {...{ item }}
            {...props}
            contentComponentProps={{ sx: { px: 1.5, py: 1 } }}
          />
        )}
      />
    </Stack>
  );
}
