import React, { useEffect } from "react";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

export default (props) => {
  const { defaultValue } = props;
  const { reset } = useFormContext();

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  return (
    <Stack p={4}>
      <TextFieldElement
        name="insight"
        label="考察"
        required
        multiline
        minRows={20}
        fullWidth
        autoFocus
        autoComplete="off"
      />
    </Stack>
  );
}
