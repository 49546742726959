import React, { useMemo } from "react";
import { Button, Stack } from "@mui/material";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AddIcon from "@mui/icons-material/Add";
import ScienceIcon from "@mui/icons-material/Science";

import Drawer from "../containers/Drawer";

import BuilderCard from "../elements/BuilderCard";
import ExperienceCard from "./ExperienceCard";
import ExperienceSelectDrawerContent from "./ExperienceSelectDrawerContent";
import ExperienceDrawerContent from "./ExperienceDrawerContent";

const title = "展開案のエクスペリエンス";

export default (props) => {
  const { container = {}, experience = {}, experienceList = [], handleUpdate, handleCreateExperience } = props;
  const { nextExperienceIdArr = [], hypothesisIdArr = [] } = experience;
  const drawer = Drawer.useContainer();

  const nextExperienceList = useMemo(() => (
    nextExperienceIdArr.map((id) => experienceList.find((x) => x.id === id))
  ), [nextExperienceIdArr, experienceList]);

  const otherExperienceList = useMemo(() => (
    experienceList.filter((x) => x.id !== experience.id)
  ), [experience.id, experienceList]);

  function handleEditClick() {
    drawer.open({
      title: `${title}を選択`,
      btnLabel: "更新",
      defaultValue: { nextExperienceIdArr },
      ContentComponent: ExperienceSelectDrawerContent,
      contentComponentProps: { name: "nextExperienceIdArr", experienceList: otherExperienceList },
      isWide: true,
      onSuccess: (data) => handleUpdate(data, `${title}を更新`).then(drawer.close),
    });
  }

  function handleCreateClick() {
    const defaultValue = { mode: experience.mode || "abtest", type: experience.type, editorUrl: container.defaultUrl, hypothesisIdArr };
    drawer.open({
      title: "エクスペリエンスを作成",
      btnLabel: "更新",
      defaultValue,
      ContentComponent: ExperienceDrawerContent,
      contentComponentProps: props,
      isWide: true,
      onSuccess: (data) => {
        handleCreateExperience(data).then(() => setTimeout(drawer.close, 300));
      },
    });
  }

  return (
    <BuilderCard
      {...{ title }}
      color="info"
      Icon={ScienceIcon}
      renderContent={() => (
        <Stack spacing={2}>
          <Stack direction="row" spacing={3} justifyContent="center">
            <Button
              variant="contained"
              onClick={handleEditClick}
              startIcon={<ChecklistIcon />}
            >
              エクスペリエンスを選択
            </Button>
            <Button
              variant="outlined"
              onClick={handleCreateClick}
              startIcon={<AddIcon />}
            >
              エクスペリエンスを作成
            </Button>
          </Stack>
          {nextExperienceList.map((item) => (
            <ExperienceCard
              key={item.id}
              {...{ item }}
              {...props}
            />
          ))}
        </Stack>
      )}
    />
  );
}
