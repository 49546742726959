import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { AutocompleteElement, useFormContext } from "react-hook-form-mui";
import GoogleButton from "react-google-button";

import Firebase from "../containers/Firebase";
import Analytics from "../containers/Analytics";

export default (props) => {
  const { defaultValue } = props;
  const firebase = Firebase.useContainer();
  const analytics = Analytics.useContainer();
  const { reset, setValue, trigger } = useFormContext();
  const [propertyList, setPropertyList] = useState(defaultValue?.propertyId ? [{ id: defaultValue.propertyId, name: defaultValue.propertyName }] : []);
  const [streamList, setStreamList] = useState(defaultValue?.streamId ? [{ id: defaultValue.streamId, name: defaultValue.streamName, measurementId: defaultValue.measurementId }] : []);

  useEffect(() => {
    if (!firebase.oauthToken) { return; }
    setupPropertyList();
    if (defaultValue) {
      reset(defaultValue);
      if (defaultValue.propertyId) {
        setupStreamList(defaultValue.propertyId);
      }
    }
    return () => reset({});
  }, [firebase.oauthToken]);

  if (!firebase.oauthToken) {
    return (
      <Stack spacing={2} p={4} alignItems="center">
        <Typography variant="h5" component="p">Googleアナリティクスへのアクセス権限が必要です。</Typography>
        <GoogleButton onClick={firebase.getOauthToken} />
      </Stack>
    );
  }

  async function setupPropertyList() {
    const list = await analytics.getPropertyList();
    if (list.length) { setPropertyList(list); }
    if (defaultValue && !list.some((x) => x.id === defaultValue?.propertyId)) {
      reset();
      setStreamList([]);
    }
  }

  async function setupStreamList(propertyId) {
    const list = await analytics.getStreamList(propertyId);
    if (list.length) { setStreamList(list); }
    return list;
  }

  async function handlePropertyChange(propertyData) {
    setValue("propertyName", propertyData.name);
    setValue("accountId", propertyData.accountId);

    const list = await setupStreamList(propertyData.id);
    if (list.length) {
      setTimeout(() => {
        const firstStream = list[0];
        setValue("streamId", firstStream.id);
        handleStreamChange(firstStream);
      });
    }
  }

  function handleStreamChange(streamData) {
    setValue("streamName", streamData.name);
    setValue("measurementId", streamData.measurementId);
    trigger();
  }

  return (
    <Stack spacing={6} p={4}>
      <AutocompleteElement
        name="propertyId"
        label="GA4プロパティ"
        options={propertyList}
        matchId
        required
        autocompleteProps={{
          onChange: (_, value) => handlePropertyChange(value),
          getOptionLabel: (option) => `${option.name}（${option.id}）`,
          groupBy: (option) => `${option.accountName}（${option.accountId}）`,
          noOptionsText: "該当するプロパティがありません",
          disableClearable: true,
          disablePortal: true,
          fullWidth: true,
          componentsProps: {
            popper: {
              sx: { transition: "none" },
            },
          },
        }}
      />

      {!!streamList.length && (
        <AutocompleteElement
          name="streamId"
          label="データストリーム"
          options={streamList}
          matchId
          required
          autocompleteProps={{
            onChange: (_, value) => handleStreamChange(value),
            getOptionLabel: (option) => `${option.name}（${option.id}）`,
            noOptionsText: "該当するデータストリームがありません",
            disableClearable: true,
            disablePortal: true,
            fullWidth: true,
            componentsProps: {
              popper: {
                sx: { transition: "none" },
              },
            },
          }}
        />
      )}
    </Stack>
  );
}
