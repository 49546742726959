import React from "react";
import { Grid, Button, Card, Stack, Typography, Divider, Tooltip, Chip } from "@mui/material";  
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import AlertDialog from "../containers/AlertDialog";

import CustomIcon from "../elements/CustomIcon";
import ExternalLink from "../elements/ExternalLink";

import * as time from "../utils/time";
import ja from "../utils/ja.json";

export default (props) => {
  const alertDialog = AlertDialog.useContainer();
  const { isOverview, isSpecial, handlePlanSelect, currentPlan, defaultPlan, stripe = {}, billableUserCount, readOnly, children } = props;
  const { subscriptionId, nextPlan, startAt, endAt } = stripe;

  function handleDowngrade(key) {
    alertDialog.open({
      title: "本当にダウングレードしますか？",
      description: `${ja[currentPlan]}をご活用いただけていませんか？\n${ja[key]}では、現在ご利用いただける機能が制限されます。`,
      cancelable: true,
      actions: [
        { label: "ダウングレード", color: "error", onClick: () => handlePlanSelect(key) },
      ],
    });
  }

  return (
    <>
      <Grid container spacing={1} sx={{ display: "flex", alignItems: "end" }}>
        {planList.map(({ key, price, maxBillableUserCount }, i) => {
          let isOverCapacity, planIndexDiff;
          let label, color, variant ,startIcon, onClick, disabled, disableRipple, cursor, tooltipTitle;
          let isFocused = (key === currentPlan) && (key !== "free");

          if (!isOverview && !isSpecial) {
            isOverCapacity = (billableUserCount > maxBillableUserCount);
            planIndexDiff = planList.findIndex(({ key }) => key === currentPlan) - i;
            label = (planIndexDiff >= 0) ? "アップグレード" : "ダウングレード";
            color = (planIndexDiff >= 0) ? "logo" : "error";
            variant = (planIndexDiff >= 0) ? "contained" : "outlined";
            startIcon = (planIndexDiff > 0) ? <AutoAwesomeIcon /> : null;
            onClick = (planIndexDiff >= 0) ? () => handlePlanSelect(key) : () => handleDowngrade(key);
            disabled = false;
            if (nextPlan) {
              const nextPlanIndexDiff = planList.findIndex(({ key }) => key === nextPlan) - i;
              if (key === nextPlan) {
                label = "移行予定のプラン";
                variant = "contained";
                disabled = true;
              } else if (key === currentPlan) {
                label = "現在のプランを更新";
                startIcon = <RefreshIcon />;
                color = "secondary";
              } else if (nextPlanIndexDiff > 0) {
                label = "プランを選択";
                startIcon = <DoneOutlineIcon />;
              }
            } else {
              if (key === currentPlan) {
                label = "現在のプラン";
                disabled = true;
              } else if (!subscriptionId) {
                label = "プランを選択";
                startIcon = <DoneOutlineIcon />;
                isFocused = !isOverCapacity;
              }
            }
          }
          if (isOverCapacity || readOnly) {
            onClick = null;
            disableRipple = true;
            cursor = "not-allowed";
            if (isOverCapacity) {
              tooltipTitle = `編集権限以上のメンバーが${billableUserCount}人いるため選択できません`;
            } else {
              tooltipTitle = "コンテナの管理権限が必要です";
            }
          }

          return (
            <Grid key={key} item xs={12} sm={6} lg={3}>
              {(!isOverview && !isSpecial) && <>
                {((key === currentPlan) && (key !== "free")) && (
                  <Stack alignItems="center" width="100%" mb={1}>
                    <Chip
                      label={`${time.toString(startAt)} 〜 ${endAt ? time.toString(endAt) : ""}`}
                      color="primary"
                      variant="outlined"
                    />
                  </Stack>
                )}
                {(key === nextPlan) && (
                  <Stack alignItems="center" width="100%" mb={1}>
                    <Chip
                      label={`${time.toString(endAt)} 〜`}
                      color="secondary"
                      variant="outlined"
                    />
                  </Stack>
                )}
              </>}
              <Card
                variant="outlined"
                sx={{
                  borderColor: (key === defaultPlan) ? "premium.main" : isFocused ? "border.focusedTree" : undefined,
                  opacity: isOverCapacity ? 0.6 : 1,
                  p: 2,
                  flexGrow: 1,
                }}
              >
                <Stack spacing={1}>
                  <Stack direction="row" spacing={0.3} alignItems="center" sx={{ textWrap: "nowrap" }}>
                    <CustomIcon type={key} color={key} />
                    <Typography variant="h3">
                      {ja[key]}
                    </Typography>
                  </Stack>
                  {!isSpecial && (
                    // <Stack height="68px">
                    <Stack>
                    {!!price ? <>
                        {/* <Stack direction="row" spacing={0.2} alignItems="baseline" sx={{ textWrap: "nowrap" }}>
                          <Typography variant="caption">¥</Typography>
                          <Typography variant="h3" color="error.main" fontSize="min(1.5rem, 6vw)">{(price / 2).toLocaleString("en")}</Typography>
                          <Typography variant="caption">/初月</Typography>
                          <Typography variant="caption">（税込 ¥{(price * 1.1 / 2).toLocaleString("en")}）</Typography>
                        </Stack> */}
                        <Stack
                          direction="row" spacing={0.2} alignItems="baseline"
                          sx={{
                            textWrap: "nowrap",
                            // position: "relative",
                            width: "fit-content",
                            // "&:before": { content: "''", position: "absolute", bottom: "40%", right: -4, left: -4, height: "2px", width: "100%", bgcolor: "error.main", opacity: 0.8 },
                          }}
                        >
                            <Typography variant="caption">¥</Typography>
                            <Typography variant="h3">{price.toLocaleString("en")}</Typography>
                            <Typography variant="caption">/月</Typography>
                            <Typography variant="caption">（税込 ¥{(price * 1.1).toLocaleString("en")}）</Typography>
                        </Stack>
                      </> : (
                        <Typography variant="h3" my="auto">無料</Typography>
                      )}
                    </Stack>
                  )}
                  {(!isOverview && !isSpecial) && (
                    <Tooltip title={tooltipTitle} placement="bottom">
                      <span>
                        <Button
                          {...{ color, variant, disabled, onClick, disableRipple, startIcon }}
                          sx={{ cursor, fontWeight: "bold", width: "100%" }}
                          size="large"
                        >
                          {label}
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </Stack>
                <Divider sx={{ my: 2 }} />
                <Stack spacing={1}>
                  {itemList.map(({ label, planObj, defaultCaption, isDivider }, i) => {
                    if (isDivider) { return <Divider key={i} />; }
                    const content = planObj[key];
                    let Icon = BlockIcon, color = "gray", text = label;
                    if (typeof content === "string") {
                      Icon = CheckCircleOutlineIcon;
                      color = "info";
                      text += `（${content}）`;
                    } else if (content) {
                      Icon = CheckCircleIcon;
                      color = "primary";
                      if (defaultCaption) { text += `（${defaultCaption}）`; }
                    }
                    return (
                      <Stack key={i} direction="row" spacing={1} alignItems="center">
                        <Icon color={color} fontSize="small" />
                        <Typography variant="h6" color={`${color}.main`}>
                          {text}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {children}
      {!isSpecial && (
        <Card variant="outlined">
          <Stack spacing={0.7} p={1.5}>
            {complianceList.map(({ title, body, captions }, i) => (
              <Stack key={i}>
                <Typography variant="body5"><strong>{title}</strong>：{body}</Typography>
                {!!captions && (
                  <Card variant="outlined" sx={{ mx: 1, my: 0.3 }}>
                    <Stack px={1} py={0.5}>
                      {captions.map((caption, j) => (
                        <Typography key={j} variant="caption">{caption}</Typography>
                      ))}
                    </Stack>
                  </Card>
                )}
              </Stack>
            ))}
            <Typography variant="body5">
              その他詳細は
              <ExternalLink href="/compliance" label="特定商取引法に基づく表記" isIconHidden sx={{ color: "gray.main", textDecorationColor: "#bdbdbd" }} />
              をご確認ください。
            </Typography>
          </Stack>
        </Card>
      )}
    </>
  );
};

const planList = [
  {
    key: "premium",
    price: 30000,
  },
  {
    key: "basic",
    price: 10000,
    maxBillableUserCount: 10,
  },
  {
    key: "starter",
    price: 3000,
    maxBillableUserCount: 3,
  },
  {
    key: "free",
    price: 0,
    maxBillableUserCount: 1,
  },
];

const itemList = [
  {
    label: "ABテストを作成・実施",
    planObj: {
      free: true,
      starter: true,
      basic: true,
      premium: true,
    },
  },
  {
    label: "数値レポートを確認",
    planObj: {
      free: true,
      starter: true,
      basic: true,
      premium: true,
    },
  },
  {
    label: "パターンの比重をカスタマイズ",
    planObj: {
      free: false,
      starter: true,
      basic: true,
      premium: true,
    },
  },
  {
    label: "ABテストを複製",
    planObj: {
      free: false,
      starter: true,
      basic: true,
      premium: true,
    },
  },
  {
    label: "勝利したパターンを100%反映",
    planObj: {
      free: false,
      starter: false,
      basic: true,
      premium: true,
    },
  },
  {
    label: "画像をアップロード",
    planObj: {
      free: false,
      starter: false,
      basic: "1件まで",
      premium: true,
    },
  },
  {
    isDivider: true,
  },
  {
    label: "KPIツリーを作成",
    planObj: {
      free: true,
      starter: true,
      basic: true,
      premium: true,
    },
  },
  {
    label: "仮説を作成",
    planObj: {
      free: "5件まで",
      starter: true,
      basic: true,
      premium: true,
    },
  },
  {
    label: "テストの概要・考察を記入",
    planObj: {
      free: "3件まで",
      starter: true,
      basic: true,
      premium: true,
    },
  },
  {
    isDivider: true,
  },
  {
    label: "複数のメンバーで編集",
    planObj: {
      free: false,
      starter: "3人まで",
      basic: "10人まで",
      premium: true,
    },
    defaultCaption: "無制限",
  },
  {
    label: "メンバーの権限をカスタマイズ",
    planObj: {
      free: false,
      starter: false,
      basic: false,
      premium: true,
    },
  },
  {
    isDivider: true,
  },
  {
    label: "個別のカスタマーサポート",
    planObj: {
      free: false,
      starter: false,
      basic: false,
      premium: true,
    },
  },
];

const complianceList = [
  {
    title: "支払い時期",
    body: "有料プランのお申込み時および更新時にカード決済を実施（引落しの時期についてはご利用のカード会社にお問い合わせください）",
  },
  {
    title: "引渡し時期",
    body: "即時（お申込み完了後、反映されるまで数分ほどかかる場合があります）",
  },
  {
    title: "契約期間",
    body: "お申込み日から1ヶ月間（解約されるまで自動で更新されます）",
  },
  {
    title: "解約方法",
    body: "フリープランにダウングレードすることでいつでも解約できます（解約手続き完了後も、残りの契約期間が終了するまでは引き続きご利用いただけます）",
  },
  {
    title: "返品・返金に関する規定",
    body: "商品の性質上、返品・返金は承っておりません（解約日に基づく日割計算による返金などにも対応しておりません）",
  },
  {
    title: "アップグレード時に必要な金額",
    body: "上位のプランにアップグレードする際は、残りの契約期間に基づいて比例配分による計算が行われます。",
    captions: [
      "例）契約期間の半ばでスタータープラン（¥3,000）からプレミアムプラン（¥30,000）にアップグレードする場合：",
      "プレミアムプランの比例配分価格（¥15,000）からスタータープランの未利用分価格（¥1,500）を差し引いた ¥13,500 が請求されます。",
      "※実際には秒単位で計算されるため、端数が発生します。"
    ],
  },
];
