import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

import { HypothesisCardContent } from "./HypothesisCard";
import CustomSelect from "../elements/CustomSelect";

export default (props) => {
  const { defaultValue, hypothesisList = [] } = props;
  const { reset } = useFormContext();

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  return (
    <Stack p={4}>
      <CustomSelect
        name="hypothesisIdArr"
        multiple
        options={hypothesisList}
        renderCardContent={(item) => (
          <HypothesisCardContent
            item={item}
            isExperienceLinkDisabled
            {...props}
            readOnly
          />
        )}
      />
    </Stack>
  );
}
