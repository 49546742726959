import React, { useMemo } from "react";
import { Alert, Button, CircularProgress, Stack, Typography } from "@mui/material";

import * as time from "../utils/time";
import ja from "../utils/ja.json";

export default (props) => {
  const { request, handleConfirm } = props;

  const alertProps = useMemo(() => {
    let modeLabel = "エクスペリエンス", statusLabel, actionLabel = ja[request.action], message, severity, variant = "standard", confirmLabel, sx;

    switch (request.action) {
      case "activatePreview":
        modeLabel = "プレビュー用URL";
        actionLabel = "有効化";
        variant = "outlined";
        break;
      case "closePreview":
        modeLabel = "プレビュー用URL";
        actionLabel = "無効化";
        variant = "outlined";
        break;
      case "updatePreview":
        modeLabel = "プレビュー内容";
        actionLabel = "更新";
        variant = "outlined";
        break;
      case "updateGa4Info":
        modeLabel = "リンク先のGoogleアナリティクス";
        actionLabel = "設定";
        variant = "outlined";
        break;
      case "addUser":
        modeLabel = "メンバー";
        actionLabel = "招待";
        variant = "outlined";
        message = request.userInfo?.email + "に招待メールを送信しました。";
        break;
      case "deleteContainer":
        modeLabel = "コンテナ";
        actionLabel = "削除";
        variant = "filled"
        break;
      case "archiveExperience":
        modeLabel = `「${request.experience?.name}」`;
        actionLabel = "アーカイブ";
        break;
      case "updateSubscription":
        modeLabel = "プラン";
        actionLabel = "更新";
        break;
      case "importTemplate":
        modeLabel = "テンプレート";
        actionLabel = "インポート";
        break;  
      default:
        break;
    }

    switch (request.status) {
      case "processing":
        severity = "info";
        statusLabel = "を処理中です。";
        message = (
          <Stack direction="row" alignItems="center">
            しばらくお待ちください...
            <CircularProgress size={18} color="inherit" sx={{ ml: 2 }} />
          </Stack>
        );
        if (time.isOld(request.createdAt)) { confirmLabel = "取消"; }
        break;
      case "successed":
        severity = "success";
        statusLabel = "が完了しました。";
        confirmLabel = "OK";
        if (request.isEmailNotFound) { message += "\n相手が登録を完了するとこのコンテナに追加されます。"; }
        break;
      case "failed":
        severity = "error";
        statusLabel = "に失敗しました。";
        confirmLabel = "確認しました";
        if (request.isContainerSizeError) {
          message = "コンテナサイズが上限に達しました。不要なエクスペリエンスにて、アーカイブまたはプレビューの無効化を行ってください。";
        } else {
          message = "少し時間を置いてから再試行してください。";
        }
        break;
      default: break;
    }

    const action = !!confirmLabel && (
      <Button
        onClick={handleConfirm}
        color="inherit"
        sx={{ m: "auto", fontWeight: "bold" }}
      >
        {confirmLabel}
      </Button>
    );

    return {
      severity,
      variant,
      title: modeLabel + "の" + actionLabel + statusLabel,
      message,
      action,
      sx,
    };
  }, [request]);

  if (!request.action) { return null; }
  
  return (
    <Alert {...alertProps} sx={{ whiteSpace: "pre-line", "& .MuiAlert-action": { p: 0, pl: 2 } }}>
      {alertProps.title}
      <Typography variant="caption" color="inherit" display="block">{alertProps.message}</Typography>
    </Alert>
  );
}
