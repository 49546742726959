import React from "react";
import { Link } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";

import AlertDialog from "../containers/AlertDialog";
import Drawer from "../containers/Drawer";
import FirestoreContainer from "../containers/FirestoreContainer";

import VStack from "../elements/VStack";
import HypothesisCard from "../components/HypothesisCard";
import HypothesisDrawerContent from "../components/HypothesisDrawerContent";
import ExperienceDrawerContent from "../components/ExperienceDrawerContent";

const title = "仮説を作成", supportId = "";

export default (props) => {
  const firestoreContainer = FirestoreContainer.useContainer();
  const { docData: container, currentUserRole, checkIsUpgradeRequired } = firestoreContainer;
  const { readOnly = (currentUserRole === "viewer"), ...restProps } = props;

  return (
    <HypothesisListComponent
      {...restProps}
      {...{ container, checkIsUpgradeRequired, readOnly }}
      handleCreate={firestoreContainer.addHypothesis}
      handleUpdate={firestoreContainer.updateHypothesis}
      handleDelete={firestoreContainer.deleteHypothesis}
      handleCreateExperience={firestoreContainer.addExperienceFromTemplate}
      isStatusShown
    />
  );
}

export const HypothesisListComponent = (props) => {
  const { container = {}, experienceList = [], hypothesisList = [], indicatorList = [], readOnly, isTemplate, handleCreate, handleUpdate, handleDelete, checkIsUpgradeRequired } = props;
  const alertDialog = AlertDialog.useContainer();
  const drawer = Drawer.useContainer();

  async function handleCreateOrEditClick(hypothesis) {
    const { id, name, status, indicatorIdArr, description } = hypothesis || {};
    const defaultValue = hypothesis ? { name, status, indicatorIdArr, description } : !isTemplate ? { status: "unverified" } : undefined;
    const btnLabel = hypothesis ? "更新" : "作成";
    drawer.open({
      title: `仮説を${btnLabel}`,
      supportId,
      btnLabel,
      defaultValue,
      ContentComponent: HypothesisDrawerContent,
      contentComponentProps: { indicatorList, isTemplate },
      onSuccess: hypothesis ? onUpdate : onCreate,
    });

    async function onCreate(data) {
      if (checkIsUpgradeRequired?.("addHypothesis", { hypothesisList })) { return; }
      handleCreate(data).then(() => setTimeout(drawer.close, 300));
    }

    async function onUpdate(data) {
      handleUpdate(id, data).then(() => setTimeout(drawer.close, 300));
    }
  }

  async function handleDeleteClick(hypothesis) {
    const { id, name } = hypothesis;
    const hasRelatedExperience = experienceList.some(({ hypothesisIdArr = [] }) => hypothesisIdArr.includes(id));
    alertDialog.open({
      title: `仮説「${name}」を削除しますか？`,
      description: hasRelatedExperience ? "エクスペリエンスとの紐付けも失われます。" : "",
      cancelable: true,
      actions: [{
        label: "削除",
        color: "error",
        onClick: () => handleDelete(id),
      }],
    });
  }

  async function handleCreateExperienceClick(hypothesis) {
    const defaultValue = { type: "modify", editorUrl: container.defaultUrl, hypothesisIdArr: [hypothesis.id] };
    drawer.open({
      title: "エクスペリエンスを作成",
      supportId: "experience-new",
      btnLabel: "作成",
      defaultValue,
      ContentComponent: ExperienceDrawerContent,
      contentComponentProps: { experienceList, hypothesisList, indicatorList },
      onSuccess: (data) => {
        props.handleCreateExperience(data)
          .then((experienceId) => {
            window.open(`/containers/${container.id}/experiences/${experienceId}`, "_blank");
            setTimeout(drawer.close, 300);
          });
      }
    });
  }

  if (!hypothesisList.length) {
    if (readOnly) {
      return (
        <VStack maxWidth="md" py={4}>
          <Typography variant="h5">設定されていません</Typography>
        </VStack>
      );
    }
    if (!indicatorList.length) {
      return (
        <VStack maxWidth="md" py={4}>
          <Typography variant="h5">まずはKPIツリーを設定しましょう。</Typography>
          <Button
            variant="contained"
            color="logo"
            disableElevation
            component={Link}
            to="#kpitree"
            sx={{ borderRadius: 10, fontWeight: "bold", width: "50%", minWidth: "fit-content" }}
          >
            設定画面へ
          </Button>
        </VStack>
      );
    }
  }

  return (
    <VStack width="96%" maxWidth="lg" py={3}>
      {!readOnly && (
        <Stack spacing={2} width="100%">
          <Button onClick={() => handleCreateOrEditClick()} variant="contained" color="primary" sx={{ mx: "auto !important" }}>
            {title}
          </Button>
        </Stack>
      )}
      <Stack spacing={2} width="100%">
        {hypothesisList.map((hypothesis) => (
          <HypothesisCard
            {...props}
            key={hypothesis.id}
            item={hypothesis}
            onEditClick={() => handleCreateOrEditClick(hypothesis)}
            onDeleteClick={() => handleDeleteClick(hypothesis)}
            onCreateExperienceClick={() => handleCreateExperienceClick(hypothesis)}
          />
        ))}
      </Stack>
    </VStack>
  );
}
