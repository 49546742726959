import React from "react";
import { Typography } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";

import Drawer from "../containers/Drawer";

import DescriptionDrawerContent from "./DescriptionDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";

const title = "概要";

export default (props) => {
  const { experience, readOnly, handleUpdate } = props;
  const drawer = Drawer.useContainer();
  const { description = "" } = experience;

  function handleEdit() {
    drawer.open({
      title: `${title}を編集`,
      btnLabel: "更新",
      defaultValue: { description },
      ContentComponent: DescriptionDrawerContent,
      isWide: true,
      onSuccess: (data) => handleUpdate(data, `${title}を更新`).then(drawer.close),
    });
  }

  return (
    <BuilderCard
      {...{ title }}
      color="warning"
      Icon={ArticleIcon}
      renderItem={() => (
        <Typography variant="body2" whiteSpace="pre-wrap">
          {description}
        </Typography>
      )}
      action={<ActionButton type="edit" onClick={handleEdit} isLocked={readOnly} />}
    />
  );
}
