import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default (props) => {
  const { summary, children, sx = {}, ...restProps } = props;

  return (
    <Accordion
      variant="outlined"
      disableGutters
      sx={{
        bgcolor: "background.paper",
        width: "100%",
        borderRadius: "4px",
        "&:before": { display: "none" },
        ...sx,
      }}
      {...restProps}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
