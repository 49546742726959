import React  from "react";
import { Stack } from "@mui/material";

export default ({ children, ...props }) => (
  <Stack
    spacing={4}
    alignItems="center"
    alignContent="center"
    maxWidth="sm"
    mx="auto"
    py={20}
    sx={{ px: { xs: 1, md: 0 } }}
    {...props}
  >
    {children}
  </Stack>
);
