import React, { useEffect, useState } from "react";
import { Stack, Chip, Card, Typography, CardHeader, Button, Avatar } from "@mui/material";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import CountTooltip from "../elements/CountTooltip";
import CustomIcon from "../elements/CustomIcon";
import MenuButton from "../elements/MenuButton";

export default (props) => {
  const { item = {}, defaultIndicatorNum = 5, isOwner, onEditClick, onDeleteClick, ...restProps } = props;
  const { name, description, indicatorList = [], hypothesisList = [], accessMode, creator = {}, importedCount = 0 } = item;
  const [isAllShown, setIsAllShown] = useState(false);

  useEffect(() => {
    setIsAllShown(indicatorList.length <= defaultIndicatorNum + 1);
  }, [indicatorList]);

  return (
    <Card variant="outlined" {...restProps}>
      <CardHeader
        title={(
          <Typography variant="body2" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {name}
          </Typography>
        )}
        avatar={<CustomIcon type={accessMode} color="action" fontSize="small" />}
        action={isOwner && (
          <MenuButton
            items={[
              { label: "編集", Icon: EditIcon, color: "primary", onClick: onEditClick },
              { label: "削除", Icon: DeleteForeverIcon, color: "error", onClick: onDeleteClick },
            ]}
          />
        )}
        sx={{ pb: 0 }}
      />
      <Stack spacing={1.5} px={2} py={1.5}>
        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
          {indicatorList.slice(0, isAllShown ? undefined : defaultIndicatorNum).map((indicator) => (
            <Chip
              key={indicator.id}
              label={indicator.name}
              color="primary"
              variant="outlined"
              size="small"
              sx={{ borderRadius: 2 }}
            />
          ))}
          {(indicatorList.length > defaultIndicatorNum + 1) && (
            <Button
              onClick={(e) => { setIsAllShown((prev) => !prev); e.preventDefault(); }}
              size="small"
              startIcon={<ExpandCircleDownOutlinedIcon />}
              sx={{
                px: 0.5,
                py: 0,
                minWidth: "1rem",
                "& .MuiButton-startIcon": {
                  mr: 0.5,
                  transform: isAllShown ? "rotate(180deg)" : undefined,
                },
              }}
            >
              {isAllShown ? "" : `他${indicatorList.length - defaultIndicatorNum}件`}
            </Button>
          )}
        </Stack>

        {!!description && (
          <Typography variant="body5">{description}</Typography>
        )}

        <Stack direction="row" spacing={2} alignItems="center">
          <Stack flex={1} direction="row" spacing={1.2} alignItems="center" pt={0.5}>
            <CountTooltip
              Icon={AccountTreeIcon}
              count={indicatorList.length}
              label="KPI"
            />
            <CountTooltip
              Icon={TextSnippetOutlinedIcon}
              count={hypothesisList.length}
              label="仮説"
            />
            <CountTooltip
              Icon={DownloadIcon}
              count={importedCount}
              label="インポート"
              connector="人が"
            />
          </Stack>
          {(accessMode === "public") && (
            <Stack direction="row" spacing={0.7} alignItems="center" overflow="hidden" sx={{ textWrap: "nowrap" }}>
              <Typography variant="caption">
                作成者：
              </Typography>
              <Avatar
                src={creator.photoURL}
                alt={creator.displayName}
                sx={{ width: 20, height: 20 }}
              />
              <Typography variant="caption" overflow="hidden" textOverflow="ellipsis">
                {creator.displayName}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
