import React from "react";
import { Card, CardActions, CardContent, CardHeader, Chip, Stack, Typography } from "@mui/material";
import AddButton from "./AddButton";
import SupportButton from "./SupportButton";

export default (props) => {
  const { title, items = [0], renderItem, renderContent, onAddClick, color, isItemConnectorShown } = props;
  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      {!!title && <BuilderCardHeader {...props} />}
      <CardContent sx={{ py: 0 }}>
        {!!renderContent && renderContent()}
        {!!renderItem && (
          <Stack spacing={1}>
            {items.map((item, index) => (
              <BuilderCardItem key={index} {...{ index, isItemConnectorShown }}>
                {renderItem(item, index)}
              </BuilderCardItem>
            ))}
          </Stack>
        )}
      </CardContent>
      <CardActions>
        {!!onAddClick && (
          <AddButton onClick={onAddClick} label={title} color={color} />
        )}
      </CardActions>
    </Card>
  );
}

export function BuilderCardHeader(props) {
  const { title, titleRight, supportId, Icon, color, action, sx = {} } = props;
  return (
    <CardHeader
      title={(
        <Stack direction="row" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Typography variant="h5">{title}</Typography>
            {titleRight}
          </Stack>
          {supportId ? <SupportButton {...{ supportId }} /> : null}
        </Stack>
      )}
      avatar={!!Icon ? <Icon color={color} /> : null}
      sx={{ mr: 1, ...sx }}
      {...{ action }}
    />
  );
}

export function BuilderCardItem(props) {
  const { index, isItemConnectorShown = false, children } = props;
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {isItemConnectorShown && <Chip label={(index === 0) ? "条件" : "かつ"} />}
      <Card variant="outlined" sx={{ width: "100%" }}>
        <CardContent sx={{ padding: "12px 20px !important" }}>
          {children}
        </CardContent>
      </Card>
    </Stack>
  );
}
