import React, { useEffect, useState } from "react";
import { useFormState, useFormContext } from "react-hook-form-mui";
import LoadingButton from "@mui/lab/LoadingButton";
import { isDiff } from "../utils/diff";

export default React.memo((props) => {
  const { label, defaultValue, isLoading = false, onIsDirtyChange, ...restProps } = props;
  const { isValid, errors } = useFormState();
  const { watch } = useFormContext();
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const subscription = watch((value) => {
      setIsDirty(isDiff(value, defaultValue));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    onIsDirtyChange?.(isDirty);
  }, [isDirty]);

  return (
    <LoadingButton
      type="submit"
      variant="contained"
      color="primary"
      disabled={!isValid || !isDirty || !!Object.keys(errors).length}
      loading={isLoading}
      {...restProps}
    >
      {label}
    </LoadingButton>
  )
});
