import React from "react";
import { Link } from "react-router-dom";
import { Stack, Card, Typography } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import ChipWithIcon from "../elements/ChipWithIcon";
import getCurrentStatus from "../utils/getCurrentStatus";
import WinningVariantChip from "../elements/WinningVariantChip";

export default (props) => {
  const { item = {}, container = {}, isLinkDisabled = !container.id, sx = { px: 1.5, py: 1 } } = props;

  if (isLinkDisabled) {
    return (
      <Card variant="outlined" sx={{ opacity: 0.75, ...sx }}>
        <ExperienceCardContent {...props} />
      </Card>
    );
  }

  return (
    <Link
      to={`/containers/${container.id}/experiences/${item.id}`}
      target="_blank"
    >
      <Card variant="outlined" sx={{ "&:hover": { bgcolor: "background.focused" }, ...sx }}>
        <ExperienceCardContent {...props} />
      </Card>
    </Link>
  );
}

export const ExperienceCardContent = (props) => {
  const { item = {}, contentComponentProps = {}, isWinningVariantHidden = false } = props;
  const currentStatus = getCurrentStatus(item);

  return (
    <Stack spacing={1} {...contentComponentProps}>
      <Typography variant="h6" overflow="hidden" textOverflow="ellipsis">
        {item.name}
      </Typography>
      <Stack direction="row" spacing={1}>
        <ChipWithIcon type={currentStatus} size="small" />
        <ChipWithIcon type={item.mode || "abtest"} size="small" />
        <ChipWithIcon type={item.type} size="small" />
        {(!!item.winningVariantId && !isWinningVariantHidden) && (
          <Stack direction="row" alignItems="center" ml="auto !important">
            <EmojiEventsIcon fontSize="small" color="action" />
            <WinningVariantChip {...{ item }} size="small" />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
