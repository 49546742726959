import React, { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createContainer } from "unstated-next";
import { FormContainer } from "react-hook-form-mui";
import { Drawer, Typography, Stack, IconButton, Card, Button } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

import RootBox from "./RootBox";
import FirestoreContainer from "./FirestoreContainer";

import TopicDrawerContent from "../components/TopicDrawerContent";
import SubmitButton from "../elements/SubmitButton";

export default createContainer(({ containerId, experienceId }) => {
  const navigate = useNavigate();
  const { setLeftOffset } = RootBox.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const [isOpen, setIsOpen] = useState(false);
  const [experience, setExperience] = useState(null);

  useEffect(() => {
    return () => {
      setLeftOffset(0);
    };
  }, []);

  function open() {
    setIsOpen(true);
    setLeftOffset(drawerWidth);
  }

  function close() {
    setIsOpen(false);
    setLeftOffset(0);
  }

  async function handleCreate(data) {
    if (firestoreContainer.checkIsUpgradeRequired("addTopic")) { return; }
    firestoreContainer.addTopic(data).then((topicId) => {
      setTimeout(close, 300);
      setTimeout(() => navigate(`/containers/${containerId}/topics/${topicId}`), 600);
    });
  }

  const Component = useMemo(() => {
    function handleClose(_, reason) {
      if (reason) { return; }
      close();
    }
  
    return (
      <>
        <Drawer
          open={isOpen}
          onClose={handleClose}
          anchor="left"
          variant="persistent"
          sx={{ zIndex: 10000 }}
          PaperProps={{ sx: { width: `${drawerWidth}px`, maxWidth: "100vw", bgcolor: "logo.light" } }}
        >
          <Stack
            direction="row" alignItems="center" spacing={2} px={2}
            sx={{ position: "sticky", top: 0, zIndex: 1000, height: 64, borderBottom: 1, borderColor: "white.main" }}
          >
            <SupportAgentIcon color="white" />
            <Typography flex={1} variant="h4" color="white.main">カスタマーサポート</Typography>
            <IconButton onClick={close}>
              <KeyboardDoubleArrowLeftIcon color="white" />
            </IconButton>
          </Stack>
          <Stack spacing={2} p={2}>
            <Stack spacing={1} textAlign="center">
              <Typography variant="h4" color="white.main">お困りですか？</Typography>
              <Typography variant="caption" color="white.main">担当スタッフが個別にサポートいたします。</Typography>
              <Typography variant="caption" color="white.main">通常1営業日以内に最初の回答が届きます。</Typography>
            </Stack>
            <Card variant="outlined" sx={{ border: "none" }}>
              <FormContainer onSuccess={handleCreate}>
                <Stack spacing={2} p={2}>
                  <TopicDrawerContent
                    defaultValue={experienceId ? { experienceIdArr: [experienceId] } : null}
                    experienceList={experience ? [experience] : []}
                    spacing={2} p={0}
                  />
                  <SubmitButton label="送信" color="logo" fullWidth />
                </Stack>
              </FormContainer>
            </Card>
            <Button
              href={`/containers/${containerId}#support`}
              target="_blank"
              variant="outlined"
              color="white"
              fullWidth
            >
              お問合せ履歴を確認
            </Button>
          </Stack>
        </Drawer>
        <IconButton
          onClick={open}
          color="white"
          sx={{
            bottom: 32, left: 32,
            position: "fixed", zIndex: 9000,
            bgcolor: "logo.main",
            transition: "inherit",
            "&:hover": { bgcolor: "logo.dark", transform: "scale(1.1)" },
          }}
        >
          <SupportAgentIcon />
        </IconButton>
      </>
    );
  }, [isOpen, experience]);

  return { Component, setExperience };
});

const drawerWidth = 400;
