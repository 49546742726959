import React from "react";
import { Stack, Typography, Switch, Alert } from "@mui/material";
import SellIcon from "@mui/icons-material/Sell";
import { CopyBlock, atomOneDark } from "react-code-blocks";

import BuilderCard from "../elements/BuilderCard";
import SupportButton from "../elements/SupportButton";

const title = "インストール"; const supportId = "container-snippet"

export default (props) => {
  const { container = {}, readOnly, handleUpdate } = props;
  const { gtmPublicId, isAntiflickerActive = true } = container;

  function handleChange() {
    handleUpdate({ isAntiflickerActive: !isAntiflickerActive }, "スニペットを更新");
  }

  return (
    <BuilderCard
      {...{ title, supportId }}
      color="success"
      Icon={SellIcon}
      renderContent={() => (
        <Stack spacing={2}>
          <Typography variant="body4">
            {"対象となるすべてのページで、<head>タグ内のなるべく上部に以下のスニペットを貼り付けてください。"}
          </Typography>
          <CopyBlock
            text={getCode(gtmPublicId, isAntiflickerActive)}
            language="html"
            theme={atomOneDark}
            codeBlock
            showLineNumbers={false}
          />
          <Stack spacing={1} direction="row" alignItems="center">
            <Switch
              checked={isAntiflickerActive}
              onChange={handleChange}
              disabled={readOnly}
            />
            <Typography variant="h5" component="p">
              アンチフリッカーを有効化（推奨）
            </Typography>
          </Stack>
          <Alert
            severity="warning"
            action={<SupportButton {...{ supportId }} />}
          >
            このスニペットはソースコードに直接記述することを強く推奨いたします。<br/>
            Googleタグマネージャーなどのタグ管理ツールを利用して設置する場合は、「アンチフリッカーを有効化」をOFFにしてからスニペットをコピーしてください。
          </Alert>
        </Stack>
      )}
    />
  );
}

function getCode(gtmPublicId, isAntiflickerActive) {
  return [
    "<!-- Optimize Next -->",
    "<script>(function(p,r,o,j,e,c,t,g){",
    "p['_'+t]={};g=r.createElement('script');g.src='https://www.googletagmanager.com/gtm.js?id=GTM-'+t;r[o].prepend(g);",
    isAntiflickerActive ? "g=r.createElement('style');g.innerText='.'+e+t+'{visibility:hidden!important}';r[o].prepend(g);" : null,
    "r[o][j].add(e+t);setTimeout(function(){if(r[o][j].contains(e+t)){r[o][j].remove(e+t);p['_'+t]=0}},c)",
    `})(window,document,'documentElement','classList','loading',2000,'${gtmPublicId?.replace("GTM-", "")}')</script>`,
    "<!-- End Optimize Next -->",
  ].filter(Boolean).join("\n");
}
