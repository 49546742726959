import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";
import { Alert, AlertTitle, Button, Container, Divider, Stack, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import RefreshIcon from "@mui/icons-material/Refresh";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import Header from "../containers/Header";
import AlertDialog from "../containers/AlertDialog";
import Drawer from "../containers/Drawer";
import Extension from "../containers/Extension";
import Firebase from "../containers/Firebase";
import FirestoreContainer from "../containers/FirestoreContainer";
import FirestoreExperience from "../containers/FirestoreExperience";
import Analytics from "../containers/Analytics";

import ReportAccordion from "../components/ReportAccordion";
import PreviewAccordion from "../components/PreviewAccordion";
import ScheduleCard from "../components/ScheduleCard";
import ConditionListCard from "../components/ConditionListCard";
import VariantListCard from "../components/VariantListCard";
import PageListCard from "../components/PageListCard";
import PageVariantListCard from "../components/PageVariantListCard";
import TriggerCard from "../components/TriggerCard";
import ExperienceDrawerContent from "../components/ExperienceDrawerContent";
import RequestStatusAlert from "../components/RequestStatusAlert";
import ActionButton from "../elements/ActionButton";

import * as time from "../utils/time";
import ja from "../utils/ja.json";

export default (props) => {
  const { experienceList, assetList, requestList, currentStatus } = props;
  const navigate = useNavigate();
  const header = Header.useContainer();
  const alertDialog = AlertDialog.useContainer();
  const drawer = Drawer.useContainer();
  const extension = Extension.useContainer();
  const firebase = Firebase.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const firestoreExperience = FirestoreExperience.useContainer();
  const analytics = Analytics.useContainer();
  const { docData: container, currentUserRole, checkIsUpgradeRequired } = firestoreContainer;
  const { docData: experience } = firestoreExperience;

  const { id: containerId, gtmPublicId, ga4Info = {} } = container;
  const { id: experienceId, name, type, gtmInfo = {}, isReportActive = true, productionUpdatedAt, previewUpdatedAt, scheduledStartAt, scheduledEndAt } = experience;

  const productionRequestList = useMemo(() => requestList.filter(({ action = "" }) => !action.endsWith("Preview")), [requestList]);
  const previewRequestList = useMemo(() => requestList.filter(({ action = "" }) => action.endsWith("Preview")), [requestList]);

  const isViewer = useMemo(() => (currentUserRole === "viewer"), [currentUserRole]);
  const isAbovePublisher = useMemo(() => ["owner", "admin", "publisher"].includes(currentUserRole), [currentUserRole]);

  const readOnly = useMemo(() => (isViewer || ["closed", "archived"].includes(currentStatus)), [isViewer, currentStatus]);
  const hasStarted = useMemo(() => ["running", "paused"].includes(currentStatus), [currentStatus]);

  const isProductionUpToDate = useMemo(() => (productionUpdatedAt - gtmInfo.productionUpdatedAt === 0), [experience]);
  const isPreviewUpToDate = useMemo(() => (previewUpdatedAt - gtmInfo.previewUpdatedAt === 0), [experience]);
  const isPreviewActive = useMemo(() => !!gtmInfo.previewTagId, [experience]);

  useEffect(() => {
    extension.addMessageListener((data) => {
      if ((data.containerId !== containerId) || (data.experienceId !== experienceId)) { return; }
      switch (data.action) {
        case "saveChanges":
          //拡張機能からの命令で、変更を保存
          handleUpdateChanges(data);
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    if (!name || isViewer) { return; }
    function handleEditName() {
      const defaultValue = { name };
      drawer.open({
        title: "エクスペリエンス名",
        btnLabel: "更新",
        defaultValue,
        ContentComponent: ExperienceDrawerContent,
        contentComponentProps: { experienceList: experienceList.filter((x) => x.id !== experienceId) },
        onSuccess: (data) => handleUpdateExtra(data),
      });
    }  
    header.setTitleRight(<ActionButton onClick={handleEditName} type="edit" color="gray" />);
    return () => { header.setTitleRight(null); }
  }, [name, isViewer]);

  function handleUpdateChanges({ page, variant, changeList = [], editorTabId }) {
    handleUpdate({ [`pageVariantObj.${page.id}@${variant.id}`]: { changeList } }, () => {
      extension.postMessage({ action: "closeEditor", editorTabId });
    });
  }

  function handleUpdate(data, onSuccess = () => setTimeout(drawer.close, 300), isExtra) {
    const timestampKeyArr = ["productionUpdatedAt"];
    if (!isExtra) { timestampKeyArr.push("previewUpdatedAt"); }
    firestoreExperience.update({ ...data, timestampKeyArr }).then(onSuccess);
  }

  function handleUpdateExtra(data, onSuccess) {
    handleUpdate(data, onSuccess, true);
  }

  async function addRequest(action) {
    switch (action) {
      case "start": case "schedule":
        if (isReportActive) {
          const audienceList = await analytics.createAudienceList(container, experience);
          if (!audienceList) { return; }
          const ret = await firestoreExperience.update({ ga4Info: { audienceList } }, null);
          if (!ret) { return; }
        }
        break;
      default: break;
    }
    await firestoreExperience.addRequest({
      action,
      experience,
      status: "processing",
    });
  }

  async function handleConfirmRequest(requestId) {
    firestoreExperience.updateRequest(requestId, { isActive: false });
  }

  function handleOpenEditor(params) {
    extension.postMessage({
      action: "openEditor",
      containerId,
      experienceId,
      gtmPublicId,
      name,
      assetList,
      readOnly,
      ...params,
      device: "iPhone 14",
    });
  }

  const publishBtnProps = useMemo(() => {
    if (isProductionUpToDate && (currentStatus !== "paused")) { return undefined; }
    let action = "", startIcon, color = "primary", descriptionArr = [];
    switch (currentStatus) {
      case "draft":
        action = "start"; startIcon = <PlayCircleOutlineIcon />;
        break;
      case "running": case "scheduled":
        action = "update"; startIcon = <RefreshIcon />;
        break;
      case "paused":
        action = "restart"; startIcon = <RestartAltIcon />;
        break;
      default:
        break
    }
    if (action.endsWith("start")) {
      const startDescription = `配信が${ja[action]}されます。`;
      if (time.isFuture(scheduledStartAt)) {
        descriptionArr.push(`${time.toString(scheduledStartAt)}に${startDescription}`);
        if (currentStatus === "draft") {
          action = "schedule"; startIcon = <ScheduleIcon />;
        }
      } else {
        descriptionArr.push(`今すぐ${startDescription}`);
      }
    }
    descriptionArr.push("必ず事前にプレビューで動作を確認してください。");
    if (hasStarted) {
      descriptionArr.push("開始後に加えた編集の内容によっては、テスト結果に影響が出るおそれがあります。");
    }
    return {
      action,
      description: descriptionArr.join("\n"),
      color,
      startIcon,
      disabled: ((currentStatus === "draft") && isReportActive && !firebase.oauthToken) || time.isPast(scheduledEndAt),
    };
  }, [currentStatus, experience, firebase.oauthToken]);

  const pauseBtnProps = useMemo(() => {
    if (currentStatus !== "running") { return undefined; }
    return {
      action: "pause",
      description: "再開するまで、エクスペリエンスは配信されなくなります。",
      startIcon: <PauseCircleIcon />,
      color: "warning",
    };
  }, [currentStatus]);

  const closeBtnProps = useMemo(() => {
    if (!["running", "scheduled", "paused"].includes(currentStatus)) { return undefined; }
    return {
      action: "close",
      description: "再開することはできません。\nプレビュー用URLは引き続き利用できます。",
      startIcon: <StopCircleIcon />,
      color: "error",
    };
  }, [currentStatus]);

  const publishPreviewBtnProps = useMemo(() => {
    if (isPreviewUpToDate) { return undefined; }
    return {
      action: isPreviewActive ? "updatePreview" : "activatePreview",
      description: isPreviewActive ? "最新の変更内容をプレビューに適用するには、更新してください。" : "プレビューを確認するには、有効化してください。",
      startIcon: isPreviewActive ? <RefreshIcon /> : <ToggleOnIcon />,
    };
  }, [isPreviewActive, isPreviewUpToDate]);

  const closePreviewBtnProps = useMemo(() => {
    if (!isPreviewActive) { return undefined; }
    return {
      action: "closePreview",
      description: "再度有効化するまで、プレビュー用URLが利用できなくなります。\n無効化すると、GTMコンテナサイズを削減できます。",
      startIcon: <ToggleOffIcon />,
      color: "warning",
      variant: "outlined",
    };
  }, [isPreviewActive]);

  function renderConfirmBtn(btnProps = {}) {
    const { action, description, color, ...restProps } = btnProps;
    if (!action) { return null; }
    const label = ja[action];
    function onClick() {
      alertDialog.open({
        title: `${action.endsWith("Preview") ? "" : "エクスペリエンスを"}${label}しますか？`,
        description,
        cancelable: true,
        actions: [{
          label,
          color,
          onClick: () => addRequest(action),
        }],
      });
    }
    return (
      <Button variant="contained" {...{ onClick, color, ...restProps }}>
        {label}
      </Button>
    );
  }

  return (
    <Container>
      <Stack spacing={3} py={2}>
        {isAbovePublisher && (
          <>
            {!ga4Info.propertyId ? (
              <Alert
                severity="error"
                action={
                  <Button
                    onClick={() => navigate(`/containers/${containerId}#settings`)}
                    color="inherit"
                    sx={{ m: "auto", fontWeight: "bold" }}
                  >
                    設定する
                  </Button>
                }
              >
                <AlertTitle>リンク先のGoogleアナリティクス{!ga4Info.measurementId ? "が未設定です。" : "を再設定してください。"}</AlertTitle>
                エクスペリエンスを開始するには、{!ga4Info.measurementId ? "計測を有効にしてください。" : "再設定が必要です。"}
              </Alert>
            ) : !!productionRequestList.length
              ? (
                <Stack spacing={2}>
                  {productionRequestList.map((request) => (
                    <RequestStatusAlert
                      key={request.id}
                      request={request}
                      handleConfirm={() => handleConfirmRequest(request.id)}
                    />
                  ))}
                </Stack>
              ) : !readOnly && (
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2} justifyContent="center">
                    {renderConfirmBtn(publishBtnProps)}
                    {renderConfirmBtn(pauseBtnProps)}
                    {renderConfirmBtn(closeBtnProps)}
                  </Stack>
                  {((currentStatus === "draft") && isReportActive && !firebase.oauthToken) && (
                    <Stack spacing={2} p={2} alignItems="center">
                      <Typography variant="h5" component="p">エクスペリエンスを{ja[publishBtnProps?.action]}するには、Googleアナリティクスへのアクセス権限が必要です。</Typography>
                      <GoogleButton onClick={firebase.getOauthToken} />
                    </Stack>
                  )}
                </Stack>
              )
            }
          </>
        )}

        {(!!gtmInfo.productionTagId && (currentStatus !== "closed")) && (
          <Alert
            severity={isProductionUpToDate ? "success" : "warning"}
            sx={{ "& .MuiAlert-message": { width: "100%" } }}
          >
            <AlertTitle>最新の変更内容が反映されていま{isProductionUpToDate ? "す" : "せん"}。</AlertTitle>
            <Stack direction="row" alignItems="center">
              <Typography flex={1} fontSize={14}>
                エクスペリエンスの最終更新：{time.toString(gtmInfo.productionUpdatedAt)}
              </Typography>
              <Typography flex={1} fontSize={14}>
                最新の変更：{time.toString(productionUpdatedAt)}
              </Typography>
            </Stack>
          </Alert>
        )}

        <ReportAccordion {...{ ...experience, readOnly, hasStarted, handleUpdateExtra }} />

        {(currentStatus !== "archived") && <>
          <PreviewAccordion {...experience}>
            <Stack spacing={2}>
              {!isViewer && (
                !!previewRequestList.length
                  ? previewRequestList.map((request) => (
                      <RequestStatusAlert
                        key={request.id}
                        request={request}
                        handleConfirm={() => handleConfirmRequest(request.id)}
                      />
                    ))
                  : !!previewUpdatedAt
                    ? <Stack direction="row" spacing={2} justifyContent="center">
                        {renderConfirmBtn(publishPreviewBtnProps)}
                        {renderConfirmBtn(closePreviewBtnProps)}
                      </Stack>
                    : <Alert severity="warning">変更が加えられていません。</Alert>
              )}
              {isPreviewActive && (
                <Alert
                  severity={isPreviewUpToDate ? "success" : "warning"}
                  sx={{ "& .MuiAlert-message": { width: "100%" } }}
                >
                  <AlertTitle>最新の変更内容が反映されていま{isPreviewUpToDate ? "す" : "せん"}。</AlertTitle>
                  <Stack direction="row" alignItems="center">
                    <Typography flex={1} fontSize={14}>
                      プレビュー内容の最終更新：{time.toString(gtmInfo.previewUpdatedAt)}
                    </Typography>
                    <Typography flex={1} fontSize={14}>
                      最新の変更：{time.toString(previewUpdatedAt)}
                    </Typography>
                  </Stack>
                </Alert>
              )}
            </Stack>
          </PreviewAccordion>
          <Divider />
        </>}

        <ScheduleCard {...{ ...experience, readOnly, hasStarted, handleUpdateExtra }} />

        <ConditionListCard {...{ ...experience, readOnly, handleUpdate }} />

        <VariantListCard {...{ ...experience, readOnly, hasStarted, handleUpdate, handleUpdateExtra, checkIsUpgradeRequired }} />

        <PageListCard {...{ ...experience, readOnly, handleUpdate, handleUpdateExtra }} />

        <Divider />

        {(!extension.isInstalled && (type !== "redirect")) && (
          <Alert
            severity="error"
            action={
              <Button
                startIcon={<OpenInNewIcon sx={{ width: "1rem", height: "1rem" }} />}
                color="inherit"
                href="https://chrome.google.com/webstore/detail/optimize-next/hhmmfngefldoedchbmpanmnjakgfacih"
                target="_blank"
                sx={{ m: "auto", fontWeight: "bold" }}
              >
                Chromeウェブストアに移動
              </Button>
            }
          >
            <AlertTitle>拡張機能がインストールされていません。</AlertTitle>
            エディタツールを使用するには、拡張機能をインストールする必要があります。
          </Alert>
        )}

        <PageVariantListCard
          {...{ ...experience, handleOpenEditor, readOnly, handleUpdate, handleUpdateExtra }}
        />

        <Divider />

        <TriggerCard {...{ ...experience, readOnly, handleUpdate }} />
      </Stack>
    </Container>
  );
}
