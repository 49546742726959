import React, { useMemo } from "react";
import { arrayRemove, arrayUnion } from "firebase/firestore";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AddIcon from "@mui/icons-material/Add";

import AlertDialog from "../containers/AlertDialog";
import Drawer from "../containers/Drawer";

import HypothesisCard from "../components/HypothesisCard";
import HypothesisSelectDrawerContent from "../components/HypothesisSelectDrawerContent";
import HypothesisDrawerContent from "../components/HypothesisDrawerContent";

import BuilderCard from "../elements/BuilderCard";
import { Button } from '@mui/material';
import { Stack } from '@mui/material';

const title = "検証対象の仮説";

export default (props) => {
  const {
    container = {}, experience = {}, experienceList = [], hypothesisList = [], indicatorList = [],
    readOnly, handleUpdate, handleCreateHypothesis, handleUpdateHypothesis, handleDeleteHypothesis,
  } = props;
  const { hypothesisIdArr = [] } = experience;

  const alertDialog = AlertDialog.useContainer();
  const drawer = Drawer.useContainer();

  const relatedHypothesisList = useMemo(() => (
    hypothesisIdArr.map((id) => hypothesisList.find((x) => x.id === id)).filter(Boolean)
  ), [hypothesisIdArr, hypothesisList]);

  function handleSelectClick() {
    drawer.open({
      title: `${title}を選択`,
      btnLabel: "更新",
      defaultValue: { hypothesisIdArr },
      ContentComponent: HypothesisSelectDrawerContent,
      contentComponentProps: { experienceList, hypothesisList, indicatorList },
      isWide: true,
      onSuccess: (data) => handleUpdate(data, `${title}を更新`).then(drawer.close),
    });
  }

  async function handleCreateOrEditClick(hypothesis) {
    const { id, name, status, indicatorIdArr, description } = hypothesis || {};
    const defaultValue = hypothesis ? { name, status, indicatorIdArr, description } : { status: "unverified" };
    const btnLabel = hypothesis ? "更新" : "作成";
    drawer.open({
      title: `仮説を${btnLabel}`,
      btnLabel,
      defaultValue,
      ContentComponent: HypothesisDrawerContent,
      contentComponentProps: { indicatorList },
      onSuccess: hypothesis ? handleUpdate : handleCreate,
    });

    async function handleCreate(data) {
      handleCreateHypothesis(data)
        .then((hypothesisId) => {
          handleUpdate({ hypothesisIdArr: arrayUnion(hypothesisId) }, `${title}を更新`)
            .then(() => setTimeout(drawer.close, 300));
        });
    }
  
    async function handleUpdate(data) {
      handleUpdateHypothesis(id, data)
        .then(() => setTimeout(drawer.close, 300));
    }
  }

  function handleUnlinkHypothesisClick(hypothesis) {
    const { id, name } = hypothesis;
    alertDialog.open({
      title: `仮説「${name}」との紐付けを解除しますか？`,
      description: "検証対象の仮説から削除されます。仮説自体は削除されません。",
      cancelable: true,
      actions: [{
        label: "紐付けを解除",
        color: "warning",
        onClick: () => {
          handleUpdate({ hypothesisIdArr: arrayRemove(id) }, `${title}を更新`)
        },
      }],
    });
  }

  function handleDeleteHypothesisClick(hypothesis) {
    const { id, name } = hypothesis;
    const hasRelatedExperience = experienceList.some(({ hypothesisIdArr = [] }) => hypothesisIdArr.includes(id));
    alertDialog.open({
      title: `仮説「${name}」を削除しますか？`,
      description: hasRelatedExperience ? "エクスペリエンスとの紐付けも失われます。" : "",
      cancelable: true,
      actions: [{
        label: "削除",
        color: "error",
        onClick: () => handleDeleteHypothesis(id),
      }],
    });
  }

  return (
    <BuilderCard
      {...{ title }}
      color="logo"
      Icon={TextSnippetOutlinedIcon}
      renderContent={() => (
        <Stack spacing={2}>
          <Stack direction="row" spacing={3} justifyContent="center">
            <Button
              variant="contained"
              onClick={handleSelectClick}
              startIcon={<ChecklistIcon />}
            >
              仮説を選択
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleCreateOrEditClick()}
              startIcon={<AddIcon />}
            >
              仮説を作成
            </Button>
          </Stack>
          {relatedHypothesisList.map((hypothesis) => (
            <HypothesisCard
              key={hypothesis.id}
              item={hypothesis}
              onEditClick={() => handleCreateOrEditClick(hypothesis)}
              onUnlinkClick={() => handleUnlinkHypothesisClick(hypothesis)}
              onDeleteClick={() => handleDeleteHypothesisClick(hypothesis)}
              selfExperienceId={experience.id}
              {...{ readOnly, container, experienceList, indicatorList }}
            />
          ))}
        </Stack>
      )}
    />
  );
}
