import React from "react";
import { Stack, Typography } from "@mui/material";

import ExternalLink from "./ExternalLink";

export default function Item (props) {
  const { title, body, list = [], linkList = [] } = props;
  return (
    <Stack spacing={!!title ? 2 : 1}>
      {!!title && <Typography variant="h3" component="h2" fontSize="1.6rem">{title}</Typography>}
      {!!body && <Typography variant="body3">{body}</Typography>}
      {(!!list.length || !!linkList.length) && (
        <Stack pl={!!title ? 0 : 2}>
          {list.map((listItem, i) => <Item key={i} {...listItem} />)}
          {linkList.map((linkProps, i) => <ExternalLink key={i} {...linkProps} />)}
        </Stack>
      )}
    </Stack>
  );
}
