import React, { useMemo } from "react";
import { Grid, Stack, Chip, Card, CardHeader, Typography, Button, Avatar } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import ExperienceCard from "./ExperienceCard";
import MenuButton from "../elements/MenuButton";
import AccordionCard from "../elements/AccordionCard";
import ChipWithIcon from "../elements/ChipWithIcon";

export default (props) => {
  const { sx, ...restProps } = props;
  return (
    <Card variant="outlined" { ...{ sx }}>
      <HypothesisCardContent {...restProps} />
    </Card>
  );
}

export const HypothesisCardContent = (props) => {
  const {
    item = {}, container = {}, experienceList = [], indicatorList = [],
    readOnly, onEditClick, onUnlinkClick, onDeleteClick, onCreateExperienceClick,
    selfExperienceId, isStatusShown, isExperiencesHidden, isExperienceLinkDisabled, isSlim, isDense = isSlim,
  } = props;
  const { id, name, status, indicatorIdArr = [], description, createdByUserId } = item;
  const { userObj = {} } = container;

  const relatedExperienceList = useMemo(() => (
    experienceList.filter(({ hypothesisIdArr = [] }) => hypothesisIdArr.includes(id))
  ), [experienceList]);

  const createdByUser = useMemo(() => userObj[createdByUserId], [userObj, createdByUserId]);

  const menuItems = [
    { label: "編集", Icon: EditIcon, color: "primary", onClick: onEditClick },
    { label: "紐付けを解除", Icon: RemoveCircleOutlineIcon, color: "warning", onClick: onUnlinkClick, hidden: !selfExperienceId },
    { label: "削除", Icon: DeleteForeverIcon, color: "error", onClick: onDeleteClick },
  ];

  return (
    <>
      <CardHeader
        title={(
          <Stack direction="row" spacing={1} alignItems="center">
            {!!isStatusShown && <ChipWithIcon type={status} size="small" variant="filled" />}
            <Typography variant="h5" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {name}
            </Typography>
          </Stack>
        )}
        action={!readOnly && <MenuButton items={menuItems} />}
        sx={{ p: isDense ? 1.5 : undefined, pb: 0 }}
      />
      <Stack p={isDense ? 1.5 : 2} py={1} spacing={1}>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} md={isSlim ? 12 : 6}>
            <Stack spacing={1}>
              <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                {indicatorIdArr.map((indicatorId) => {
                  const indicator = indicatorList.find((x) => x.id === indicatorId);
                  if (!indicator) { return null; }
                  return (
                    <Chip
                      key={indicatorId}
                      label={indicator.name}
                      color="primary"
                      variant="outlined"
                      size="small"
                      sx={{ borderRadius: 2 }}
                    />
                  );
                })}
              </Stack>
              {!!description && (
                <Typography variant="body5">{description}</Typography>
              )}
            </Stack>
          </Grid>
          {!isExperiencesHidden && (
            <Grid item xs={12} md={isSlim ? 12 : 6}>
              <AccordionCard
                summary={(
                  <Typography variant="caption">
                    {`関連するエクスペリエンス (${relatedExperienceList.length})`}
                  </Typography>
                )}
                defaultExpanded={!relatedExperienceList.length && !isSlim && !!onCreateExperienceClick}
                onClick={(e) => e.stopPropagation()}
                sx={{ bgcolor: "background.card" }}
              >
                <Stack spacing={1}>
                  {!!relatedExperienceList.length && (
                    <Stack spacing={0.5}>
                      {relatedExperienceList.map((experience) => (
                        <ExperienceCard
                          key={experience.id}
                          item={experience}
                          isLinkDisabled={isExperienceLinkDisabled || (experience.id === selfExperienceId)}
                          {...{ container }}
                        />
                      ))}
                    </Stack>
                  )}
                  {(!!onCreateExperienceClick && !readOnly) && (
                    <Button
                      onClick={onCreateExperienceClick}
                      size="small"
                      variant="contained"
                      color="primary"
                      sx={{ mx: "auto !important" }}
                    >
                      エクスペリエンスを作成
                    </Button>
                  )}
                </Stack>
              </AccordionCard>
            </Grid>
          )}
        </Grid>
        <Stack direction="row" spacing={0.7} justifyContent="flex-end" alignItems="center" overflow="hidden" sx={{ textWrap: "nowrap" }}>
          <Typography variant="caption">
            作成者：
          </Typography>
          <Avatar
            src={createdByUser?.photoURL}
            alt={createdByUser?.displayName}
            sx={{ width: 20, height: 20 }}
          />
          <Typography variant="caption" overflow="hidden" textOverflow="ellipsis">
            {createdByUser?.displayName}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}
