import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import "swiper/css";

import App from "./App";
import theme from "./theme";
import "./index.css";

import Extension from "./containers/Extension";
import AlertDialog from "./containers/AlertDialog";
import Snackbar from "./containers/Snackbar";
import Drawer from "./containers/Drawer";
import Firebase from "./containers/Firebase";
import Analytics from "./containers/Analytics";
import Header from "./containers/Header";
import RootBox from "./containers/RootBox";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (document.location.hostname !== "optimize-next.com" && document.location.hostname !== "localhost") {
  document.location.replace(document.location.href.replace(document.location.hostname, "optimize-next.com"));
}

root.render(
  <BrowserRouter>
    <ThemeProvider {...{ theme }}>
      <RootBox.Provider>
        <Extension.Provider>
          <AlertDialog.Provider>
            <Snackbar.Provider>
              <Drawer.Provider>
                <Firebase.Provider>
                  <Analytics.Provider>
                    <Header.Provider>
                      <App />
                    </Header.Provider>
                  </Analytics.Provider>
                </Firebase.Provider>
              </Drawer.Provider>
            </Snackbar.Provider>
          </AlertDialog.Provider>
        </Extension.Provider>
      </RootBox.Provider>
    </ThemeProvider>
  </BrowserRouter>
);
