import React, { useEffect, useMemo, useState } from "react";
import { Container, Alert, AlertTitle, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Divider, Stack, Chip, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import GoogleButton from "react-google-button";

import Firebase from "../containers/Firebase";
import Analytics from "../containers/Analytics";
import FirestoreContainer from "../containers/FirestoreContainer";
import FirestoreExperience from "../containers/FirestoreExperience";
import Drawer from "../containers/Drawer";

import VStack from "../elements/VStack";
import BuilderCard from "../elements/BuilderCard";
import SupportButton from "../elements/SupportButton";
import GoalCard from "../components/GoalCard";
import MetricCard from "../components/MetricCard";

import ja from "../utils/ja.json";

export default (props) => {
  const { currentStatus } = props;
  const firebase = Firebase.useContainer();
  const analytics = Analytics.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const firestoreExperience = FirestoreExperience.useContainer();
  const drawer = Drawer.useContainer();
  const { docData: container = {}, currentUserRole } = firestoreContainer;
  const { docData: experience = {} } = firestoreExperience;

  const readOnly = useMemo(() => (currentUserRole === "viewer") || (currentStatus === "archived"), [currentUserRole, currentStatus]);

  const { mode, ga4Info, gtmInfo, isReportActive = true } = experience;
  const isRollout = useMemo(() => (mode === "rollout"), [mode]);

  if ((currentStatus === "draft") && !isReportActive) {
    return (
      <VStack maxWidth="lg" py={4}>
        <Alert
          severity="warning"
          sx={{ minWidth: "40%" }}
        >
          レポートは有効化されていません。
        </Alert>
      </VStack>
    );
  }

  if (["draft", "scheduled"].includes(currentStatus)) {
    return (
      <VStack maxWidth="lg" py={4}>
        <Alert
          severity="info"
          sx={{ minWidth: "40%" }}
        >
          レポートはエクスペリエンスの開始後に表示されます。
        </Alert>
      </VStack>
    );
  }

  if (!gtmInfo?.startAt) {
    return (
      <VStack maxWidth="lg" py={4}>
        <Alert
          severity="warning"
          sx={{ minWidth: "40%" }}
        >
          このエクスペリエンスは開始されませんでした。
        </Alert>
      </VStack>
    );
  }

  function renderAlert(label) {
    return (
      <VStack maxWidth="lg" py={4}>
        <Alert
          severity="warning"
          action={(
            <Button
              startIcon={<HelpIcon sx={{ width: "1rem", height: "1rem" }} />}
              color="inherit"
              href="https://support.optimize-next.com/experience-report-ga4"
              target="_blank"
              sx={{ m: "auto", fontWeight: "bold" }}
            >
              作成方法を見る
            </Button>
          )}
          sx={{ minWidth: "40%" }}
        >
          <AlertTitle>
            {label}されたエクスペリエンスでは、レポートを表示できません。
          </AlertTitle>
          Googleアナリティクスで「探索レポート」を作成する必要があります。
        </Alert>
      </VStack>
    );
  }

  if (!container.ga4Info) {
    return renderAlert("β版で作成");
  }

  if (!isReportActive) {
    return renderAlert("レポートを有効化せずに開始");
  }

  const [reportList, setReportList] = useState(ga4Info.reportList || []);

  if ((currentStatus === "archived") && !reportList.length) {
    return renderAlert("レポートを保存せずにアーカイブ");
  }

  useEffect(() => {
    if (firebase.oauthToken) {
      updateReportList();
    }
  }, [firebase.oauthToken]);

  async function updateReportList(experienceData = experience, isChanged = false) {
    switch (currentStatus) {
      case "archived":
        return;
      case "closed":
        if (!!reportList.length && !isChanged) { return; }
        break;
      default:
        break;
    }

    const newReportList = await analytics.getReportList(container, experienceData, isChanged);
    if (!newReportList) { return; }
    setReportList(newReportList);
    if (currentStatus === "closed") {
      firestoreExperience.update({ "ga4Info.reportList": newReportList }, "レポートを保存");
    }
  }

  if (!firebase.oauthToken && !reportList.length) {
    return (
      <VStack maxWidth="lg" py={4}>
        <Typography variant="h5" component="p">レポートを表示するには、Googleアナリティクスへのアクセス権限が必要です。</Typography>
        <GoogleButton onClick={firebase.getOauthToken} />
      </VStack>
    );
  }

  function handleUpdate(data) {
    firestoreExperience.update(data, "レポートの設定を更新")
      .then(() => {
        updateReportList({ ...experience, ...data }, true);
        setTimeout(drawer.close, 300);
      });
  }

  return (
    <Container>
      <Stack spacing={3} py={2}>
        {reportList.map(({ eventName, metricName = "totalUsers", variantRowList = [], pValue }, i) => {
          const isNonsignificant = (pValue > 0.4);
          return (
            <BuilderCard
              key={i}
              title={(
                <Stack flex={4} direction="row" alignItems="center">
                  <Typography variant="body2">{`目標${i + 1}`}</Typography>
                  <Chip label={eventName} variant="outlined" />
                </Stack>
              )}
              renderContent={() => (
                <>
                  <TableContainer variant="outlined" component={Paper} sx={{ width: "100%" }}>
                    <Table>
                      <TableHead sx={{ "& th": { fontSize: "small", color: "gray", padding: "8px 16px" } }}>
                        <TableRow>
                          {!isRollout && <TableCell>パターン名</TableCell>}
                          <TableCell>{ja[metricName]}</TableCell>
                          <TableCell>目標達成{ja[metricName]}</TableCell>
                          <TableCell>目標達成率</TableCell>
                          {!isRollout && (
                            <TableCell sx={{ color: isNonsignificant ? "gray.main" : "inherit" }}>
                              <Stack direction="row" alignItems="center">
                                最適である確率
                                <SupportButton supportId="experience-report-bayesian" sx={{ p: 0, pl: 0.5, mt: "-1px" }} />
                              </Stack>
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {variantRowList.map((row, j) => {
                          let winProbSx;
                          if (isNonsignificant) {
                            winProbSx = { color: "gray.main" };
                          } else if (row.winProb > 0.85) {
                            winProbSx = { color: "success.main", fontWeight: "bold" };
                          } else if (row.winProb < 0.15) {
                            winProbSx = { color: "error.main", fontWeight: "bold" };
                          }
                          return (
                            <TableRow key={j}>
                              {!isRollout && <TableCell>{row.variantName}</TableCell>}
                              <TableCell>{getNumberString(row.imp)}</TableCell>
                              <TableCell>{getNumberString(row.cv)}</TableCell>
                              <TableCell>{getPercentString(row.cv / row.imp)}</TableCell>
                              {!isRollout && <TableCell sx={winProbSx}>{getPercentString(row.winProb)}</TableCell>}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {isNonsignificant && (
                    <Alert severity="warning" sx={{ mt: 2 }}>
                      統計的に有意な差は見られません。
                      {(["running", "paused"].includes(currentStatus) && variantRowList.reduce((sum, x) => sum + x.imp, 0) < 1000) && (
                        <Typography variant="caption" color="inherit" display="block">
                          十分なサンプル数を確保するため、テストを続行してください。
                        </Typography>
                      )}
                    </Alert>
                  )}
                </>
              )}
            />
          );
        })}

        {!!reportList.length && <Divider />}

        <GoalCard
          {...{ container, experience, handleUpdate, readOnly }}
        />
        <MetricCard
          {...{ experience, handleUpdate, readOnly }}
        />
      </Stack>
    </Container>
  );
}

function getNumberString(num) {
  return Number(num || 0).toLocaleString("en");
}

function getPercentString(num) {
  if (!(num >= 0)) { return "-"; }
  return (num * 100 || 0).toFixed(2) + "%";
}
