import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar, Container } from "@mui/material";

import FirestoreContainer from "../containers/FirestoreContainer";
import FirestoreExperience from "../containers/FirestoreExperience";

import ja from "../utils/ja.json";
import * as time from "../utils/time";

export default (props) => {
  const { currentStatus } = props;
  const firestoreContainer = FirestoreContainer.useContainer();
  const firestoreExperience = FirestoreExperience.useContainer();
  const { docData: container = {} } = firestoreContainer;
  const { docData: experience = {} } = firestoreExperience;
  const { dataList: historyList = [] } = firestoreExperience.useHistoryCol();

  function renderUserCell(userId) {
    const user = container.userObj?.[userId];
    return (
      <TableCell sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={user?.photoURL}
          alt={user?.displayName}
          sx={{ width: 30, height: 30, mr: 2 }}
        />
        {!!user ? `${user.displayName}（${user.email}）` : "不明なメンバー"}
      </TableCell>
    );
  }

  return (
    <Container sx={{ py: 2 }}>
      <TableContainer variant="outlined" component={Paper} sx={{ width: "100%" }}>
        <Table>
          <TableHead sx={{ "& th": { fontSize: "small", color: "gray", padding: "8px 16px" } }}>
            <TableRow>
              <TableCell>日時</TableCell>
              <TableCell>操作</TableCell>
              <TableCell>メンバー</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(currentStatus === "archived") && (
              <TableRow>
                <TableCell>{time.toString(experience.gtmInfo?.archivedAt)}</TableCell>
                <TableCell>アーカイブ</TableCell>
                {renderUserCell(experience.gtmInfo?.archivedByUserId || experience.createdByUserId)}
              </TableRow>
            )}
            {historyList.map((history) => (
              <TableRow key={history.id}>
                <TableCell>{time.toString(history.resolvedAt)}</TableCell>
                <TableCell>{ja[history.action]}</TableCell>
                {renderUserCell(history.createdByUserId)}
              </TableRow>
            ))}
            <TableRow>
              <TableCell>{time.toString(experience.createdAt)}</TableCell>
              <TableCell>作成</TableCell>
              {renderUserCell(experience.createdByUserId)}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
