import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Stack, IconButton, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default (props) => {
  const { children, containerId } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const isUpMd = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { offsetLeft = 0 } = window[containerId] || {};

  const Chevron = ({ prev }) => (
    <IconButton
      onClick={() => swiper[`slide${prev ? "Prev" : "Next"}`]()}
      disabled={isUpMd ? false : prev ? (activeIndex === 0) : (activeIndex === (children.length - 1))}
    >
      {prev ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  );

  return (
    <Stack
      spacing={2}
      sx={{ "& .swiper-wrapper": { overflow: "visible" } }}
    >
      <Swiper
        onSwiper={setSwiper}
        onActiveIndexChange={(x) => setActiveIndex(x.activeIndex)}
        style={{ marginInline: `-${offsetLeft}px` }}
        noSwiping={false}
        slidesPerView="auto"
        centeredSlides={!isUpMd}
        freeMode={isUpMd}
        slidesOffsetBefore={isUpMd ? offsetLeft : 0}
        slidesOffsetAfter={isUpMd ? offsetLeft : 0}
      >
        {children.map((Component, i) => (
          <SwiperSlide
            key={i}
            style={{ width: "25rem", maxWidth: "80vw", paddingInline: 8 }}
          >
            {Component}
          </SwiperSlide>
        ))}
      </Swiper>
      <Stack direction="row" spacing={2} alignSelf="center">
        <Chevron prev />
        <Chevron />
      </Stack>
    </Stack>
  );
}
