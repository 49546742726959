import React  from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default (props) => {
  const { onClick, title, label, color } = props;
  return (
    <Button
      {...{ onClick, color }}
      startIcon={<AddIcon />}
      sx={{ padding: 0, width: "fit-content" }}
    >
      {title || `${label}を追加`}
    </Button>
  );
};
