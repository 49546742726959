import React, { useState, useMemo, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { useFormContext } from "react-hook-form-mui";
import { Button, Stack, Typography } from "@mui/material";

import AlertDialog from "../containers/AlertDialog";
import Drawer from "../containers/Drawer";

import IndicatorCard from "./IndicatorCard";
import TemplateDrawerContent from "./TemplateDrawerContent";
import VStack from "../elements/VStack";
import { getRandomId } from "../utils/random";

export default (props) => {
  const {
    readOnly, isTemplate, container = {}, indicatorList = [], isIndicatorListLocal, hypothesisList = [],
    focusedIndicatorId, ghostParentIndicatorId,
    handleDelete, isUnderSubmission, handleCreateTemplate,
  } = props;
  const { reset, watch, setValue, unregister, setError, clearErrors, setFocus } = useFormContext();
  const alertDialog = AlertDialog.useContainer();
  const drawer = Drawer.useContainer();

  const [sortOrderDiff, setSortOrderDiff] = useState(0);
  const [warpParentIndicatorId, setWarpParentIndicatorId] = useState(null);
  const ref = useRef(null);
  
  useEffect(() => {
    reset({});
    if (ghostParentIndicatorId) {
      handleScrollAdjust();
      setTimeout(() => setFocus("name"), 100);
    }
  }, [ghostParentIndicatorId]);

  useEffect(() => {
    if (focusedIndicatorId) {
      setTimeout(() => setFocus("name"), 100);
    } else {
      reset({});
      setSortOrderDiff(0);
      setWarpParentIndicatorId(null);
    }
  }, [focusedIndicatorId]);

  useEffect(() => {
    if (isTemplate) {
      if (sortOrderDiff) {
        setValue("sortOrderDiff", sortOrderDiff);
      } else {
        unregister("sortOrderDiff");
      }
    }
  }, [sortOrderDiff]);

  useEffect(() => {
    if (focusedIndicatorId) {
      handleScrollAdjust();
    }
  }, [focusedIndicatorId, sortOrderDiff, warpParentIndicatorId]);

  const [nestedIndicatorList, activeIndicatorList] = useMemo(() => {
    const nestedList = [], activeList = [];
    function addToList(parent, list = []) {
      if (!parent) { return; }
      if (parent.id !== "root") { activeList.push(parent); }
      const childList = [];
      let sortTargetIndex;
      indicatorList.forEach((x) => {
        if ((focusedIndicatorId === x.id) && !!warpParentIndicatorId && !isIndicatorListLocal) {
          return;
        }
        if (x.parentIndicatorId === parent.id) {
          if (!!sortOrderDiff && (x.id === focusedIndicatorId) && !isIndicatorListLocal) {
            sortTargetIndex = childList.length;
          }
          addToList(x, childList);
        }
      });
      if ((warpParentIndicatorId === parent.id) && !isIndicatorListLocal) {
        if (!!sortOrderDiff) {
          sortTargetIndex = childList.length;
        }
        addToList({ ...indicatorList.find((x) => x.id === focusedIndicatorId), parentIndicatorId: warpParentIndicatorId }, childList);
      }
      if (sortTargetIndex !== undefined) {
        const sortTargetItem = childList[sortTargetIndex];
        childList.splice(sortTargetIndex, 1);
        childList.splice(sortTargetIndex + sortOrderDiff, 0, sortTargetItem);
      }
      if (parent.id === "root") {
        childList.forEach((x) => list.push(x));
      } else {
        list.push({ ...parent, childList });
      }
    }
    addToList({ id: "root" }, nestedList);
    return [nestedList, activeList];
  }, [indicatorList, focusedIndicatorId, sortOrderDiff, warpParentIndicatorId]);

  useEffect(() => {
    const subscription = watch(({ name = "" }) => {
      if (activeIndicatorList.some((x) => (x.id !== focusedIndicatorId) && (x.name === name))) {
        setError("name", {
          type: "manual",
          message: "すでに使われているKPI名です",
        });
      } else {
        clearErrors("name");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, focusedIndicatorId, activeIndicatorList]);

  function onDeleteClick(item) {
    let description = "仮説との紐付けも失われます。";
    if (item.childList?.length) {
      description += "\n下層のKPIも全て削除されます。";
    }
    alertDialog.open({
      title: `「${item.name}」を削除しますか？`,
      description,
      cancelable: true,
      actions: [{
        label: "削除",
        color: "error",
        onClick: handleDelete,
      }],
    });
  }

  function handleSort(value) {
    const focusedInputName = document.activeElement?.name;
    if (focusedInputName) {
      setTimeout(() => setFocus(focusedInputName), 100);
    }
    setSortOrderDiff((prev) => prev + value);
  }

  function handleWarp(parentIndicatorId) {
    setSortOrderDiff(0);
    setWarpParentIndicatorId(parentIndicatorId);
  }

  function handleScrollAdjust() {
    setTimeout(() => {
      const card = document.getElementById(!!ghostParentIndicatorId ? "ghost" : focusedIndicatorId);
      if (card) {
        card.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }
    }, 100);
  }

  function handleTemplateClick() {
    alertDialog.open({
      title: "テンプレートを作成しますか？",
      description: "KPIツリーと仮説をテンプレートとして保存すると、他のコンテナにも共有できるようになります。\nKPIの「現状数値」「目標数値」と仮説の「ステータス」は反映されません。",
      cancelable: true,
      actions: [{ label: "テンプレートを作成", onClick }],
    });

    function onClick() {
      const indicatorAliasIdObj = { root: "root" };
      const templateIndicatorList = activeIndicatorList.map(({ id, name, description, parentIndicatorId }) => {
        const aliasId = getRandomId();
        indicatorAliasIdObj[id] = aliasId;
        return { id: aliasId, parentIndicatorId: indicatorAliasIdObj[parentIndicatorId], name, description };
      });
      const templateHypothesisList = hypothesisList.map(({ indicatorIdArr = [], name, description }) => {
        return { id: getRandomId(), indicatorIdArr: indicatorIdArr.map((indicatorId) => indicatorAliasIdObj[indicatorId]), name, description };
      });
      setTimeout(() => {
        drawer.open({
          title: "テンプレートを作成",
          btnLabel: "作成",
          ContentComponent: TemplateDrawerContent,
          defaultValue: { indicatorList: templateIndicatorList, hypothesisList: templateHypothesisList, accessMode: "private" },
          onSuccess: (data) => {
            handleCreateTemplate(data).then((templateId) => {
              setTimeout(() => {
                drawer.close();
                window.open(`/templates/${templateId}`);
              }, 500)
            });
          },
        });
      }, 300);
    }
  }

  if (!activeIndicatorList.length) {
    if (readOnly) {
      return (
        <VStack maxWidth="md" py={4}>
          <Typography variant="h5">設定されていません</Typography>
          <Button
            variant="contained"
            color="logo"
            disableElevation
            to="/templates"
            component={Link}
            sx={{ borderRadius: 10, fontWeight: "bold", width: "50%", minWidth: "fit-content" }}
          >
            テンプレートを確認
          </Button>
        </VStack>
      );
    }
    return (
      <VStack maxWidth="md" py={4}>
        <Typography variant="h5">まずはサイトの目標（KGI）を設定しましょう。</Typography>
        <IndicatorCard
          isInitial
          item={{ id: "ghost", parentIndicatorId: "root" }}
          {...{ isTemplate }}
        />
        {!isTemplate && <>
          <Typography variant="h5">または</Typography>
          <Button
            variant="contained"
            color="logo"
            disableElevation
            component={Link}
            to={`/templates?container=${container.id}`}
            target="_blank"
            sx={{ borderRadius: 10, fontWeight: "bold", width: "50%", minWidth: "fit-content" }}
          >
            テンプレートから複製
          </Button>
        </>}
      </VStack>
    );
  }

  return (
    <>
      <Stack ref={ref} spacing={8} p={6} pb={12} sx={{ overflowX: "scroll", overflowY: "clip" }}>
        {nestedIndicatorList.map((item, index) => (
          <IndicatorCard
            key={item.id + index}
            list={activeIndicatorList}
            {...props}
            {...{
              item, index,
              sortOrderDiff, handleSort,
              warpParentIndicatorId, handleWarp,
              onDeleteClick,
            }}
            prevSibling={nestedIndicatorList[index - 1]}
            nextSibling={nestedIndicatorList[index + 1]}
          />
        ))}

        {((ghostParentIndicatorId === "root") && !isUnderSubmission) && (
          <IndicatorCard
            {...props}
            item={{ id: "ghost", parentIndicatorId: "root" }}
            prevSibling={nestedIndicatorList[nestedIndicatorList.length - 1]}
          />
        )}
      </Stack>
      {(!readOnly && !isTemplate) && (
        <Stack spacing={1} sx={{ position: "fixed", bottom: "2rem", right: "2rem", zIndex: 1000 }}>
          <Button
            variant="contained"
            color="logo"
            size="small"
            disableElevation
            onClick={handleTemplateClick}
            sx={{ borderRadius: 10, px: 2, py: 0.7 }}
          >
            テンプレートを作成
          </Button>
          <Button
            variant="outlined"
            color="logo"
            size="small"
            disableElevation
            to={`/templates?container=${container.id}`}
            target="_blank"
            component={Link}
            sx={{ borderRadius: 10, px: 2, py: 0.7, bgcolor: "white.main" }}
          >
            テンプレートを確認
          </Button>
        </Stack>
      )}
    </>
  );
}
