import React from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default (props) => {
  const {
    isOpen, onClose, operation, description, actions = [{ label: "OK", autoFocus: true }], cancelable = false, isLoading = false,
    disableClose = isLoading, disableOptionalClose, maxWidth = "sm", fullWidth = !isLoading, sx = {}, children, Content, actionComponent,
    title = getDefaultTitle(),
    ...otherProps
  } = props;

  function getDefaultTitle() {
    if (!operation) { return undefined; }
    if (isLoading) { return `${operation}しています・・・`; }
    if (actions) { return `${operation}しますか？`; }
    return undefined;
  }

  function handleClose(_, reason) {
    if (disableClose) { return; }
    if ((disableOptionalClose) && reason && (reason !== "timeout")) { return; }
    onClose();
  }

  const allActions = actions ? [...actions] : null;
  if (cancelable) { allActions.unshift({ label: "キャンセル" }); }

  if (!title) {
    sx.backgroundColor = "transparent";
    sx.boxShadow = "none";
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{ zIndex: isLoading ? 9999 : undefined }}
      slotProps={{ backdrop: { sx: { opacity: isLoading ? "0.2 !important" : "inherit" } } }}
      PaperProps={{ sx: { p: 1.5, ...sx } }}
      {...{ maxWidth, fullWidth }}
      keepMounted
      {...otherProps}
    >
      {children || <>
        {!!title && (
          <DialogTitle variant="h4">
            {title}
          </DialogTitle>
        )}
        {isLoading ? <CircularProgress sx={{ alignSelf: "center", my: 2 }} /> : <>
          <DialogContent>
            {Content || <DialogContentText sx={{ whiteSpace: "pre-line" }}>{description}</DialogContentText>}
          </DialogContent>
          {(!!actionComponent || !!actions) && (
            <DialogActions>
              {actionComponent || allActions.map(({ onClick, label, ...otherProps }, i) => (
                <Button
                  key={i}
                  onClick={() => {
                    if (onClick) { onClick(); }
                    handleClose();
                  }}
                  {...otherProps}
                >
                  {label}
                </Button>
              ))}
            </DialogActions>
          )}
        </>}
      </>}
    </Dialog>
  );
}
