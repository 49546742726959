import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import Drawer from "../containers/Drawer";
import Firestore from "../containers/Firestore";

import ContainerDrawerContent from "../components/ContainerDrawerContent";

import VStack from "../elements/VStack";

const title = "コンテナを新規作成", supportId = "container-new";

export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const drawer = Drawer.useContainer();
  const firestore = Firestore.useContainer();
  const { dataList: containerList = [], loading } = firestore.useMyContainerCol();

  useEffect(() => {
    if (loading) { return; }
    const containerIndex = searchParams.get("to");
    if (containerIndex) {
      const containerId = containerList[containerIndex]?.id;
      if (containerId) {
        navigate(`./${containerId}${location.hash}`);
      } else if (location.hash === "#subscription") {
        navigate("/plans");
      }
    }
  }, [loading, containerList, searchParams]);
  
  function handleCreate() {
    drawer.open({
      title,
      supportId,
      btnLabel: "作成",
      ContentComponent: ContainerDrawerContent,
      onSuccess: (data) => {
        firestore.addContainer(data)
        .then((containerId) => {
          if (!containerId) { return; }
          setTimeout(() => {
            drawer.close();
            navigate(`./${containerId}#kpitree`);
          }, 300);
        });  
      },
    });
  }

  return (
    <VStack width="96%" maxWidth="lg" py={3}>
      <Button variant="contained" color="primary" onClick={handleCreate}>
        {title}
      </Button>

      <TableContainer variant="outlined" component={Paper} sx={{ width: "100%" }}>
        <Table>
          <TableHead sx={{ "& th": { fontSize: "small", color: "gray", padding: "8px 16px" } }}>
            <TableRow>
              <TableCell>コンテナ名</TableCell>
              <TableCell>対象サイト</TableCell>
              <TableCell>コンテナID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {containerList.map((container) => (
              <TableRow
                key={container.id}
                onClick={() => navigate(`./${container.id}`)}
                sx={{ cursor: "pointer", "&:hover": { bgcolor: "background.focused" } }}
              >
                <TableCell sx={{ fontWeight: "bold" }}>{container.name}</TableCell>
                <TableCell>{container.defaultUrl}</TableCell>
                <TableCell>{container.gtmPublicId?.replace("GTM-", "")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </VStack>
  );
}
