import React, { useMemo }  from "react";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

import ChipWithIcon from "./ChipWithIcon";

export default (props) => {
  const { item, ...restProps } = props;
  const { winningVariantId = "", variantList = [] } = item;
  const winningVariant = useMemo(() => (
    (!!winningVariantId && !["draw", "unknown"].includes(winningVariantId)) ? variantList.find((x) => x.id === winningVariantId) : null
  ), [winningVariantId, variantList]);

  return (
    <ChipWithIcon
      type={winningVariantId}
      label={winningVariant?.name}
      defaultColor={!!winningVariant ? "logo" : undefined}
      DefaultIcon={SentimentSatisfiedAltIcon}
      variant="filled"
      {...restProps}
    />
  );
};
