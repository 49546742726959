import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

import Analytics from "../containers/Analytics";
import MultiInputElement from "../elements/MultiInputElement";

export default (props) => {
  const { reset, watch, setError, clearErrors } = useFormContext();
  const analytics = Analytics.useContainer();
  const { defaultValue, container, experience } = props;
  const [eventNameArr, setEventNameArr] = useState([]);

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    setupEventNameArr();
    return () => reset({});
  }, []);

  useEffect(() => {
    const subscription = watch(({ goalEventNameArr = [] }) => {
      if (goalEventNameArr.length > 4) {
        setError("goalEventNameArr", {
          type: "manual",
          message: "設定できる目標イベントは4つまでです",
        });
      } else {
        clearErrors("goalEventNameArr");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  async function setupEventNameArr() {
    setEventNameArr(await analytics.getEventNameArr(container, experience));
  }

  return (
    <Stack spacing={4} p={4}>
      <MultiInputElement
        name="goalEventNameArr"
        label="目標イベント"
        options={eventNameArr}
        maxNum={4}
        freeSolo
      />
    </Stack>
  );
}
