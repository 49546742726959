import React  from "react";
import { IconButton, Tooltip } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

import CustomIcon from "./CustomIcon";
import ja from "../utils/ja.json";

export default (props) => {
  const {
    type, isReverse, isLocked, requiredRole = "editor", lockedReason = `コンテナの${ja[requiredRole]}権限が必要です`,
    color = (type === "edit") ? "primary" : "default", ...restProps
  } = props;

  if (isLocked) {
    return (
      <Tooltip title={lockedReason}>
        <LockIcon color="gray" sx={{ cursor: "not-allowed" }} />
      </Tooltip>
    );
  }

  return (
    <IconButton
      {...{ color }}
      sx={{
        p: isReverse ? 0.8 : 0,
        bgcolor: isReverse ? "primary.main" : "transparent",
        "&:hover": { opacity: 0.75, bgcolor: isReverse ? "primary.main" : "transparent" },
        transition: "inherit",
      }}
      {...restProps}
    >
      <CustomIcon {...{ type }} color={isReverse ? "white" : "inherit"} />
    </IconButton>
  );
};
