import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

import CustomSelect from "../elements/CustomSelect";

export default (props) => {
  const { reset } = useFormContext();
  const { defaultValue, variantList = [] } = props;

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  return (
    <Stack spacing={4} p={4}>
      <CustomSelect
        name="winningVariantId"
        label="勝利したパターン"
        options={variantList.concat({ id: "draw" }, { id: "unknown" })}
        variant="chip"
        withIcon
        defaultColor="logo"
        DefaultIcon={SentimentSatisfiedAltIcon}
      />
    </Stack>
  );
}
