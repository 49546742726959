import React, { useMemo } from "react";
import { arrayRemove, deleteField, increment } from "firebase/firestore";
import { Stack, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Avatar, Chip } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PersonOffIcon from "@mui/icons-material/PersonOff";

import Firebase from "../containers/Firebase";
import AlertDialog from "../containers/AlertDialog";
import Drawer from "../containers/Drawer";

import UserDrawerContent from "./UserDrawerContent";
import RequestStatusAlert from "./RequestStatusAlert";
import MenuButton from "../elements/MenuButton";
import ChipWithIcon from "../elements/ChipWithIcon";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";
import ja from "../utils/ja.json";

const title = "メンバー管理";

export default (props) => {
  const { currentUser } = Firebase.useContainer();
  const alertDialog = AlertDialog.useContainer();
  const drawer = Drawer.useContainer();
  const { container = {}, requestList = [], readOnly, currentPlan, checkIsUpgradeRequired, handleUpdate, handleAddRequest, handleConfirmRequest } = props;
  const { userIdArr = [], invitedEmailArr = [], userRoleObj = {}, userObj = {} } = container;

  const userRequestList = useMemo(() => requestList.filter(({ action }) => action === "addUser"), [requestList]);

  const userList = useMemo(() => {
    const list = userIdArr.map((id) => ({
      id, role: userRoleObj[id], ...(userObj[id] || {}) }));
    const invitedList = invitedEmailArr.map((email) => {
      const id = email.replaceAll(".", ":");
      return { id, email, role: userRoleObj[id], isInvited: true };
    });
    return list.concat(invitedList);
  }, [userIdArr, invitedEmailArr, userRoleObj]);

  function handleAddClick() {
    drawer.open({
      title: "メンバーを招待",
      btnLabel: "招待",
      defaultValue: { role: (currentPlan === "free") ? "viewer" : "admin" },
      ContentComponent: UserDrawerContent,
      contentComponentProps: { userList },
      onSuccess: (userInfo) => {
        if (checkIsUpgradeRequired("addUser", userInfo)) { return; }
        handleAddRequest({ action: "addUser", userInfo, status: "processing", isActive: true })
          .then(() => setTimeout(drawer.close, 500));
        },
    });
  }

  function handleEditClick(user) {
    const label = "メンバーの権限";
    drawer.open({
      title: `${label}を変更`,
      btnLabel: "更新",
      defaultValue: { email: user.email, role: user.role },
      contentComponentProps: { isOwnerSelectable: !user.isInvited },
      ContentComponent: UserDrawerContent,
      onSuccess: (userInfo) => {
        if (checkIsUpgradeRequired("updateUserRole", { ...userInfo, currentRole: user.role })) { return; }
        const currentOwner = userList.find((x) => x.role === "owner");
        if ((userInfo.role !== "owner") || !currentOwner) {
          handleUpdate({ [`userRoleObj.${user.id}`]: userInfo.role }, `${label}を更新`)
            .then(() => setTimeout(drawer.close, 500));
        } else {
          const currentOwnerLabel = (currentOwner.id === currentUser.uid) ? "あなた" : `${currentOwner.displayName}（${currentOwner.email}）`;
          const newRoleOfCurrentOwner = (currentPlan === "free") ? "viewer" : "admin";
          const label = "オーナーを変更";
          alertDialog.open({
            title: `${label}しますか？`,
            description: `${currentOwnerLabel}はオーナー権限を失い、${ja[newRoleOfCurrentOwner]}権限に変更されます。`,
            cancelable: true,
            actions: [{
              label,
              color: "error",
              onClick: () => {
                handleUpdate({
                  [`userRoleObj.${user.id}`]: "owner",
                  [`userRoleObj.${currentOwner.id}`]: newRoleOfCurrentOwner,
                }, label)
                  .then(() => setTimeout(drawer.close, 500));
              },
            }],
          });
        }
      },
    });
  }

  function handleRemoveClick(user) {
    let label = "削除";
    let operation = "メンバーを削除";
    let title = "メンバーをコンテナから削除しますか？";
    let description = `${user.displayName}（${user.email}）はこのコンテナにアクセスできなくなります。`;
    let data = {
      userIdArr: arrayRemove(user.id),
      [`userRoleObj.${user.id}`]: deleteField(),
      "statsInfo.userCount": increment(-1),
    };
    if (user.id === currentUser.uid) {
      title = "自身をコンテナから削除しますか？";
      description = "あなたはこのコンテナにアクセスできなくなります。";
    }
    if (user.isInvited) {
      label = "招待を取消";
      operation = "招待を削除";
      title = "招待を取り消しますか？";
      description = `${user.email}に送信された招待は無効になります。`;
      data = {
        invitedEmailArr: arrayRemove(user.email),
        [`userRoleObj.${user.id}`]: deleteField(),
      };
    }
    alertDialog.open({
      title,
      description,
      cancelable: true,
      actions: [{
        label,
        color: "error",
        onClick: () => handleUpdate(data, operation),
      }],
    });
  }

  return (
    <BuilderCard
      {...{ title }}
      Icon={PeopleIcon}
      color="secondary"
      action={<ActionButton type="add" color="primary" onClick={handleAddClick} isLocked={readOnly} requiredRole="admin" />}
      renderContent={() => (
        <Stack spacing={2}>
          {!!userRequestList.length && userRequestList.map((request) => (
            <RequestStatusAlert
              key={request.id}
              request={request}
              handleConfirm={() => handleConfirmRequest(request.id)}
            />
          ))}
          <TableContainer variant="outlined" component={Paper} sx={{ width: "100%" }}>
            <Table>
              <TableHead sx={{ "& th": { fontSize: "small", color: "gray", padding: "8px 16px" } }}>
                <TableRow>
                  <TableCell>名前</TableCell>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell>権限</TableCell>
                  <TableCell sx={{ width: 0 }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {userList.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell sx={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={user.photoURL}
                        alt={user.displayName}
                        children={!user.isInvited ? undefined : <PersonOffIcon />}
                        sx={{ width: 30, height: 30, mr: 2 }}
                      />
                      {!user.isInvited ? user.displayName : <Chip label="未登録のメンバー" />}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell><ChipWithIcon type={user.role} /></TableCell>
                    <TableCell>
                      {(!readOnly && (user.role !== "owner")) && (
                        <MenuButton
                          items={[
                            {
                              label: "権限を変更",
                              Icon: EditIcon,
                              color: "primary",
                              onClick: () => handleEditClick(user),
                            },
                            {
                              label: !user.isInvited ? "削除" : "招待を取消",
                              Icon: RemoveCircleIcon,
                              color: "error",
                              onClick: () => handleRemoveClick(user),
                            },
                          ]}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    />
  );
}
