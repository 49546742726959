import React, { useEffect } from "react";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Alert, Divider, FormLabel, Stack, Switch } from "@mui/material";

import Firebase from "../containers/Firebase";

import CustomSelect from "../elements/CustomSelect";
import TemplateCard from "./TemplateCard";

export default (props) => {
  const { defaultValue } = props;
  const { reset, watch, setValue, unregister } = useFormContext();
  const { currentUser: { displayName, photoURL } } = Firebase.useContainer();

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  useEffect(() => {
    const subscription = watch(({ accessMode, creator }) => {
      if (accessMode === "public") {
        if (!creator) {
          setValue("creator", { displayName, photoURL });
          setTimeout(() => {
            const displayNameInput = document.querySelector("input[name='creator.displayName']");
            displayNameInput?.scrollIntoView({ behavior: "smooth", block: "center" });
          }, 100)
        }
      } else if (creator) {
        unregister("creator");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Stack spacing={2} p={4} pb={8}>
      <TemplateCard
        item={watch()}
        sx={{ maxWidth: "25rem" }}
      />
      <Divider sx={{ mb: "0.5rem !important" }} />
      <TextFieldElement
        name="name"
        label="テンプレート名"
        required
        fullWidth
        autoFocus
        autoComplete="off"
      />
      <TextFieldElement
        name="description"
        label="概要"
        multiline
        minRows={5}
        fullWidth
        autoComplete="off"
      />
      <Stack direction="row" alignItems="center">
        <Switch
          checked={!!watch("hypothesisList")?.length}
          onChange={(e) => setValue("hypothesisList", e.target.checked ? defaultValue?.hypothesisList : [])}
        />
        <FormLabel>仮説を含める</FormLabel>
      </Stack>
      <CustomSelect
        name="accessMode"
        label="公開設定"
        required
        withIcon
        options={[
          { id: "public", description: "全ユーザーが閲覧可能な「コミュニティテンプレート」として公開されます" },
          { id: "limitedPublic", description: "リンクを知っているユーザーだけが閲覧できます" },
          { id: "private", description: "あなただけが閲覧できます" },
        ]}
      />
      {(watch("accessMode") === "public") && (
        <>
          <Alert severity="warning">
            機密情報を公開しないようご注意ください
          </Alert>
          <TextFieldElement
            name="creator.displayName"
            label="ユーザー名"
            required
          />
          <Stack direction="row" alignItems="center">
            <Switch
              checked={!!watch("creator.photoURL")}
              onChange={(e) => setValue("creator.photoURL", e.target.checked ? photoURL : null)}
            />
            <FormLabel>プロフィール画像を表示</FormLabel>
          </Stack>
        </>
      )}
    </Stack>
  );
}
