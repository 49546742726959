import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form-mui";
import { Alert, Stack } from "@mui/material";
import CalendarPicker from "../elements/CalendarPicker";
import ActionButton from "../elements/ActionButton";

export default (props) => {
  const { defaultValue, hasStarted } = props;
  const { setValue, reset, watch, setError, clearErrors, formState, trigger } = useFormContext();
  const { errors } = formState;

  const [currentScheduledStartAt, setCurrentScheduledStartAt] = useState(defaultValue?.scheduledStartAt);
  const [currentScheduledEndAt, setCurrentScheduledEndAt] = useState(defaultValue?.scheduledEndAt);

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  useEffect(() => {
    const subscription = watch(({ scheduledStartAt, scheduledEndAt }) => {
      trigger();
      setCurrentScheduledStartAt(scheduledStartAt);
      setCurrentScheduledEndAt(scheduledEndAt);

      if (!hasStarted) {
        if (!scheduledStartAt) {
          clearErrors("scheduledStartAt");
        } else if (scheduledStartAt < new Date()) {
          setError("scheduledStartAt", { message: "過去の日時は設定できません" });
        } else if (scheduledEndAt && (scheduledStartAt >= scheduledEndAt)) {
          setError("scheduledStartAt", { message: "終了日時よりも前に設定してください" });
        } else {
          clearErrors("scheduledStartAt");
        }
      }

      if (!scheduledEndAt) {
        clearErrors("scheduledEndAt");
      } else if (scheduledEndAt < new Date()) {
        setError("scheduledEndAt", { message: "過去の日時は設定できません" });
      } else if (scheduledStartAt && (scheduledEndAt <= scheduledStartAt)) {
        setError("scheduledEndAt", { message: "開始日時よりも後に設定してください" });
      } else {
        clearErrors("scheduledEndAt");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Stack spacing={5} p={6}>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={3}>
          <CalendarPicker
            label="開始日時"
            value={currentScheduledStartAt}
            maxDateTime={currentScheduledEndAt}
            onChange={(value) => setValue("scheduledStartAt", value)}
            readOnly={hasStarted}
            disablePast={!hasStarted}
            // onError={(error) => error ? setError("scheduledStartAt", { message: error }) : clearErrors("scheduledStartAt")}
          />
          {(!!currentScheduledStartAt && !hasStarted) && (
            <ActionButton type="remove" onClick={() => setValue("scheduledStartAt", null)} />
          )}
        </Stack>
        {!!errors.scheduledStartAt?.message && (
          <Alert severity="error">{errors.scheduledStartAt.message}</Alert>
        )}
      </Stack>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" spacing={3}>
          <CalendarPicker
            label="終了日時"
            value={currentScheduledEndAt}
            minDateTime={currentScheduledStartAt}
            onChange={(value) => setValue("scheduledEndAt", value)}
            // onError={(error) => error ? setError("scheduledEndAt", { message: error }) : clearErrors("scheduledEndAt")}
          />
          {!!currentScheduledEndAt && (
            <ActionButton type="remove" onClick={() => setValue("scheduledEndAt", null)} />
          )}
        </Stack>
        {!!errors.scheduledEndAt?.message && (
          <Alert severity="error">{errors.scheduledEndAt.message}</Alert>
        )}
      </Stack>
    </Stack>
  );
}
