import React from "react";
import { Container, Stack, Typography } from "@mui/material";

import TermItem from "../elements/TermItem";

import policy from "../utils/policy.json";

export default () => {
  return (
    <>
      <Container maxWidth="md">
        <Stack spacing={5} py={6}>
          <Typography variant="h2" component="h1">プライバシーポリシー</Typography>
          {policy.map((item, i) => (
            <TermItem key={i} {...item} />
          ))}
        </Stack>
      </Container>
    </>
  )
}
