import React, { useEffect } from "react";
import { FormLabel, Stack } from "@mui/material";
import { AutocompleteElement, SwitchElement, useFormContext } from "react-hook-form-mui";
import CustomSelect from "../elements/CustomSelect";

export default (props) => {
  const { containerId, template = {}, containerList = [] } = props;
  const { reset, watch } = useFormContext();

  useEffect(() => {
    reset({
      containerId,
      includesHypotheses: !!template.hypothesisList?.length,
      type: "integrate",
    });
    return () => reset({});
  }, []);

  const includesHypotheses = watch("includesHypotheses");
  const typeDescriptionPrefix = `コンテナ内のKPI${includesHypotheses ? "と仮説" : ""}を`;

  return (
    <Stack spacing={4} p={4}>
      <AutocompleteElement
        name="containerId"
        label="コンテナ"
        options={containerList}
        matchId
        required
        autocompleteProps={{
          getOptionLabel: (option) => `${option.name}（${option.gtmPublicId}）`,
          noOptionsText: "該当するコンテナがありません",
          disableClearable: true,
          disablePortal: true,
          fullWidth: true,
          componentsProps: {
            popper: {
              sx: { transition: "none" },
            },
          },
        }}
      />
      
      {!!template.hypothesisList?.length && (
        <SwitchElement
          name="includesHypotheses"
          label={<FormLabel>仮説を含める</FormLabel>}
          sx={{ width: "fit-content" }}
        />
      )}

      <CustomSelect
        name="type"
        label="タイプ"
        required
        withIcon
        colored
        options={[
          { id: "integrate", description: typeDescriptionPrefix + "残したまま追加します" },
          { id: "overwrite", description: typeDescriptionPrefix + "すべて削除して上書きします" },
        ]}
      />
    </Stack>
  );
}
