import React, { useEffect } from "react";
import { Container, Stack } from "@mui/material";

import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import SubmitButton from "./SubmitButton";

export default (props) => {
  const { handleSend, sendSuccessCount } = props;
  const { handleSubmit, reset } = useFormContext();

  useEffect(() => {
    if (sendSuccessCount) { reset(); }
  }, [sendSuccessCount]);

  useEffect(() => {
    function handleKeyDown (e) {
      switch (e.key) {
        case "Enter":
          if (e.metaKey || e.ctrlKey) { handleSubmit(handleSend)(); e.preventDefault(); }
          break;
        default:
          break;
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Stack
      position="fixed" bottom={0} right={0} left={0}
      zIndex={2000} alignItems="center"
      borderTop={1} borderColor="divider" bgcolor="background.card"
    >
      <Container maxWidth="lg">
        <Stack spacing={0.5} pt={0.5} pb={2}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <SubmitButton label="送信" />
          </Stack>
          <TextFieldElement
            name="body"
            required
            multiline
            minRows={3}
            fullWidth
            autoComplete="off"
          />
        </Stack>
      </Container>
    </Stack>
  );
};
