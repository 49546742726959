import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { createContainer } from "unstated-next";
import { Snackbar, Alert } from "@mui/material";

import RootBox from "./RootBox";

import { getRandomId } from "../utils/random";

export default createContainer(() => {
  const { leftOffset } = RootBox.useContainer();
  const [items, setItems] = useState([]);
  const location = useLocation();
  const containerId = useMemo(() => location.pathname.split("/")[2], [location]);

  useEffect(() => {
    setItems((prev) => prev.filter((x) => x.isConsistent));
  }, [containerId]);

  function open(item, id = getRandomId()) {
    setTimeout(() => {
      setItems((prev) => {
        const index = prev.findIndex((item) => item.id === id);
        if (index > -1) {
          prev[index] = { id, ...item };
        } else {
          prev.push({ id, ...item });
        }
        return [...prev];
      });
    });
    return {
      close: () => close(id),
      update: (props) => update(id, props),
    };
  }

  function close(id) {
    setTimeout(() => {
      setItems((prev) => prev.filter((item) => item.id !== id));
    }, 300);
  }

  function update(id, props) {
    setItems((prev) => {
      const index = prev.findIndex((x) => x.id === id);
      if (index < 0) { return prev; }
      prev[index] = { ...prev[index], ...props };
      return [...prev];
    });
  }

  function getIsOpen(id) {
    return items.some((item) => item.id === id);
  }

  const Component = useMemo(() => (
    <>
      {items.map(({ id, isError = false, severity = isError ? "error" : "success", color, action, autoHideDuration = 5000, title, icon, operation }, i) => {
        function handleClose(_, reason) {
          if (reason && (reason !== "timeout")) { return; }
          close(id);
        }
        
        return (
          <Snackbar
            key={id}
            open
            onClose={handleClose}
            {...{ autoHideDuration }}
            sx={{ bottom: `${84 + 56*i}px !important`, left: `${leftOffset + 24}px !important` }}
          >
            <Alert
              onClose={handleClose}
              {...{ severity, color, action, icon }}
              variant="filled"
              sx={{ width: "100%", "& .MuiAlert-action": { pt: !!action ? 0 : undefined } }}
            >
              {title || `${operation}${isError ? "できませんでした" : "しました"}`}
            </Alert>
          </Snackbar>
        );
      })}
    </>
  ), [items, leftOffset]);

  return { open, close, update, getIsOpen, Component };
});
