import React, { useEffect } from "react";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

import CustomSelect from "../elements/CustomSelect";
import { getRandomItem } from "../utils/random";
import placehoders from "../utils/placeholders.json";

export default (props) => {
  const { defaultValue, indicatorList = [], isTemplate, delayAutoFocus, disableAutoFocus = delayAutoFocus } = props;
  const { reset, setFocus } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue);
    }
    if (delayAutoFocus) {
      setTimeout(() => setFocus("name"), 300);
    }
    return () => reset({});
  }, []);

  return (
    <Stack spacing={4} p={4}>
      <TextFieldElement
        name="name"
        label="仮説タイトル"
        helperText="KPIが抱える課題（現状数値と目標数値のギャップ）の要因として考えられる仮説"
        placeholder={getRandomItem(placehoders.hypothesis)}
        required
        autoFocus={!disableAutoFocus}
        autoComplete="off"
      />
      {!isTemplate && (
        <CustomSelect
          name="status"
          label="ステータス"
          variant="chip"
          required
          withIcon
          options={[
            { id: "unverified" },
            { id: "underVerification" },
            { id: "hasProven" },
            { id: "hasDisproven" },
          ]}
        />
      )}
      <Stack spacing={1}>
        <CustomSelect
          name="indicatorIdArr"
          label="関連するKPI"
          variant="chip"
          multiple
          options={indicatorList}
          noOptionsText="KPIが設定されていません"
        />
      </Stack>
      <TextFieldElement
        name="description"
        label="概要"
        multiline
        minRows={3}
        fullWidth
        autoComplete="off"
      />
    </Stack>
  );
}
