import React, { useMemo } from "react";
import ScienceIcon from "@mui/icons-material/Science";

import BuilderCard from "../elements/BuilderCard";
import { ExperienceCardContent } from "./ExperienceCard";

export default (props) => {
  const { experience = {}, experienceList = [] } = props;

  const prevExperienceList = useMemo(() => (
    experienceList.filter(({ nextExperienceIdArr = [] }) => nextExperienceIdArr.includes(experience.id))
  ), [experience.id, experienceList]);

  if (!prevExperienceList.length) { return null; }

  return (
    <BuilderCard
      title="展開元のエクスペリエンス"
      color="info"
      Icon={ScienceIcon}
      items={prevExperienceList}
      renderItem={(item) => (
        <ExperienceCardContent
          {...{ item }}
          {...props}
        />
      )}
    />
  );
}
