import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { createContainer } from "unstated-next";
import { FormContainer } from "react-hook-form-mui";
import { Drawer, Typography, Stack, ListItem, ListItemIcon, ListItemText, IconButton, Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import SubmitButton from "../elements/SubmitButton";
import SupportButton from "../elements/SupportButton";

export default createContainer(() => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [prevItem, setPrevItem] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    close();
  }, [location]);

  function open(item) {
    setCurrentItem(item);
    setPrevItem(item);
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
    setIsDirty(false);
    if (currentItem?.onClose) { currentItem.onClose(); }
    setTimeout(() => {
      setCurrentItem(null);
      setPrevItem(null);
    }, 300);
  }

  function update(props) {
    if (!isOpen || !currentItem) { return; }
    setCurrentItem((prev) => ({ ...prev, ...props }));
  }

  function revert() {
    setCurrentItem(prevItem);
  }

  function updateContentComponentProps(newProps) {
    setCurrentItem(({ contentComponentProps = {}, ...prev }) => (
      { ...prev, contentComponentProps: { ...contentComponentProps, ...newProps } }
    ));
  }

  const Component = useMemo(() => {
    const {
      ContentComponent, contentComponentProps = {}, defaultValue,
      isWide, chipLabel, title, supportId, btnLabel, onSuccess, readOnly,
      LeftIcon = CloseIcon, onLeftIconClick = handleClose,
    } = currentItem || {};

    function handleClose(_, reason) {
      if (reason && isDirty) { return; }
      close();
    }
  
    return (
      <Drawer
        open={isOpen}
        onClose={handleClose}
        anchor="right"
        PaperProps={{ sx: { width: isWide ? "max(50vw, 650px)" : "650px", maxWidth: "100vw", bgcolor: "background.default" } }}
      >
        <FormContainer onSuccess={!readOnly && onSuccess}>
          <ListItem divider component="div" sx={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "background.card", height: 56 }}>
            <ListItemIcon>
              <IconButton onClick={onLeftIconClick}>
                <LeftIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemText
              primary={(
                <Stack direction="row" alignItems="center" spacing={1}>
                  {!!chipLabel && <Chip label={chipLabel} variant="outlined" />}
                  <Typography variant="h4">{title}</Typography>
                  {!!supportId && <SupportButton supportId={supportId} />}
                </Stack>
              )}
            />
            {(!!btnLabel && !readOnly) && (
              <SubmitButton
                label={btnLabel}
                onIsDirtyChange={setIsDirty}
                {...{ defaultValue }}
              />
            )}
          </ListItem>

          {!!ContentComponent && (
            <ContentComponent
              {...{ defaultValue, readOnly }}
              {...contentComponentProps}
            />
          )}
        </FormContainer>
      </Drawer>
    );
  }, [currentItem, isOpen, isDirty]);

  return { open, close, update, revert, Component, isOpen, updateContentComponentProps };
});
