import React  from "react";
import { Stack, Typography, Tooltip } from "@mui/material";

export default (props) => {
  const {
    count, label, connector = "件の", title = count + connector + label,
    Icon, color = "action", fontSize = "small",
    ...restProps
  } = props;
  return (
    <Tooltip title={title} placement="bottom">
      <Stack direction="row" spacing={0.5} alignItems="center" {...restProps}>
        <Icon {...{ color, fontSize }} />
        <Typography variant="caption" fontWeight="bold">
          {count}
        </Typography>
      </Stack>
    </Tooltip>
  );
};
