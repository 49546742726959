import DeleteIcon from "@mui/icons-material/Delete";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";
import WebIcon from "@mui/icons-material/Web";
import CodeIcon from "@mui/icons-material/Code";

const types = [
  { value: "remove", label: "要素を削除", Icon: DeleteIcon },
  { value: "move", label: "要素を移動", Icon: SwapVertIcon },
  { value: "editStyle", label: "スタイルを変更", Icon: FormatColorFillIcon },
  { value: "editText", label: "テキストを変更", Icon: TextFieldsIcon },
  { value: "editSrc", label: "画像を変更", Icon: ImageIcon },
  { value: "editHref", label: "リンクを変更", Icon: LinkIcon },
  { value: "editHtml", label: "HTMLを変更", Icon: WebIcon },
  { value: "runScript", label: "JavaScriptを実行", Icon: CodeIcon },
];

export function getChangeTypeObj(type) {
  return types.find((x) => x.value === type) || {};
}
