import React, { useMemo } from "react";
import { Button, Stack } from "@mui/material";

import Drawer from "../containers/Drawer";
import Firebase from "../containers/Firebase";
import FirestoreContainer from "../containers/FirestoreContainer";

import VStack from "../elements/VStack";
import TopicCard from "../components/TopicCard";
import TopicDrawerContent from "../components/TopicDrawerContent";

const supportId = "";

export default (props) => {
  const { experienceList = [], topicList = [] } = props;
  const drawer = Drawer.useContainer();
  const { currentUser } = Firebase.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const { docData: container, currentUserRole, checkIsUpgradeRequired } = firestoreContainer;
  const readOnly = useMemo(() => (currentUserRole === "viewer"), [currentUserRole]);

  async function handleCreateOrEditClick(topic) {
    const { id, name, description, experienceIdArr = [] } = topic || {};
    const defaultValue = topic ? { name, description, experienceIdArr } : undefined;
    const btnLabel = topic ? "更新" : "作成";
    drawer.open({
      title: `お問合せトピックを${btnLabel}`,
      supportId,
      btnLabel,
      defaultValue,
      contentComponentProps: { experienceList },
      ContentComponent: TopicDrawerContent,
      onSuccess: topic ? onUpdate : onCreate,
    });

    async function onCreate(data) {
      if (checkIsUpgradeRequired("addTopic")) { return; }
      firestoreContainer.addTopic(data).then(() => setTimeout(drawer.close, 300));
    }

    async function onUpdate(data) {
      firestoreContainer.updateTopic(id, data).then(() => setTimeout(drawer.close, 300));
    }
  }

  return (
    <VStack width="96%" maxWidth="lg" py={3}>
      {!readOnly && (
        <Button onClick={() => handleCreateOrEditClick()} variant="contained" color="primary">
          お問合せトピックを作成
          {/* {topicList.length ? "お問合せトピックを追加" : "問合せを開始"} */}
        </Button>
      )}
      <Stack spacing={2} width="100%">
        {topicList.map((topic) => (
          <TopicCard
            key={topic.id}
            item={topic}
            {...{ container, experienceList, readOnly, currentUser }}
            onEditClick={() => handleCreateOrEditClick(topic)}
          />
        ))}
      </Stack>
    </VStack>
  );
}
