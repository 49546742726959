import { Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { createContainer } from "unstated-next";

export default createContainer(() => {
  const [leftOffset, setLeftOffset] = useState(0);

  const renderRootBox = useCallback((children) => {
    return (
      <Stack height="100dvh" overflow="auto" ml={`${leftOffset}px`}>
        {children}
      </Stack>
    );
  }, [leftOffset]);

  return { renderRootBox, leftOffset, setLeftOffset };
});
