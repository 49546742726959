import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

import CustomSelect from "../elements/CustomSelect";

export default (props) => {
  const { defaultValue } = props;
  const { reset } = useFormContext();

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  return (
    <Stack spacing={4} p={4}>
      <CustomSelect
        name="triggerIdArr"
        label="トリガー"
        multiple
        required
        options={[
          { id: "load", description: "ページが読み込まれた時に発火します" },
          { id: "domChange", description: "ページが読み込まれた後、コンテンツが変更される度に発火します" },
        ]}
      />
    </Stack>
  );
}
