import React, { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form-mui";
import { Card, CardActionArea, Checkbox, Chip, FormHelperText, FormLabel, Radio, Stack, Typography } from "@mui/material";
import FiberNewIcon from "@mui/icons-material/FiberNew";

import CustomIcon from "./CustomIcon";
import ja from "../utils/ja.json";
import colors from "../utils/colors.json";

export default (props) => {
  const { name, label, options = [], required, readOnly, multiple, variant = "card", withIcon, defaultColor, DefaultIcon, colored, noOptionsText, renderCardContent } = props;
  const { setValue, watch, setError, clearErrors, formState, trigger } = useFormContext();

  const currentValue = watch(name) || (multiple ? [] : "");

  useEffect(() => {
    if (readOnly) { return; }
    if (required) {
      if (multiple ? !currentValue.length : !currentValue) {
        setError(name, {
          type: "manual",
          message: "選択してください",
        });
      } else {
        clearErrors(name);
      }
      trigger(name);
    }
  }, [currentValue]);

  const handleChange = useCallback((id, isSelected) => {
    if (readOnly) { return; }
    if (multiple) {
      setValue(name, isSelected ? currentValue.filter((x) => x !== id) : [...currentValue, id]);
    } else {
      setValue(name, isSelected ? "" : id);
    }
  }, [currentValue]);

  return (
    <Stack spacing={1} pb={1}>
      {!!label && (
        <Stack direction="row" spacing={2} alignItems="center">
          <FormLabel
            {...{ required }}
            error={!!formState.errors[name]}
          >
            {label}
          </FormLabel>
          <FormHelperText error>
            {formState.errors[name]?.message}
          </FormHelperText>
        </Stack>
      )}
      {!options.length ? (
        <Typography variant="caption" color="error.main">
          {noOptionsText}
        </Typography>
      ) : (
        (variant === "chip") ? (
          <Card variant="outlined" sx={{ borderColor: "border.input" }}>
            <Stack p={2} direction="row" spacing={1} flexWrap="wrap" useFlexGap>
              {options.map((option) => {
                const { id, name = ja[id], color = colors[id] || defaultColor || "primary" } = option || {};
                if (!id) { return null; }
                const isSelected = multiple ? currentValue.includes(id) : (currentValue === id);
                return (
                  <Chip
                    key={id}
                    label={name}
                    color={isSelected ? color : undefined}
                    icon={withIcon && <CustomIcon type={id} {...{ DefaultIcon }} fontSize="small" />}
                    onClick={() => handleChange(id, isSelected)}
                    sx={{ borderRadius: 2 }}
                  />
                );
              })}
            </Stack>
          </Card>
        ) : (
          <Stack spacing={1}>
            {options.map((option) => {
              const { id, name = ja[id], description, isNew } = option || {};
              if (!id) { return null; }
              const isSelected = multiple ? currentValue.includes(id) : (currentValue === id);
              const Adornment = multiple ? Checkbox : Radio;
              return (
                <Stack
                  key={id}
                  direction="row"
                  spacing={1}
                  alignItems={!!renderCardContent ? "flex-start" : "center"}
                >
                  <Adornment
                    checked={isSelected}
                    onChange={() => handleChange(id, isSelected)}
                    disabled={readOnly}
                  />
                  <Card
                    variant="outlined"
                    sx={{
                      flex: 1,
                      backgroundColor: isSelected ? "background.focused" : "background.paper",
                      borderColor: isSelected ? "border.focused" : undefined,
                    }}
                  >
                    <CardActionArea
                      onClick={() => handleChange(id, isSelected)}
                      disabled={readOnly}
                      disableTouchRipple
                    >
                      {!!renderCardContent
                        ? renderCardContent(option)
                        : (
                          <Stack spacing={0.5} p={1.5}>
                            <Stack direction="row" spacing={1} alignItems="center">
                              {!!withIcon && (
                                <CustomIcon
                                  type={id}
                                  color={colored ? colors[id] : "darkGray"}
                                  fontSize="small"
                                />
                              )}
                              <Typography
                                variant="h5"
                                color={colored ? `${colors[id]}.main` : undefined}
                              >
                                {name}
                              </Typography>
                              {isNew && <FiberNewIcon color="logo" />}
                            </Stack>
                            {!!description && (
                              <Typography variant="caption">
                                {description}
                              </Typography>
                            )}
                          </Stack>
                        )
                      }
                    </CardActionArea>
                  </Card>
                </Stack>
              );
            })}
          </Stack>
        )
      )}
    </Stack>
  );
}
