import React, { useMemo }  from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

import * as time from "../utils/time";

export default (props) => {
  const { item, userObj = {} } = props;
  const { id, body = (id === "response") && responseBody, createdByUserId, createdAt } = item;
  const isSupport = useMemo(() => createdByUserId === "support", [createdByUserId]);
  const user = useMemo(() => isSupport ? supportUser : userObj[createdByUserId] || {}, [isSupport, userObj]);
  //const isMine = useMemo(() => createdByUserId === currentUser?.uid, [createdByUserId, currentUser?.uid]);

  return (
    <Stack id={item.id} direction="row" spacing={2}>
      <Avatar
        src={user.photoURL}
        alt={user.displayName}
        children={isSupport ? <SupportAgentIcon /> : undefined}
        sx={isSupport ? { bgcolor: "logo.main" } : undefined}
      />
      <Stack flex={1} spacing={1}>
        <Typography variant="h4" fontWeight="bold">{user.displayName}</Typography>
        <Typography variant="body4" whiteSpace="pre-wrap">{body}</Typography>
      </Stack>
      <Typography variant="caption">{time.toString(createdAt)}</Typography>
    </Stack>
  );
};

const responseBody = "お問合せを受け付けました。\n通常1営業日以内に返信いたしますので、しばらくお待ちください。";

const supportUser = {  displayName: "サポートスタッフ" };
