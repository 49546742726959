import ja from "./ja.json";

function getArr (type) {
  switch(type) {
    case "method":
      return ["equals", "includes", "startsWith", "endsWith", "match", "not:equals", "not:includes", "not:startsWith", "not:endsWith", "not:match"];
    case "methodCustom":
      return ["equals", "includes", "startsWith", "endsWith", "match", "not:equals", "not:includes", "not:startsWith", "not:endsWith", "not:match", "greaterThan", "greaterThanOrEquals", "lessThan", "lessThanOrEquals", "empty", "not:empty"];
    case "pageTarget":
      return ["canonicalUrl", "url", "pathname", "pathnameWithSearch", "hostname"];
    // case "changeType":
    //   return ["remove", "move", "editText", "editStyle", "insertHTML", "addCSS", "runJS"];
    default:
      return undefined;
  }
}

export default (type) => {
  const arr = getArr(type);
  if (!arr) { return undefined; }

  return arr.map((id) => ({
    id,
    label: ja[id],
  }));
}
