import React, { useMemo } from "react";
import { Button, Grid } from "@mui/material";

import AlertDialog from "../containers/AlertDialog";
import Drawer from "../containers/Drawer";
import Firestore from "../containers/Firestore";
import FirestoreContainer from "../containers/FirestoreContainer";

import VStack from "../elements/VStack";
import AssetCard from "../components/AssetCard";
import AssetDrawerContent from "../components/AssetDrawerContent";
import { getRandomId } from "../utils/random";
import { getAssetUrl } from "../utils/url";

const title = "画像をアップロード";

export default (props) => {
  const { experienceList = [], assetList = [] } = props;
  const alertDialog = AlertDialog.useContainer();
  const drawer = Drawer.useContainer();
  const firestore = Firestore.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const { docData: container, currentUserRole, checkIsUpgradeRequired } = firestoreContainer;
  const readOnly = useMemo(() => (currentUserRole === "viewer"), [currentUserRole]);

  async function handleCreateOrEditClick(asset) {
    const { id, name, description } = asset || {};
    const defaultValue = asset ? { name, description } : undefined;
    drawer.open({
      title: asset ? "画像の情報を更新" : title,
      btnLabel: asset ? "更新" : "アップロード",
      defaultValue,
      contentComponentProps: asset ? { assetUrl: getAssetUrl(asset) } : undefined,
      ContentComponent: AssetDrawerContent,
      onSuccess: asset ? onUpdate : onCreate,
    });

    async function onCreate({ file, ...data }) {
      if (checkIsUpgradeRequired("addAsset", { assetList })) { return; }
      const assetId = getRandomId(4);
      if (assetList.some((x) => x.id === assetId)) { return onCreate({ file, ...data }); }
      const extension = file.name.split(".").at(-1);
      const fileName = `${container.id.slice(0, 4)}${assetId}.${extension}`.toLowerCase();
      firestoreContainer.setAsset(assetId, { ...data, fileName, status: "processing" }).then(() => {
        firestore.uploadFile(fileName, file, () => {
          firestoreContainer.updateAsset(assetId, { status: "failed" });
        });
        setTimeout(drawer.close, 300);
      });
    }

    async function onUpdate(data) {
      firestoreContainer.updateAsset(id, data).then(() => setTimeout(drawer.close, 300));
    }
  }

  async function handleDeleteClick(asset) {
    const { id, name } = asset;
    alertDialog.open({
      title: `画像「${name}」を削除しますか？`,
      cancelable: true,
      actions: [{
        label: "削除",
        color: "error",
        onClick: () => firestoreContainer.deleteAsset(id),
      }],
    });
  }

  return (
    <VStack width="96%" maxWidth="xl" py={3}>
      {!readOnly && (
        <Button onClick={() => handleCreateOrEditClick()} variant="contained" color="primary">
          {title}
        </Button>
      )}
      <Grid container spacing={1} width="100%">
        {assetList.map((asset) => (
          <Grid key={asset.id} item xs={12} sm={6} lg={4}>
            <AssetCard
              item={asset}
              {...{ container, experienceList, readOnly }}
              onEditClick={() => handleCreateOrEditClick(asset)}
              onDeleteClick={() => handleDeleteClick(asset)}
            />
          </Grid>
        ))}
      </Grid>
    </VStack>
  );
}
