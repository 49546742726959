import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form-mui";
import { Card, Stack } from "@mui/material";

import HypothesisCard from "./HypothesisCard";
import CustomSelect from "../elements/CustomSelect";

export default (props) => {
  const { defaultValue, hypothesisIdArr = [], hypothesisList = [], ...restProps } = props;
  const { reset } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue);
    }
    return () => reset({});
  }, []);

  return (
    <Stack spacing={2} px={1.5} py={4}>
      {hypothesisIdArr.map((hypothesisId) => {
        const hypothesis = hypothesisList.find((x) => x.id === hypothesisId);
        if (!hypothesis) { return null; }
        return (
          <Card key={hypothesisId} variant="outlined">
            <Stack spacing={1.5} px={1.5} py={2}>
              <HypothesisCard
                item={hypothesis}
                readOnly
                isSlim
                isExperiencesHidden
                {...restProps}
              />
              <CustomSelect
                name={`hypothesisVerificationObj.${hypothesisId}`}
                label="検証結果"
                variant="chip"
                withIcon
                options={[
                  { id: "proven" },
                  { id: "disproven" },
                  { id: "unknown" },
                ]}
              />
            </Stack>
          </Card>
        );
      })}
    </Stack>
  );
}
