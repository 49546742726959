import React from "react";
import { Stack, Typography, Grid } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

import Drawer from "../containers/Drawer";

import ContainerDrawerContent from "./ContainerDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";
import ExternalLink from "../elements/ExternalLink";

const title = "コンテナの詳細";

export default (props) => {
  const drawer = Drawer.useContainer();
  const { container = {}, readOnly, handleUpdate } = props;
  const { name, defaultUrl, companyName } = container;

  function handleEditClick() {
    drawer.open({
      title,
      btnLabel: "更新",
      defaultValue: { name, defaultUrl, companyName },
      ContentComponent: ContainerDrawerContent,
      onSuccess: (data) => handleUpdate(data, `${title}を更新`).then(drawer.close),
    });
  }

  return (
    <BuilderCard
      {...{ title }}
      color="info"
      Icon={HomeIcon}
      action={<ActionButton type="edit" onClick={handleEditClick} isLocked={readOnly} requiredRole="admin" />}
      renderContent={() => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <Typography variant="caption">コンテナ名</Typography>
              <Typography variant="h5" component="p">{name}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <Typography variant="caption">対象サイト</Typography>
              <ExternalLink href={defaultUrl} />
            </Stack>
          </Grid>
        </Grid>
      )}
    />
  );
}
