import React, { useEffect } from "react";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { isEmail } from "validator";
import { Stack } from "@mui/material";

import CustomSelect from "../elements/CustomSelect";

export default (props) => {
  const { defaultValue, userList = [], isOwnerSelectable = false } = props;
  const { reset, watch, setError, clearErrors } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue);
    }
    return () => reset({});
  }, []);

  useEffect(() => {
    const subscription = watch(({ email }) => {
      if (email) {
        if (!isEmail(email)) {
          setError("email", {
            type: "manual",
            message: "有効なメールアドレスを入力してください",
          });
        } else if (userList.some((x) => x.email === email)) {
          setError("email", {
            type: "manual",
            message: "このメールアドレスは既に招待されています",
          });
        } else {
          clearErrors("email");
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Stack spacing={6} p={6}>
      <TextFieldElement
        name="email"
        label="メールアドレス"
        required
        autoFocus={!defaultValue?.email}
        InputProps={{ readOnly: !!defaultValue?.email }}
        inputProps={{ type: "email" }}
      />
      <CustomSelect
        name="role"
        label="権限"
        required
        withIcon
        colored
        options={[
          isOwnerSelectable ? { id: "owner", description: "コンテナの所有者です（管理権限を含む）" } : null,
          { id: "admin", description: "コンテナの設定や契約プランを変更できます（公開権限を含む）" },
          { id: "publisher", description: "エクスペリエンスを公開できます（編集権限を含む）" },
          { id: "editor", description: "エクスペリエンスや仮説、KPIツリーなどを編集できます（閲覧権限を含む）" },
          { id: "viewer", description: "エクスペリエンスや仮説、KPIツリーなどを閲覧できます" }
        ]}
      />
    </Stack>
  );
}
