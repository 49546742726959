import React, { useState, useMemo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, Alert, AlertTitle, ListItemButton, ListItemText, Button, Tooltip } from "@mui/material";  
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LockIcon from "@mui/icons-material/Lock";

import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";

import Header from "../containers/Header";
import Firebase from "../containers/Firebase";
import FirestoreContainer from "../containers/FirestoreContainer";

import LoadingStack from "../elements/LoadingStack";
import VStack from "../elements/VStack";

import * as time from "../utils/time";
import ja from "../utils/ja.json";
import config from "../env.json";
import PlanCardList from "../components/PlanCardList";

const stripePromise = loadStripe(config.stripe.publishableKey);

export default () => {
  const header = Header.useContainer();
  const { isSuperUser } = Firebase.useContainer();
  const firestoreContainer = FirestoreContainer.useContainer();
  const { stripeDocData: stripe = {}, currentUserRole, currentPlan, billableUserCount } = firestoreContainer;
  const { customerId, subscriptionId, status, plan, nextPlan, endAt, updatedAt } = stripe;
  
  const readOnly = useMemo(() => !["owner", "admin"].includes(currentUserRole), [currentUserRole]);
  const isSpecial = useMemo(() => plan === "special", [plan]);
  
  const [searchParams] = useSearchParams();
  const defaultPlan = searchParams.get("plan");

  const [planToCheckout, setPlanToCheckout] = useState(null);
  const [checkoutSessionObj, setCheckoutSessionObj] = useState({});
  const [isCaptionShown, setIsCaptionShown] = useState(false);
  const [hasDefaultPlanSelected, setHasDefaultPlanSelected] = useState(false);

  useEffect(() => {
    if (isSpecial) {
      header.setAlert({
        severity: "info",
        children: <>
          <AlertTitle>特別なオファーが適用されました！</AlertTitle>
          このコンテナではプレミアムプランを無料でご利用いただけます。
        </>,
      });
    } else if (!subscriptionId) {
      // header.setAlert({
      //   severity: "info",
      //   children: <>
      //     <AlertTitle>【期間限定】初月50%OFFキャンペーン実施中！</AlertTitle>
      //     2024年6月30日までのお申込みで、1ヶ月目のサブスクリプション料金が50%OFFになります。
      //   </>,
      // });
    } else if (status !== "active") {
      header.setAlert({
        children: <>
          <AlertTitle>決済エラーが発生しています。</AlertTitle>
          ステータス： {status}
        </>,
      });
    } else if (!!nextPlan) {
      header.setAlert({
        severity: "warning",
        children: <>
          <AlertTitle>現在のサブスクリプションは{(nextPlan === "free") ? "解約" : "ダウングレード"}済みです。</AlertTitle>
          {ja[currentPlan]}の有効期限： {time.toString(endAt)}
        </>,
      });
    } else {
      header.setAlert(null);
    }
    return () => header.setAlert(null);
  }, [currentPlan, updatedAt]);

  useEffect(() => {
    if (customerId && !subscriptionId && defaultPlan && !hasDefaultPlanSelected) {
      handlePlanSelect(defaultPlan, true);
      setHasDefaultPlanSelected(true);
    }
  }, [customerId, subscriptionId, defaultPlan]);

  if (!customerId) { return null; }

  function handlePlanSelect(plan, disableScroll = false) {
    if (readOnly) { return; }

    if (subscriptionId) {
      if (plan === "free") {
        openPortalSession({ configKey: "subscriptionCancel" });
      } else if ((nextPlan === "free") && (currentPlan === plan)) {
        openPortalSession({ configKey: "subscriptionRestore" });
      } else {
        openPortalSession({ plan });
      }
      setTimeout(() => setIsCaptionShown(true), 1000);
      return;
    }

    setTimeout(scrollToCheckoutForm, 300);
    if (planToCheckout === plan) { return; }

    setPlanToCheckout(null);
    setTimeout(() => setPlanToCheckout(plan), 200);

    const checkoutSession = checkoutSessionObj[plan];
    if (checkoutSession?.clientSecret) {
      listenCheckoutSession(checkoutSession.id);
    } else {
      firestoreContainer.addSession({ type: "checkout", plan })
        .then((sessionId) => {
          if (sessionId) { listenCheckoutSession(sessionId, disableScroll); }
        });
    }
  }

  function listenCheckoutSession(sessionId, disableScroll) {
    firestoreContainer.listenSession(sessionId, (data) => {
      setCheckoutSessionObj((prev) => {
        if (!prev.clientSecret && data.clientSecret && !disableScroll) {
          setTimeout(scrollToCheckoutForm, 300);
        }
        return { ...prev, [data.plan]: data }
      });
    });
  }

  function openPortalSession(data) {
    firestoreContainer.addSession({ type: "portal", ...data })
      .then((sessionId) => {
        if (sessionId) {
          window.open(`${window.location.pathname}/sessions/${sessionId}`);
        }
      });
  }

  function scrollToCheckoutForm() {
    const checkoutForm = document.getElementById("checkout-form");
    if (checkoutForm) {
      checkoutForm.scrollIntoView({ behavior: "smooth" });
    }
  }

  function handleUpdateBySuperUser() {
    const newPlan = isSpecial ? "free" : "special";
    firestoreContainer.updateStripe({ plan: newPlan });
    firestoreContainer.update({ "statsInfo.plan": newPlan });
  }

  return (
    <VStack width="96%" maxWidth="xl" py={3}>
      {isCaptionShown && (
        <Alert severity="info">
          プラン変更の反映には数分ほどかかる場合があります。
        </Alert>
      )}

      {(isSuperUser && !subscriptionId) && (
        <Button
          onClick={handleUpdateBySuperUser}
          variant="contained"
          color={isSpecial ? "error" : "logo"}
        >
          特別なオファーを{isSpecial ? "解除" : "適用"}
        </Button>
      )}

      <PlanCardList {...{ handlePlanSelect, currentPlan, defaultPlan, stripe, isSpecial, billableUserCount, readOnly }}>
        <Tooltip title={readOnly ? "コンテナの管理権限が必要です" : null}>
          <Card variant="outlined" sx={{ width: "100%", maxWidth: "sm", cursor: readOnly ? "not-allowed" : undefined }}>
            {configList.map(({ key, label }, i) => (
              <ListItemButton
                key={i}
                onClick={() => openPortalSession({ configKey: key })}
                divider={i < configList.length - 1}
                disabled={readOnly}
              >
                <ListItemText primary={label} primaryTypographyProps={{ variant: "h5" }} />
                {readOnly ? <LockIcon color="gray" /> : <ChevronRightIcon color="gray" />}
              </ListItemButton>
            ))}
          </Card>
        </Tooltip>
        {!!planToCheckout && (
          <Card
            id="checkout-form"
            variant="outlined"
            sx={{ width: "100%", py: 3, bgcolor: "background.card", maxWidth: "1000px", borderRadius: "12px" }}
          >
            {!!checkoutSessionObj[planToCheckout]?.clientSecret ? (
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={{
                  clientSecret: checkoutSessionObj[planToCheckout].clientSecret,
                  onComplete: () => setIsCaptionShown(true),
                }}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            ) : (
              <LoadingStack label="フォームを準備しています" />
            )}
          </Card>
        )}
      </PlanCardList>
    </VStack>
  );
};

const configList = [
  {
    key: "customerUpdate",
    label: "請求先の名前とメールアドレスを編集",
  },
  {
    key: "paymentMethodUpdate",
    label: "お支払い方法を更新／追加",
  },
  {
    key: "invoiceHistory",
    label: "お支払い履歴を確認／領収書をダウンロード",
  },
];
