export default (allList = []) => {
  const list = [];
  function addToList(parent) {
    allList.forEach((x) => {
      if (x.parentIndicatorId === parent.id) {
        list.push(x);
        addToList(x);
      }
    });
  }
  addToList({ id: "root" });
  return list;
}
