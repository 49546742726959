import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { createContainer } from "unstated-next";

import Dialog from "../elements/Dialog";
import { getRandomId } from "../utils/random";

export default createContainer(() => {
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [activeItemIdArr, setActiveItemIdArr] = useState([]);

  useEffect(() => {
    setItems([]);
    setActiveItemIdArr([]);
    if (!location.pathname.match(/^\/(containers|templates|service)/)) {
      setTimeout(() => {
        setItems([]);
        setActiveItemIdArr([]);
      }, 100);
    }
  }, [location]);

  function open(item, id = getRandomId()) {
    setTimeout(() => {
      setActiveItemIdArr((prev) => prev.concat(id));
      setItems((prev) => {
        const index = prev.findIndex((x) => x.id === id);
        if (index > -1) {
          prev[index] = { id, ...item };
        } else {
          prev.push({ id, ...item });
        }
        return [...prev];
      });
    });
    return {
      isOpen: () => getIsOpen(id),
      close: () => close(id),
      update: (props) => update(id, props),
    };
  }

  function close(id) {
    setTimeout(() => {
      setActiveItemIdArr((prev) => prev.filter((x) => x !== id));
    }, 300);
    setTimeout(() => {
      setItems((prev) => prev.filter((x) => x.id !== id));
    }, 800);
  }

  function update(id, props) {
    setItems((prev) => {
      const index = prev.findIndex((x) => x.id === id);
      if (index < 0) { return prev; }
      prev[index] = { ...prev[index], ...props };
      return [...prev];
    });
  }

  function getIsOpen(id) {
    return activeItemIdArr.includes(id);
  }

  const Component = useMemo(() => (
    <>
      {items.map(({ id, ...item }, i, arr) => (
        <Dialog
          key={id}
          isOpen={getIsOpen(id) && (!item.isLoading || (arr.findIndex((x) => x.isLoading) === i))}
          onClose={() => close(id)}
          {...item}
        />
      ))}
    </>
  ), [items, activeItemIdArr]);

  return { open, close, update, getIsOpen, Component };
});
