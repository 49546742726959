import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form-mui";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export default (props) => {
  const { name, label, options = [], required, maxNum, freeSolo, ...restProps } = props;
  const { setValue, getValues, trigger, setError, clearErrors, watch } = useFormContext();

  const [chipValueArr, setChipValueArr] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setChipValueArr(getValues(name) || []);
    });
  }, []);

  useEffect(() => {
    const subscription = watch(({ [name]: valueArr }) => {
      if (required) {
        if (!valueArr?.length) {
          setError(name, {
            type: "manual",
            message: "必須項目です",
          });
        } else {
          clearErrors(name);
        }
      }
    });
    return () => {
      clearErrors(name);
      subscription.unsubscribe();
    }
  }, [watch]);

  useEffect(() => {
    const valueArr = [...chipValueArr];
    if (inputValue && !valueArr.includes(inputValue)) { valueArr.push(inputValue); }
    setValue(name, valueArr);
    setTimeout(() => { trigger(name); });
  }, [chipValueArr, inputValue]);

  const isMaxNum = !!maxNum && (chipValueArr.length >= maxNum);

  return (
    <Autocomplete
      value={chipValueArr}
      inputValue={inputValue}
      options={isMaxNum ? [] : options}
      onChange={(_, v) => setChipValueArr(v)}
      onInputChange={isMaxNum ? null : (_, v) => setInputValue(v)}
      freeSolo={!options.length || freeSolo}
      multiple
      disableClearable
      disableCloseOnSelect
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={chipValueArr.length ? (isMaxNum ? "上限数に達しました。" : "または...") : (options.length ? "選択..." : "入力...")}
          inputProps={{ ...params.inputProps, style: isMaxNum ? { caretColor: "transparent" } : undefined }}
          autoComplete="off"
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      componentsProps={{
        popper: { sx: { transition: "none", visibility: !!options.length ? "visible" : "hidden" } },
        popupIndicator: { sx: { visibility: !!options.length ? "visible" : "hidden" } },
      }}
      sx={{ "& .MuiAutocomplete-input": { width: "100% !important" } }}
      {...restProps}
    />
  );
}
