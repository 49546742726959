import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CopyToClipboard from "react-copy-to-clipboard";

export default (props) => {
  const { value, color } = props;
  const [isTipOpen, setIsTipOpen] = useState(false);

  return (
    <CopyToClipboard
      text={value}
      onCopy={() => setIsTipOpen(true)}
    >
      <Tooltip
        open={isTipOpen}
        onClose={() => setIsTipOpen(false)}
        placement="top"
        title="コピーしました"
        leaveDelay={700}
      >
        <IconButton {...{ color }}>
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
};
