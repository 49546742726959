import React, { useEffect } from "react";
import { TextFieldElement, useFormContext } from "react-hook-form-mui";
import { Stack } from "@mui/material";

import { HypothesisCardContent } from "./HypothesisCard";
import CustomSelect from "../elements/CustomSelect";
import { getIsUrl } from "../utils/url";
import { getRandomItem } from "../utils/random";
import placeholders from "../utils/placeholders.json";

export default (props) => {
  const { defaultValue, experienceList = [], hypothesisList = [] } = props;
  const { watch, reset, setError, clearErrors } = useFormContext();

  useEffect(() => {
    if (defaultValue) { reset(defaultValue); }
    return () => reset({});
  }, []);

  useEffect(() => {
    const subscription = watch(({ name, editorUrl }) => {
      if (editorUrl) {
        if (!getIsUrl(editorUrl)) {
          setError("editorUrl", {
            type: "manual",
            message: "有効なURLを入力してください",
          });
        } else {
          clearErrors("editorUrl");
        }
      }

      if (experienceList.some((x) => x.name === name)) {
        setError("name", {
          type: "manual",
          message: "このエクスペリエンス名は既に使用されています",
        });
      } else {
        clearErrors("name");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Stack spacing={4} p={4}>
      <TextFieldElement
        name="name"
        label="エクスペリエンス名"
        required
        fullWidth
        autoFocus={!defaultValue?.hypothesisIdArr?.length}
        placeholder={getRandomItem(placeholders.experience)}
        autoComplete="off"
      />
      {!defaultValue?.name && (
        <>
          <TextFieldElement
            name="editorUrl"
            label="エディターページのURL"
            type="url"
            required
            fullWidth
            placeholder="https://example.com/lp"
          />
          <CustomSelect
            name="mode"
            label="モード"
            required
            withIcon
            options={[
              { id: "abtest", description: "オリジナルとテストパターンをランダムに配信して比較します" },
              { id: "rollout", description: "オリジナルへの変更を100%反映します", isNew: true },
            ]}
          />
          <CustomSelect
            name="type"
            label="タイプ"
            required
            withIcon
            options={[
              { id: "modify", description: "対象ページのコンテンツの一部を変更します" },
              { id: "redirect", description: "対象ページをそのまま別のページにリダイレクトさせます" },
            ]}
          />
          <CustomSelect
            name="hypothesisIdArr"
            label="検証対象の仮説"
            multiple
            options={hypothesisList}
            noOptionsText="仮説が作成されていません"
            renderCardContent={(item) => (
              <HypothesisCardContent
                item={item}
                isExperienceLinkDisabled
                isDense
                {...props}
                readOnly
              />
            )}
          />
        </>
      )}
    </Stack>
  );
}
