import React, { useMemo } from "react";
import { Stack, Alert } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";

import AlertDialog from "../containers/AlertDialog";

import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";
import RequestStatusAlert from "./RequestStatusAlert";

export default (props) => {
  const alertDialog = AlertDialog.useContainer();
  const { container = {}, requestList = [], readOnly, currentPlan, handleAddRequest, handleConfirmRequest } = props;

  const deleteRequestList = useMemo(() => requestList.filter(({ action }) => action === "deleteContainer"), [requestList]);

  function handleDelete() {
    if (currentPlan !== "free") {
      alertDialog.open({
        title: "有料プランをご契約中のコンテナは削除できません",
        description: "コンテナを削除する前に、有料プランを解約する必要があります。",
      });
      return;
    }

    alertDialog.open({
      title: "インストール用スニペットは削除されていますか？",
      description: "コンテナを削除する前に、必ずすべてのページからインストール用スニペットを削除してください。",
      cancelable: true,
      actions: [{
        label: "はい、削除済みです",
        color: "error",
        onClick: () => {
          alertDialog.open({
            title: `コンテナ「${container?.name}」を削除しますか？`,
            description: "コンテナに含まれるエクスペリエンスもすべて削除されます。\n復元することはできません。",
            cancelable: true,
            actions: [{
              label: "完全に削除",
              color: "error",
              onClick: () => {
                handleAddRequest({ action: "deleteContainer", status: "processing", isActive: true });
              },
            }],
          });
        },
      }],
    });
  }

  return (
    <BuilderCard
      title="コンテナを削除"
      Icon={ReportIcon}
      color="error"
      action={<ActionButton type="deleteForever" color="error" onClick={handleDelete} isLocked={readOnly} requiredRole="owner" />}
      renderContent={() => (
        <Stack spacing={2}>
          {!!deleteRequestList.length && deleteRequestList.map((request) => (
            <RequestStatusAlert
              key={request.id}
              request={request}
              handleConfirm={() => handleConfirmRequest(request.id)}
            />
          ))}
          <Alert severity="error">
            コンテナを削除する前に、必ずすべてのページからインストール用スニペットを削除してください。
          </Alert>
        </Stack>
      )}
    />
  );
}
