import React  from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import Firebase from "../containers/Firebase";

export default (props) => {
  const { sx = {} } = props;
  const { currentUser } = Firebase.useContainer();
  return (
    <Button
      className="cta"
      component={Link}
      to="/containers"
      variant="contained"
      size="large"
      disableElevation={!currentUser}
      sx={{ borderRadius: 0.7, px: 5, ...sx }}
    >
      {!!currentUser ? "管理画面へ" : "無料で利用する"}
    </Button>
  );
};
