import React from "react";
import { Stack, Chip } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";

import Drawer from "../containers/Drawer";
import TriggerDrawerContent from "./TriggerDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";

import ja from "../utils/ja.json";

const title = "トリガー", supportId = "experience-triggers";

export default (props) => {
  const { triggerIdArr = [], readOnly, handleUpdate } = props;
  const drawer = Drawer.useContainer();

  function handleEdit() {
    const defaultValue = { triggerIdArr };
    drawer.open({
      title,
      supportId,
      btnLabel: "更新",
      defaultValue,
      ContentComponent: TriggerDrawerContent,
      onSuccess: (data) => handleUpdate(data),
    });
  }

  return (
    <BuilderCard
      {...{ title, supportId }}
      color="gray"
      Icon={BoltIcon}
      renderItem={() => {
        return (
          <Stack direction="row" spacing={4} alignItems="center">
            <Stack spacing={1} flex={4} direction="row" alignItems="center">
              <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                {triggerIdArr?.map((id) => (
                  <Chip key={id} label={ja[id]} variant="outlined" />
                ))}
              </Stack>
            </Stack>

            {!readOnly && (
              <Stack flex={1} direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                <ActionButton type="edit" onClick={handleEdit} />
              </Stack>
            )}
          </Stack>
        );
      }}
    />
  );
}
