import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createContainer } from "unstated-next";
import { doc, collection, where, orderBy } from "firebase/firestore";

import Header from "./Header";
import AlertDialog from "./AlertDialog";
import Firestore from "./Firestore";
import Firebase from "./Firebase";

import PlanUpgradeButton from "../elements/PlanUpgradeButton";
import { getRandomId, getRandomNumber } from "../utils/random";

export default createContainer(({ containerId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const header = Header.useContainer();
  const alertDialog = AlertDialog.useContainer();
  const { currentUser, isSuperUser, sendEvent } = Firebase.useContainer();
  const { containerColRef, listenDocData, useDocData, useColData, addDocData, setDocData, updateDocData, deleteDocData } = Firestore.useContainer();

  const containerDocRef = doc(containerColRef, containerId);
  const experienceColRef = collection(containerDocRef, "experiences");
  const requestColRef = collection(containerDocRef, "requests");
  const hypothesisColRef = collection(containerDocRef, "hypotheses");
  const indicatorColRef = collection(containerDocRef, "indicators");
  const assetColRef = collection(containerDocRef, "assets");
  const topicColRef = collection(containerDocRef, "topics");
  const stripeDocRef = doc(containerDocRef, "secrets", "stripe");
  const sessionColRef = collection(stripeDocRef, "sessions");

  const { data: docData = {} } = useDocData(containerDocRef);

  const { data: stripeDocData = {} } = useDocData(stripeDocRef, true);

  const [isGa4InfoAlertDismissed, setIsGa4InfoAlertDismissed] = useState(false);

  const billableUserCount = useMemo(() => {
    const { userRoleObj = {} } = docData;
    return Object.values(userRoleObj).filter((x) => x !== "viewer").length;
  }, [docData.userRoleObj]);

  const currentUserRole = useMemo(() => {
    if (isSuperUser) { return "owner"; }
    const { userRoleObj = {} } = docData;
    return userRoleObj[currentUser?.uid];
  }, [currentUser, docData.userRoleObj]);

  const currentPlan = useMemo(() => {
    const { plan = "free", status } = stripeDocData;
    if (plan === "special") { return "premium"; }
    if (status !== "active") { return "free"; }
    return plan;
  }, [stripeDocData]);

  useEffect(() => {
    header.setCurrentUserRole(currentUserRole);
    return () => header.setCurrentUserRole(null);
  }, [currentUserRole]);

  useEffect(() => {
    header.setCurrentPlan(currentPlan);
    return () => header.setCurrentPlan(null);
  }, [currentPlan]);

  useEffect(() => {
    if (!!docData.ga4Info && !docData.ga4Info.propertyId && !alertDialog.getIsOpen("container-ga4Info") && (location.hash !== "#settings") && !isGa4InfoAlertDismissed) {
      alertDialog.open({
        title: "リンク先のGoogleアナリティクスを再設定してください。",
        description: "測定IDの手動入力は、β版のみの仕様です。\n正式版では、アクセス可能なプロパティの一覧から選択する必要があります。",
        actions: [
          { label: "あとで", color: "error", onClick: () => setIsGa4InfoAlertDismissed(true) },
          { label: "設定する", onClick: () => navigate(`/containers/${containerId}#settings`) },
        ],
        disableOptionalClose: true,
      }, "container-ga4Info");
    }
  }, [docData.ga4Info]);

  async function update(data, operation = "コンテナを更新") {
    return updateDocData(containerDocRef, data, operation);
  }

  function useExperienceCol() {
    return useColData(experienceColRef);
  }

  async function addExperience(data) {
    return addDocData(experienceColRef, data, "エクスペリエンスを作成")
      .then((ret) => {
        sendEvent("create_experience", data.type);
        return ret;
      });
  }

  async function addExperienceFromTemplate({ editorUrl = "", ...data }) {
    const isRollout = (data.mode === "rollout");
    return addExperience({
      variantList: isRollout ? undefined : [
        { id: "original", name: "オリジナル", ratio: "50" },
        { id: getRandomId(), name: "パターン1", ratio: "50" },
      ],
      pageList: [
        {
          id: getRandomId(),
          name: "ページ1",
          editorUrl,
          filterList: [{
            target: editorUrl.includes("?") ? "url" : "canonicalUrl",
            method: editorUrl.includes("?") ? "includes" : "equals",
            valueArr: [editorUrl],
          }],
        },
      ],
      triggerIdArr: ["load"],
      isReportActive: !isRollout,
      timestampKeyArr: ["productionUpdatedAt"],
      ...data,
      randomNumber: isRollout ? undefined : getRandomNumber(6),
    });
  }

  async function updateExperience(experienceId, data) {
    return updateDocData(doc(experienceColRef, experienceId), data);
  }

  async function deleteExperience(experienceId) {
    return deleteDocData(doc(experienceColRef, experienceId), "エクスペリエンスを削除");
  }

  function useRequestCol() {
    return useColData(requestColRef, where("isActive", "==", true));
  }

  async function addRequest(data, timeout) {
    return addDocData(requestColRef, data, "リクエストを送信", timeout);
  }

  async function updateRequest(requestId, data) {
    return updateDocData(doc(requestColRef, requestId), data);
  }

  function useHypothesisCol() {
    return useColData(hypothesisColRef);
  }

  async function addHypothesis(data) {
    return addDocData(hypothesisColRef, data, "仮説を作成");
  }

  async function updateHypothesis(hypothesisId, data, operation = "仮説を更新") {
    return updateDocData(doc(hypothesisColRef, hypothesisId), data, operation);
  }

  async function deleteHypothesis(hypothesisId) {
    return deleteDocData(doc(hypothesisColRef, hypothesisId), "仮説を削除");
  }

  function useIndicatorCol() {
    return useColData(indicatorColRef, orderBy("orderNum", "asc"));
  }

  async function addIndicator(data) {
    return addDocData(indicatorColRef, data, "KPIを作成");
  }

  async function updateIndicator(indicatorId, data) {
    return updateDocData(doc(indicatorColRef, indicatorId), data, "KPIを更新");
  }

  async function deleteIndicator(indicatorId) {
    return deleteDocData(doc(indicatorColRef, indicatorId), "KPIを削除");
  }

  function useAssetCol() {
    return useColData(assetColRef);
  }

  async function setAsset(assetId, data) {
    return setDocData(doc(assetColRef, assetId), data, "画像ファイルを作成");
  }

  async function updateAsset(assetId, data) {
    return updateDocData(doc(assetColRef, assetId), data, "画像ファイルを更新");
  }

  async function deleteAsset(assetId) {
    return deleteDocData(doc(assetColRef, assetId), "画像ファイルを削除");
  }

  function useTopicCol() {
    return useColData(topicColRef);
  }

  async function addTopic(data) {
    return addDocData(topicColRef, { ...data, userIdArr: [currentUser?.uid] }, "お問合せトピックを作成");
  }

  async function updateTopic(assetId, data) {
    return updateDocData(doc(topicColRef, assetId), data, "お問合せトピックを更新");
  }

  async function addSession(data) {
    return addDocData(sessionColRef, data);
  }

  async function listenSession(sessionId, callback) {
    return listenDocData(doc(sessionColRef, sessionId), callback);
  }

  async function updateStripe(data) {
    if (!isSuperUser) { return; }
    return updateDocData(stripeDocRef, data, "Stripe情報を更新");
  }

  function checkIsUpgradeRequired(action, props = {}) {
    switch (action) {
      case "addUser": {
        if (props.role === "viewer") { return false; }
        const alertTitleBase = "のメンバー（閲覧権限を除く）で利用";
        switch (currentPlan) {
          case "free":
            if (billableUserCount === 1) { //既に複数メンバーで利用中でない限り、新規招待はブロック
              return openAlert("複数" + alertTitleBase, "starter");
            }
            break;
          case "starter":
            if (billableUserCount >= 3) {
              return openAlert("4人以上" + alertTitleBase, "basic");
            }
            break;
          case "basic":
            if (billableUserCount >= 10) {
              return openAlert("11人以上" + alertTitleBase, "premium");
            }
            break;
          case "premium": default:
            return false;
        }
        if (!["owner", "admin"].includes(props.role)) {
          return openAlert("メンバーの権限をカスタマイズ", "premium");
        }
        return false;
      }
      case "updateUserRole": {
        if ((currentPlan === "premium") || (props.role === "viewer")) { return false; }
        if ((currentPlan === "free") && (props.role === "owner")) { return false; } //フリープランでのオーナーの変更は許可
        if (!["owner", "admin"].includes(props.role)) {
          return openAlert("メンバーの権限をカスタマイズ", "premium");
        }
        return checkIsUpgradeRequired("addUser", props);
      }
      case "addHypothesis": {
        if (currentPlan !== "free") { return false; }
        if (props.hypothesisList.length >= 5) {
          return openAlert("6つ以上の仮説を作成", "starter");
        }
        return false;
      }
      case "addAsset": {
        switch (currentPlan) {
          case "premium": return false;
          case "basic":
            if (props.assetList.length) {
              return openAlert("2つ以上の画像をアップロード", "premium");
            }
            return false;
          default: return openAlert("画像をアップロード", "basic");
        }
      }
      case "duplicateExperience": {
        if (currentPlan !== "free") { return false; }
        return openAlert("エクスペリエンスを複製", "starter");
      }
      case "updateVariantList": {
        if (currentPlan !== "free") { return false; }
        const ratioArr = props.variantList.map((x) => Number(x.ratio));
        if (Math.max(...ratioArr) - Math.min(...ratioArr) > 1) {
          return openAlert("パターンの比重をカスタマイズ", "starter");
        }
        return false;
      }
      case "addExperience": {
        if (currentPlan !== "free") { return false; }
        if (props.mode === "rollout") {
          return openAlert("「100%反映」モードを利用", "starter");
        }
        return false;
      }
      case "updateExperience": {
        if (currentPlan !== "free") { return false; }
        const { data, experienceList = [] } = props;
        function isRich(x) {
          return x.overview || x.hypothesisIdArr?.length || x.winningVariantId || x.insight || x.nextExperienceIdArr?.length;
        }
        const richExperienceList = experienceList.filter(isRich);
        if ((richExperienceList.length >= 3) && isRich(data)) {
          return openAlert("4つ以上のエクスペリエンスに情報を追加", "starter");
        }
        return false;
      }
      case "turnIntoRollout": {
        switch (currentPlan) {
          case "premium": case "basic": return false;
          default: return openAlert("勝利したパターンを100%反映", "basic");
        }
      }
      case "addTopic": case "addMessage": {
        if (currentPlan !== "premium") { return openAlert("カスタマーサポートを利用", "premium"); }
        return false;
      }
      default:
        return false;
    }

    function openAlert(label, plan) {
      if (["basic", "starter"].includes(plan)) {
        if (billableUserCount >= 10) {
          plan = "premium";
        } else if (billableUserCount >= 3) {
          plan = "basic";
        }
      }
      alertDialog.open({
        title: "プランのアップグレードが必要です！",
        description: `${label}するには、\nプランをアップグレードしてください。`,
        actionComponent: <PlanUpgradeButton {...{ containerId, plan }} />,
        sx: { textAlign: "center" },
      });
      return true;
    }
  }

  return {
    containerDocRef, experienceColRef, topicColRef, sessionColRef,
    update, docData, stripeDocData,
    billableUserCount, currentUserRole, currentPlan, checkIsUpgradeRequired,
    useExperienceCol, addExperience, addExperienceFromTemplate, updateExperience, deleteExperience,
    useRequestCol, addRequest, updateRequest,
    useHypothesisCol, addHypothesis, updateHypothesis, deleteHypothesis,
    useIndicatorCol, addIndicator, updateIndicator, deleteIndicator,
    useAssetCol, setAsset, updateAsset, deleteAsset,
    useTopicCol, addTopic, updateTopic,
    addSession, listenSession,
    updateStripe,
  };
});
