import React from "react";
// import React, { useEffect } from "react";
// import { AlertTitle } from "@mui/material";

// import Header from "../containers/Header";

import VStack from "../elements/VStack";
import PlanCardList from "../components/PlanCardList";

export default () => {
  // const header = Header.useContainer();

  // useEffect(() => {
  //   header.setAlert({
  //     severity: "info",
  //     children: <>
  //       <AlertTitle>【期間限定】初月50%OFFキャンペーン実施中！</AlertTitle>
  //       2024年6月30日までのお申込みで、1ヶ月目のサブスクリプション料金が50%OFFになります。
  //     </>,
  //   });
  //   return () => header.setAlert(null);
  // }, []);

  return (
    <VStack width="96%" maxWidth="xl" py={3}>
      <PlanCardList isOverview />
    </VStack>
  );
};
