import { diff } from "deep-object-diff";

export const isDiff = (xSrc, ySrc) => {
  const x = { ...(xSrc || {}) };
  const y = { ...(ySrc || {}) };
  removeEmptyValues(x);
  removeEmptyValues(y);
  return !!Object.keys(diff(x, y)).length;
}

function removeEmptyValues(obj) {
  for (const key in obj) {
    if (obj[key] && (typeof obj[key] === "object")) {
      removeEmptyValues(obj[key]);
    } else if ([null, undefined, ""].includes(obj[key])) {
      delete obj[key];
    }
  }
}
