import { createContainer } from "unstated-next";
import { doc } from "firebase/firestore";

import Firestore from "./Firestore";

export default createContainer(({ containerId, sessionId }) => {
  const { useDocData, containerColRef } = Firestore.useContainer();

  const { data: docData = {} } = useDocData(doc(containerColRef, containerId, "secrets", "stripe", "sessions", sessionId), undefined, true);

  return { docData };
});
