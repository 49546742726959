import { useState } from "react";
import { createContainer } from "unstated-next";
import ChiSquare from "chi-square-p-value";
import GoogleButton from "react-google-button";

import Firebase from "./Firebase";
import AlertDialog from "./AlertDialog";
import Snackbar from "./Snackbar";

import * as time from "../utils/time";

export default createContainer(() => {
  const firebase = Firebase.useContainer();
  const alertDialog = AlertDialog.useContainer();
  const snackbar = Snackbar.useContainer();
  const [experienceEventNameArrObj, setExperienceEventNameArrObj] = useState({});
  const [experienceReportListObj, setExperienceReportListObj] = useState({});

  async function runFetchAdmin(path, data, method = data ? "POST" : "GET") {
    if (!firebase.oauthToken) { return { error: { code: 401 } }; }
    try {
      const res = await fetch(`https://analyticsadmin.googleapis.com/v1alpha/${path}${(!data && path.endsWith("s")) ? "?pageSize=200" : ""}`, {
        headers: { Authorization: `Bearer ${firebase.oauthToken}` },
        method,
        body: data ? JSON.stringify(data) : undefined,
      });
      if (res?.status === 401) { firebase.resetOauthToken(); }
      const ret = await res.json();
      return ret;
    } catch (e) {
      console.log(e);
      snackbar.open({ operation: "Googleアナリティクスのデータにアクセス", isError: true });
      return undefined;
    }
  }

  async function runFetchData(path, data, method = data ? "POST" : "GET") {
    if (!firebase.oauthToken) { return { error: { code: 401 } }; }
    try {
      const res = await fetch(`https://analyticsdata.googleapis.com/v1beta/${path}`, {
        headers: { Authorization: `Bearer ${firebase.oauthToken}` },
        method,
        body: data ? JSON.stringify(data) : undefined,
      });
      if (res?.status === 401) { firebase.resetOauthToken(); }
      const ret = await res.json();
      return ret;
    } catch (e) {
      console.log(e);
      snackbar.open({ operation: "Googleアナリティクスのデータにアクセス", isError: true });
      return undefined;
    }
  }

  async function getPropertyList() {
    const loading = alertDialog.open({ isLoading: true });
    const ret = await runFetchAdmin("accountSummaries");
    loading.close();
    const { accountSummaries = [] } = ret || {};
    const propertyList = [];
    accountSummaries.forEach(({ propertySummaries = [], ...accountSummary }) => {
      if (!propertySummaries.length) { return; }
      const { id: accountId, name: accountName } = getAccountData(accountSummary);
      propertySummaries.forEach((propertySummary) => {
        propertyList.push({ ...getPropertyData(propertySummary), accountId, accountName });
      });
    });
    return propertyList;
  }

  async function getStreamList(propertyId) {
    const loading = alertDialog.open({ isLoading: true });
    const ret = await runFetchAdmin(`properties/${propertyId}/dataStreams`);
    loading.close();
    const { dataStreams = [] } = ret || {};
    const streamList = [];
    dataStreams.forEach((data) => {
      if (data?.webStreamData) {
        streamList.push(getStreamData(data));
      }
    });
    return streamList;
  }

  async function createAudienceList(container = {}, experience = {}) {
    const { propertyId } = container.ga4Info || {};
    const operation = "GA4オーディエンスを作成";
    const isRollout = (experience.mode === "rollout");
    const variantCount = isRollout ? 1 : experience.variantList?.length;
    const loading = alertDialog.open({ operation, isLoading: true });
    const audienceList = [];
    for (let i = 0; i < variantCount; i++) {
      const audience = await createAudience(propertyId, `OPTX-${experience.id}${isRollout ? "" : `-${i}`}`);
      if (!audience) { break; }
      audienceList.push(audience);
    }
    loading.close();
    if (audienceList.length < variantCount) {
      snackbar.open({ operation, isError: true });
      return null;
    }
    snackbar.open({ operation });
    return audienceList;
  }

  async function createAudience(propertyId, name) {
    const ret = await runFetchAdmin(`properties/${propertyId}/audiences`, getAudienceBody(name));
    if (!ret) { return null; }
    if (ret.error) {
      switch (ret.error.code) {
        case 403:
          alertDialog.open({ title: "GA4プロパティの権限がありません。", description: "エクスペリエンスを開始するには、リンク先のGoogleアナリティクスプロパティで「マーケティング担当者」以上の権限が必要です。" });
          break;
        case 400:
          if (ret.error.message?.includes("already exists")) {
            const audienceList = await getAudienceList(propertyId);
            return audienceList.find((x) => x.name === name);
          }
          break;
        case 429:
          if (ret.error.status === "RESOURCE_EXHAUSTED") {
            alertDialog.open({ title: "GA4オーディエンスを作成できませんでした。", description: "GA4プロパティのオーディエンス数が上限に達しています。終了済みのエクスペリエンスをアーカイブして、オーディエンスの空き枠を用意してください。" });
          }
          break;
        default:
          break;
      }
      return null;
    }
    return getAudienceData(ret);
  }

  async function archiveAudienceList(container = {}, audienceList = []) {
    const list = [...audienceList];
    for (let i = 0; i < list.length; i++) {
      const audience = list[i];
      if (audience.isArchived) { continue; }
      const ret = await archiveAudience(container.ga4Info?.propertyId, audience.id);
      if (ret) {
        audience.isArchived = true;
      } else {
        break;
      }
    }
    return list;
  }

  async function archiveAudience(propertyId, audienceId) {
    const ret = await runFetchAdmin(`properties/${propertyId}/audiences/${audienceId}:archive`, null, "POST");
    if (!ret) { return false; }
    if (ret.error) {
      switch (ret.error.code) {
        case 404:
          return true;
        case 403:
          alertDialog.open({ title: "GA4プロパティの権限がありません。", description: "実施済みのエクスペリエンスをアーカイブするには、リンク先のGoogleアナリティクスプロパティで「マーケティング担当者」以上の権限が必要です。" });
          return false;
        case 401:
          alertDialog.open({
            title: "実施済みのエクスペリエンスをアーカイブするには、Googleアナリティクスへのアクセス権限が必要です。",
            actions: null,
            Content: <GoogleButton onClick={firebase.getOauthToken} style={{ margin: "20px auto" }} />,
          }, "token");
          return false;
        default:
          return false;
      }
    }
    return true;
  }

  async function getAudienceList(propertyId) {
    const ret = await runFetchAdmin(`properties/${propertyId}/audiences`);
    const { audiences = [] } = ret || {};
    return audiences.map(getAudienceData);
  }

  async function getEventNameArr(container = {}, experience = {}) {
    const { id, gtmInfo = {} } = experience;

    const stateEventNameArr = experienceEventNameArrObj[id];
    if (stateEventNameArr) { return stateEventNameArr; }

    const loading = alertDialog.open({ isLoading: true });

    const ret = await runFetchData(`properties/${container.ga4Info.propertyId}:runReport`, {
      dimensions: [{ name: "eventName" }],
      metrics: [{ name: "eventCount" }],
      dimensionFilter: {
        notExpression: {
          filter: {
            fieldName: "eventName",
            inListFilter: {
              values: ["first_visit", "session_start", "page_view", "experience_impression"],
            },
          },
        },
      },
      dateRanges: [{
        startDate: time.isOld(gtmInfo.startAt, 7*24*60) ? time.toAnalyticsDateString(gtmInfo.startAt) : "7daysAgo",
        endDate: time.toAnalyticsDateString(gtmInfo.endAt) || "today",
      }],
    });

    if (!ret || ret.error) {
      switch (ret?.error?.code) {
        case 403:
          alertDialog.open({ title: "GA4プロパティの権限がありません。", description: "レポートを表示するには、リンク先のGoogleアナリティクスプロパティで「閲覧者」以上の権限が必要です。" });
          break;
        default:
          break;
      }
      loading.close();
      return undefined;
    }

    const eventNameArr = getDimensionValueArr(ret);
    if (eventNameArr?.length) {
      setExperienceEventNameArrObj((prev) => ({ ...prev, [id]: eventNameArr }));
    }
    loading.close();
    return eventNameArr;
  }

  async function getReportList(container = {}, experience = {}) {
    const { id, mode, variantList = [], goalEventNameArr = [], metricName = "totalUsers", gtmInfo = {} } = experience;
    const isRollout = (mode === "rollout");
    const audiencePrefix = `OPTX-${id}`;
    const variantCount = variantList.length;
    if (!variantCount || !gtmInfo.startAt || !container.ga4Info?.propertyId) { return undefined; }
    if (!goalEventNameArr.length) { return []; }

    const idWithDate = `${id}@${new Date().toLocaleDateString("ja")}`;
    const stateReportList = experienceReportListObj[idWithDate];
    if ((stateReportList?.length === goalEventNameArr.length) && !stateReportList.some((x, i) => (x.eventName !== goalEventNameArr[i]) || (x.metricName !== metricName))) {
      return stateReportList;
    }

    const loading = alertDialog.open({ isLoading: true });
    const requests = [];

    function addRequest(dimensionFilter) {
      requests.push({
        dimensionFilter,
        dimensions: [{ name: "audienceName" }],
        metrics: [{ name: metricName }],
        dateRanges: [{
          startDate: time.toAnalyticsDateString(gtmInfo.startAt),
          endDate: time.toAnalyticsDateString((gtmInfo.status === "paused") ? gtmInfo.pausedAt : gtmInfo.endAt) || "today",
        }],
      });
    }
    const filter = {
      fieldName: "audienceName",
      stringFilter: { matchType: "BEGINS_WITH", value: audiencePrefix },
    };

    addRequest({ filter });
    
    goalEventNameArr.forEach((eventName) => {
      addRequest({ andGroup: { expressions: [
        { filter },
        { filter: {
          fieldName: "eventName",
          stringFilter: { matchType: "EXACT", value: eventName },
        }},
      ]}});
    });

    const ret = await runFetchData(`properties/${container.ga4Info.propertyId}:batchRunReports`, { requests });
    if (!ret || ret.error) {
      switch (ret?.error?.code) {
        case 403:
          alertDialog.open({
            title: "GA4プロパティの権限がありません。",
            description: "レポートを表示するには、リンク先のGA4プロパティで「閲覧者」以上の権限が必要です。",
          });
          break;
        default:
          break;
      }
      loading.close();
      return undefined;
    }

    const { reports = [] } = ret;
    const impReport = getReportData(reports[0]);

    const reportList = [];

    for (let i = 0; i < goalEventNameArr.length; i++) {
      const cvReport = getReportData(reports[i + 1]);
      const variantRowList = variantList.map((variant, j) => {
        const audienceName = audiencePrefix + (isRollout ? "" : `-${j}`);
        const variantRowData = {
          imp: impReport[audienceName] || 0,
          cv: cvReport[audienceName] || 0,
        };
        if (!isRollout) {
          variantRowData.variantIndex = j;
          variantRowData.variantName = variant.name;
        }
        return variantRowData;
      });
  
      let pValue = 1;
      const activeRowList = variantRowList.filter((x) => x.imp > 0);
      if (activeRowList.length > 1) {
        const chiSquareRet = ChiSquare(activeRowList.map((x) => [x.imp, x.cv]));
        if (chiSquareRet) { pValue = chiSquareRet.pValue; }
        const res = await fetch("https://static-calc-api-uiififeqoa-uc.a.run.app/api/baysian", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(activeRowList),
        }).catch(() => null);
        if (res?.ok) {
          const winProbArr = await res.json() || [];
          activeRowList.forEach(({ variantIndex }, j) => {
            variantRowList[variantIndex].winProb = winProbArr[j];
          });
        }
      }

      reportList.push({ eventName: goalEventNameArr[i], metricName, variantRowList, pValue });
    }

    setExperienceReportListObj((prev) => ({ ...prev, [idWithDate]: reportList }));
    loading.close();
    return reportList;
  }

  return { getPropertyList, getStreamList, getEventNameArr, createAudienceList, archiveAudienceList, getReportList };
});

function getAccountData(data) {
  const { account, displayName } = data || {};
  return {
    id: getId(account),
    name: displayName,
  };
}

function getPropertyData(data) {
  const { property, displayName } = data || {};
  return {
    id: getId(property),
    name: displayName,
  };
}

function getStreamData(data) {
  const { name, displayName, webStreamData = {} } = data || {};
  const { measurementId } = webStreamData;
  return {
    id: getId(name),
    name: displayName,
    measurementId,
  };
}

function getAudienceData(data) {
  const { name, displayName } = data || {};
  return {
    id: getId(name),
    name: displayName,
  };
}

function getId(name) {
  return name?.match(/\d+$/)?.[0];
}

function getReportData(report) {
  const { rows = [] } = report || {};
  const data = {};
  rows.forEach(({ dimensionValues = [], metricValues = [] }) => {
    data[dimensionValues[0].value] = Number(metricValues[0].value);
  });
  return data;
}

function getDimensionValueArr(report) {
  const { rows = [] } = report || {};
  return rows.map(({ dimensionValues = [] }) => dimensionValues[0]?.value);
}

function getAudienceBody(displayName) {
  return {
    displayName,
    description: "Created by Optimize Next",
    membershipDurationDays: 540,
    filterClauses: [{
      clauseType: "INCLUDE",
      simpleFilter: {
        scope: "AUDIENCE_FILTER_SCOPE_ACROSS_ALL_SESSIONS",
        filterExpression: {
          andGroup: {
            filterExpressions: [{
              orGroup: {
                filterExpressions: [{
                  eventFilter: {
                    eventName: "experience_impression",
                    eventParameterFilterExpression: {
                      andGroup: {
                        filterExpressions: [{
                          orGroup: {
                            filterExpressions: [{
                              dimensionOrMetricFilter: {
                                fieldName: "exp_variant_string",
                                stringFilter: {
                                  matchType: "EXACT",
                                  value: displayName,
                                },
                                atAnyPointInTime: true,
                              },
                            }],
                          },
                        }],
                      },
                    },
                  },
                }],
              },
            }],
          },
        },
      },
    }],
  };
}