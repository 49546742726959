import React  from "react";
import { CircularProgress, Typography } from "@mui/material";
import VStack from "../elements/VStack";

export default ({ label, children }) => (
  <VStack>
    {label && (
      <Typography variant="h3" component="p">
        {label + "..."}
      </Typography>
    )}
    <CircularProgress />
    {children}
  </VStack>
);
