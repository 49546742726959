import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Stack } from "@mui/material";

import RootBox from "./containers/RootBox";
import Header from "./containers/Header";
import AlertDialog from "./containers/AlertDialog";
import Snackbar from "./containers/Snackbar";
import Drawer from "./containers/Drawer";
import Firebase from "./containers/Firebase";
import Firestore from "./containers/Firestore";

import routes from "./routes";
import Footer from "./components/Footer";

export default () => {
  const location = useLocation();
  const { renderRootBox } = RootBox.useContainer();
  const header = Header.useContainer();
  const alertDialog = AlertDialog.useContainer();
  const snackbar = Snackbar.useContainer();
  const drawer = Drawer.useContainer();
  const { currentUser, sendEvent } = Firebase.useContainer();

  useEffect(() => {
    window.addEventListener("offline", () => {
      snackbar.close("online");
      snackbar.open({ id: "offline", title: "接続されていません", isError: true, autoHideDuration: null, isConsistent: true });
    });
    window.addEventListener("online", () => {
      snackbar.close("offline");
      snackbar.open({ id: "online", title: "オンラインに復帰しました", isConsistent: true });
    });
  }, []);

  useEffect(() => {
    sendEvent("location_change", undefined, true);
  }, [location]);

  return renderRootBox(
    <Firestore.Provider initialState={{ currentUser }}>
      {header.Component}
      <Stack flex={1} overflow="scroll">
        <Stack flex={1} pb={12}>
          <Routes>
            {routes.map((route) => <Route {...route} key={route.path} />)}
          </Routes>
        </Stack>
        {(!location.pathname.match(/^\/(containers|templates|service)\/.+/)
          || (location.pathname.startsWith("/containers") && (location.hash === "#settings"))
        ) && <Footer />}
      </Stack>
      {alertDialog.Component}
      {drawer.Component}
      {snackbar.Component}
    </Firestore.Provider>
  );
}
