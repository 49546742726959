import React  from "react";
import { Card, CardMedia, Divider, Stack, Typography } from "@mui/material";

import Header from "../containers/Header";

export default () => {
  const header = Header.useContainer();
  return (
    <>
      <Divider sx={{ width: "100%", pt: 6 }} />
      <Stack maxWidth="md" spacing={4} py={4} alignSelf="center">
        <Typography variant="body4" align="center">
          {descriptions[header.fragment]}
        </Typography>
        <Card raised sx={{ width: "100%" }}>
          <CardMedia
            image={`/premium-${header.fragment}.png`}
            alt=""
            component="img"
            width="100%"
          />
        </Card>
      </Stack>
    </>
  );
};

const descriptions = {
  "kpitree": <>
    Webサイトの目標を細分化して、<strong>KPIツリーを作成</strong>しましょう。<br/>
    KPIごとに<strong>現状数値と目標数値のギャップ</strong>を把握することで、<br/>
    <strong>あなたのWebサイトが抱えている課題</strong>が明確になります。
  </>,
  "hypotheses": <>
    各KPIが抱える課題（<strong>現状数値と目標数値のギャップ</strong>）に対して、<br/>
    その<strong>要因として考えられる仮説</strong>を立案しましょう。<br/>
    ABテストは、これらの<strong>仮説を検証するための手段</strong>です。
  </>,
  "overview": <>
    <strong>どのような仮説を検証するために</strong>このテストを実施するのか、<br/>
    あらかじめ明確にしておきましょう。<br/>
    <strong>テストの目的や内容</strong>をメモしておくと、施策管理に役立ちます。
  </>,
  "insight": <>
    テスト結果のレポートから<strong>パターンの勝者を決定</strong>し、<br/>
    検証対象の<strong>仮説が正しいと言えるか</strong>判定しましょう。<br/>
    考察をもとに、<strong>新たなABテスト施策を展開</strong>することも重要です。
  </>,
};
