import React from "react";
import { Stack, Typography } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import dayjs from "dayjs";

import Drawer from "../containers/Drawer";
import ScheduleDrawerContent from "./ScheduleDrawerContent";
import BuilderCard from "../elements/BuilderCard";
import ActionButton from "../elements/ActionButton";
import * as time from "../utils/time";

const title = "スケジュール", supportId = "experience-schedule";

export default (props) => {
  const { scheduledStartAt, scheduledEndAt, gtmInfo, handleUpdateExtra, readOnly = false, hasStarted = false } = props;
  const drawer = Drawer.useContainer();

  function handleEdit() {
    const defaultValue = {
      scheduledStartAt: scheduledStartAt ? dayjs(scheduledStartAt?.toDate()) : undefined,
      scheduledEndAt: scheduledEndAt ? dayjs(scheduledEndAt?.toDate()) : undefined,
    };
    drawer.open({
      title,
      supportId,
      btnLabel: "更新",
      defaultValue,
      ContentComponent: ScheduleDrawerContent,
      contentComponentProps: { hasStarted },
      onSuccess: (data) => handleUpdateExtra(data),
    });
  }

  return (
    <BuilderCard
      {...{ title, supportId }}
      color="gray"
      Icon={ScheduleIcon}
      renderItem={() => (
        <Stack direction="row" spacing={4} alignItems="center">
          <Stack spacing={1} flex={4} direction="row" alignItems="center">
            <Stack spacing={2} flex={1} direction="row" alignItems="center">
              <Typography variant="body2">開始：</Typography>
              <Typography
                variant="body2"
                fontWeight={scheduledStartAt ? "bold" : "inherit"}
                color={(!gtmInfo?.status && time.isPast(scheduledStartAt)) ? "error.main" : "inherit"}
              >
                {time.toString(scheduledStartAt) || "未設定"}
              </Typography>
            </Stack>
            <Stack spacing={2} flex={1} direction="row" alignItems="center">
              <Typography variant="body2">終了：</Typography>
              <Typography
                variant="body2"
                fontWeight={scheduledEndAt ? "bold" : "inherit"}
                color={((gtmInfo?.status !== "closed") && time.isPast(scheduledEndAt)) ? "error.main" : "inherit"}
              >
                {time.toString(scheduledEndAt) || "未設定"}
              </Typography>
            </Stack>
          </Stack>

          {!readOnly && (
            <Stack flex={1} direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
              <ActionButton type="edit" onClick={handleEdit} />
            </Stack>
          )}
        </Stack>
      )}
    />
  );
}
