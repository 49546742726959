import React, { useEffect, useMemo } from "react";
import { useSwiper } from "swiper/react";
import { TextFieldElement, CheckboxElement, SelectElement, useFormContext } from "react-hook-form-mui";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import Drawer from "../containers/Drawer";

import SwiperTabs from "../elements/SwiperTabs";
import MultiInputElement from "../elements/MultiInputElement";

import selectOptions from "../utils/selectOptions";
import conditionTypes, { getConditionTypeObj } from "../utils/conditionTypes";

export default (props) => {
  const { defaultValue } = props;

  if (defaultValue) {
    return <FormSlideContent {...props} />;
  }

  return (
    <SwiperTabs>
      <ListSlideContent />
      <FormSlideContent {...props} />
    </SwiperTabs>
  );
}

function ListSlideContent() {
  const swiper = useSwiper();
  const drawer = Drawer.useContainer();
  const { setValue, reset } = useFormContext();

  function handleSelect(item) {
    setValue("type", item.value);
    setTimeout(() => {
      swiper.slideNext();
      drawer.update({
        title: item.label,
        btnLabel: "追加",
        LeftIcon: ChevronLeftIcon,
        onLeftIconClick: () => {
          reset({});
          swiper.slidePrev();
          drawer.revert();
        },
      });
    });
  }

  return (
    <List sx={{ pt: 0 }}>
      {conditionTypes.map((item) => (
        <ListItem key={item.value} divider disablePadding sx={{ bgcolor: "background.paper" }}>
          <ListItemButton onClick={() => handleSelect(item)}>
            <ListItemIcon>
              <item.Icon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={item.label} primaryTypographyProps={{ variant: "h5" }} />
            <ChevronRightIcon color="gray" />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

function FormSlideContent(props) {
  const { defaultValue } = props;
  const { setValue, reset, watch } = useFormContext();

  const currentType = watch("type");
  const currentMethod = watch("method");
  const currentTypeObj = useMemo(() => getConditionTypeObj(currentType), [currentType]);

  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue);
    } else {
      setValue("method", "equals");
    }
  }, [currentType]);

  return (
    <Stack spacing={2} p={4}>
      {!!currentTypeObj.isKeyNeeded && (
        <TextFieldElement
          name="key"
          label="キー"
          required
          fullWidth
          autoComplete="off"
        />
      )}
      <SelectElement
        name="method"
        label="演算子"
        options={selectOptions(currentTypeObj.isKeyNeeded ? "methodCustom" : "method")}
        required
        fullWidth
      />

      {!currentMethod?.endsWith("empty") && (
        <MultiInputElement
          key={currentType}
          name="valueArr"
          label="値"
          options={currentTypeObj.options}
          required
          freeSolo={!currentMethod?.endsWith("equals")}
          noOptionsText="該当する値がありません"
        />
      )}

      {currentMethod?.endsWith("match") && (
        <CheckboxElement
          name="ignoreCaseFlag"
          label="大文字と小文字の違いを無視"
          size="small"
        />
      )}
    </Stack>
  );
}
